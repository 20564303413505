import React, { useEffect, useState, useContext } from 'react';
import { NumberInput } from 'src/components/NumberInput';
import {
  providerAccBasePricing,
  providerAccExtraFee,
  providerAccExtraFeeRevenue,
  providerAccExtraFeeVat,
  providerAuditPricing,
  providerLiquidationPricing,
  providerPayrollPricing,
  providerTaxConsultingPricing,
  WebshopFeeList,
  TourismFeeList,
  TradeFeeList,
  MedicineFeeList,
  severalLocationsExtra,
} from '../profileHelper';
import { ProviderContext } from '../ProviderProfile';

const Pricing = () => {
  const [data, setData] = useState(null);
  const { providerData, updateData } = useContext(ProviderContext);

  useEffect(() => {
    if (providerData) {
      setData({
        provider_acc_monthly_price: providerData.provider_acc_monthly_price,
        provider_payroll_price: providerData.provider_payroll_price,
        provider_tax_consulting_price: providerData.provider_tax_consulting_price,
        provider_audit_price: providerData.provider_audit_price,
        provider_liquidation_price: providerData.provider_liquidation_price,
      });
    }
  }, [providerData]);

  const handleChange = async (id, value) => {
    if (isNaN(value) || !value) {
      value = 0;
    }
    await updateData('provider', { [id]: value });
  };

  return (
    <div className="TabContent">
      {data ? (
        <div className="TabContentFlexRow">
          <div className="Padding12">
            <p className="LabelBoldM8">Könyvelés</p>
            {providerAccBasePricing.map((item, i) => {
              return (
                <div key={i}>
                  <NumberInput
                    label={item.label}
                    valueProp={data[item.modelKey][item.id]}
                    id={`${item.modelKey}.${item.id}`}
                    onChange={handleChange}
                    index={i}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <p className="LabelBoldM8">Könyvelés felárak</p>
            {providerAccExtraFee.map((item, i) => {
              return (
                <div key={i}>
                  <NumberInput
                    label={item.label}
                    valueProp={data[item.modelKey][item.id]}
                    id={`${item.modelKey}.${item.id}`}
                    onChange={handleChange}
                    index={i}
                  />
                </div>
              );
            })}
            <p className="LabelBoldM8">Könyvelés egyéb felára</p>
            {severalLocationsExtra.map((item, i) => {
              return (
                <div key={i}>
                  <NumberInput
                    label={item.label}
                    valueProp={data[item.modelKey][item.id]}
                    id={`${item.modelKey}.${item.id}`}
                    onChange={handleChange}
                    index={i}
                  />
                </div>
              );
            })}
            <p className="LabelBoldM8">Áfa bevallás szerinti felárak (%)</p>
            {providerAccExtraFeeVat.map((item, i) => {
              return (
                <div key={i}>
                  <NumberInput
                    label={item.label}
                    valueProp={data[item.modelKey][item.id]}
                    id={`${item.modelKey}.${item.id}`}
                    onChange={handleChange}
                    index={i}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <p className="LabelBoldM8">Árbevétel szerinti felárak (%)</p>
            {providerAccExtraFeeRevenue.map((item, i) => {
              return (
                <div key={i}>
                  <NumberInput
                    label={item.label}
                    valueProp={data[item.modelKey][item.id]}
                    id={`${item.modelKey}.${item.id}`}
                    onChange={handleChange}
                    index={i}
                  />
                </div>
              );
            })}
            <p className="LabelBoldM8">Kis-és nagykereskedelem felárak (Ft/hó)</p>
            {TradeFeeList.map((item, i) => {
              return (
                <div key={i}>
                  <NumberInput
                    label={item.label}
                    valueProp={data[item.modelKey][item.id]}
                    id={`${item.modelKey}.${item.id}`}
                    onChange={handleChange}
                    index={i}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <p className="LabelBoldM8">Webshop felárak (Ft/hó)</p>
            {WebshopFeeList.map((item, i) => {
              return (
                <div key={i}>
                  <NumberInput
                    label={item.label}
                    valueProp={data[item.modelKey][item.id]}
                    id={`${item.modelKey}.${item.id}`}
                    onChange={handleChange}
                    index={i}
                  />
                </div>
              );
            })}
            <p className="LabelBoldM8">Szállás, vendéglátás felárak (Ft/hó)</p>
            {TourismFeeList.map((item, i) => {
              return (
                <div key={i}>
                  <NumberInput
                    label={item.label}
                    valueProp={data[item.modelKey][item.id]}
                    id={`${item.modelKey}.${item.id}`}
                    onChange={handleChange}
                    index={i}
                  />
                </div>
              );
            })}
            <p className="LabelBoldM8">Egészségügy felárak (Ft/hó)</p>
            {MedicineFeeList.map((item, i) => {
              return (
                <div key={i}>
                  <NumberInput
                    label={item.label}
                    valueProp={data[item.modelKey][item.id]}
                    id={`${item.modelKey}.${item.id}`}
                    onChange={handleChange}
                    index={i}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <p className="LabelBoldM8">Bérszámfejtés</p>
            {providerPayrollPricing.map((item, i) => {
              return (
                <div key={i}>
                  <NumberInput
                    label={item.label}
                    valueProp={data[item.modelKey][item.id]}
                    id={`${item.modelKey}.${item.id}`}
                    onChange={handleChange}
                    index={i}
                  />
                </div>
              );
            })}
            <p className="LabelBoldM8">Adótanácsadás</p>
            {providerTaxConsultingPricing.map((item, i) => {
              return (
                <div key={i}>
                  <NumberInput
                    label={item.label}
                    valueProp={data[item.modelKey][item.id] ? data[item.modelKey][item.id] : 0}
                    id={`${item.modelKey}.${item.id}`}
                    onChange={handleChange}
                    index={i}
                  />
                </div>
              );
            })}
            <p className="LabelBoldM8">Könyvvizsgálat</p>
            {providerAuditPricing.map((item, i) => {
              return (
                <div key={i}>
                  <NumberInput
                    label={item.label}
                    valueProp={data[item.modelKey][item.id]}
                    id={`${item.modelKey}.${item.id}`}
                    onChange={handleChange}
                    index={i}
                  />
                </div>
              );
            })}
            <p className="LabelBoldM8">Végelszámolás</p>
            {providerLiquidationPricing.map((item, i) => {
              if (data[item.modelKey] && data[item.modelKey][item.id]) {
                return (
                  <div key={i}>
                    <NumberInput
                      label={item.label}
                      valueProp={data[item.modelKey][item.id]}
                      id={`${item.modelKey}.${item.id}`}
                      onChange={handleChange}
                    />
                  </div>
                );
              } else
                return (
                  <div key={i}>
                    <NumberInput
                      label={item.label}
                      valueProp=""
                      id={`${item.modelKey}.${item.id}`}
                      onChange={handleChange}
                    />
                  </div>
                );
            })}
          </div>
        </div>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default Pricing;

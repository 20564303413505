import React, { useEffect, useState, useContext } from 'react';
import MuiSwitch from 'src/components/Switch';
import TextInput from 'src/components/TextInput';
import { accountSwitchInputs, accountTextInputs } from '../profileHelper';
import { ProviderContext } from '../ProviderProfile';

const Account = () => {
  const [data, setData] = useState(null);
  const { accountData, providerData, /* setAccountData, setProviderData, */ updateData } = useContext(ProviderContext);

  useEffect(() => {
    if (accountData && providerData) {
      let tempObj = {
        confirmed: accountData.confirmed,
        email: accountData.email,
        finishedRegistration: false,
        'provider_subscription.activeSubscription': providerData.provider_subscription.activeSubscription,
        'provider_subscription.subscriptionStatus': providerData.provider_subscription.subscriptionStatus
          ? providerData.provider_subscription.subscriptionStatus
          : '',
        provider_package: providerData.provider_package,
      };
      if (accountData.finishedRegistration === true && providerData.finishedRegistration === true) {
        tempObj.finishedRegistration = true;
      }
      setData(tempObj);
    }
  }, [accountData, providerData]);

  const handleCheck = async (id, value) => {
    setData({ ...data, [id]: value });
    switch (id) {
      case 'confirmed':
      case 'email': {
        await updateData('user', { [id]: value });
        break;
      }
      case 'provider_subscription.activeSubscription':
      case 'provider_subscription.subscriptionStatus': {
        await updateData('provider', { [id]: value });
        break;
      }
      case 'finishedRegistration': {
        await updateData('provider', { [id]: value });
        await updateData('user', { [id]: value });

        break;
      }
      default:
        return;
    }
  };

  const handleChange = async (id, value) => {
    if (id === 'email') {
      await updateData('user', { [id]: value });
    } else await updateData('provider', { [id]: value });
  };

  return (
    <div className="TabContent">
      {data ? (
        <div className="TabContentFlexRow">
          <div className="Padding12">
            {accountTextInputs.map((item, i) => {
              return (
                <div className="TextInput" key={i}>
                  <TextInput label={item.label} id={item.id} valueProp={data[item.id]} onChange={handleChange} />
                </div>
              );
            })}
            {accountSwitchInputs.map((item, i) => {
              return (
                <div className="Width350" key={i}>
                  <MuiSwitch label={item.label} id={item.id} value={data[item.id]} onChange={handleCheck} />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default Account;

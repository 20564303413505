// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'providers',
    path: '/dashboard/providers',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'referrals',
    path: '/dashboard/referrals',
    icon: getIcon('eva:person-add-fill'),
  },
  {
    title: 'coupons',
    path: '/dashboard/coupons',
    icon: getIcon('ic:baseline-discount'),
  },
  {
    title: 'req status',
    path: '/dashboard/status',
    icon: getIcon('eva:file-text-fill'),
  },
  // {
  //   title: 'contracts',
  //   path: '/dashboard/contracts',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  /*{
    title: 'login',
    path: '/login',
    icon: getIcon('eva:lock-fill'),
  },
  {
    title: 'register',
    path: '/register',
    icon: getIcon('eva:person-add-fill'),
  },
  {
    title: 'Not found',
    path: '/404',
    icon: getIcon('eva:alert-triangle-fill'),
  }, */
];

export default navConfig;

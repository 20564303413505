export const getTemplate = ({ chosenTemp, tempData, providerData }) => {
  console.log(tempData, 'CHOSEN TEMP', providerData);
  let template;

  const foundingpriceslist = {
    singleidk: 11900,
    singleprimary: 11900,
    singlesecondary: 11900,
    kiva: 11900,
    vszja: 11900,
    bt: 30000,
    kft: 50000,
  };

  const companyText = {
    accounting: 'Könyvelés',
    payroll: 'Bérszámfejtés',
    taxconsulting: 'Adótanácsadás',
    audit: 'Könyvvizsgálat',
    legalentityfounding: 'Cégalapítás',
    liquidation: 'Végelszámolás',
    singleprimary: 'KATA',
    singlesecondary: 'Egyéni vállalkozó, átalányadós, mellékállású',
    kiva: 'Egyéni vállalkozó, átalányadós, főállású',
    vszja: 'Egyéni vállalkozó, VSZJA',
    bt: 'BT',
    kft: 'KFT',
    zrt: 'ZRT',
    kkt: 'KKT',
    kht: 'KHT',
    nyrt: 'NYRT',
    condo: 'Társasház',
    citycouncyl: 'Önkormányzat, költségvetési szerv',
    civil: 'Civil szervezet, egyesület, alapítvány, egyház',
    tbd: 'Nem meghatározott',
    construction: 'Építőipar',
    vehicle: 'Gépjármű Ipar',
    manufacturing: 'Gyártás és Feldolgozó Ipar',
    IT: 'Informatika',
    realestate: 'Ingatlan Kereskedelem',
    //trade: 'Kis- és Nagy Kereskedelem',
    retail: 'Kiskereskedelem',
    wholesale: 'Nagykereskedelem',
    agriculture: 'Mező- és erdőgazdálkodás, halászat',
    education: 'Oktatás, Kutatás',
    art: 'Művészet',
    beauty: 'Szépségipar',
    medicine: 'Egészségügy',
    finance: 'Pénzügy, biztosítás',
    logistics: 'Szállítás, raktározás',
    travel: 'Szállás, Vendéglátás',
    otherservices: 'Szolgáltatások (Egyéb)',
    etrade: 'E-kereskedelem',
    startup: 'Start up',
    sport: 'Sport',
    other: 'Egyéb',
    local: 'Helyben könyvelés',
    remote: 'Távkönyvelés ',
    false: 'Nem',
    true: 'Igen',
    quarterly: 'negyedéves ÁFÁ-s',
    yearly: 'éves ÁFÁ-s',
    monthly: 'havi ÁFÁ-s',
    inside: 'EU-n belül',
    outside: 'EU-n kívül is',
    both: 'EU-n belül és kívűl is',
    phone: 'Telefonos egyeztetés',
    online: 'Online videóhívás',
  };

  const languagesText = {
    hungarian: 'magyar',
    english: 'angol',
    german: 'német',
    french: 'francia',
    chinese: 'kínai',
    russian: 'orosz',
    romanian: 'román',
    spanish: 'spanyol',
  };

  const servicesText = {
    accounting: 'Könyvelés',
    taxconsulting: 'Adótanácsadás',
    taxconsultingsimple: 'Adótanácsadás - egyszerű',
    taxconsultingcomplex: 'Adótanácsadás - komplex',
    legalentityfounding: 'Cégalapítás',
    payroll: 'Bérszámfejtés',
    audit: 'Könyvvizsgálat',
    liquidation: 'Végelszámolás',
  };

  let languages = [];
  let servicesHU = [];
  let pickedDate = '';
  const data = {
    providerName: providerData?.companyname ? providerData.companyname : ['könyvelő neve'],
    providerPhone: providerData?.phone ? providerData.phone : ['könyvelő telefon'],
    providerEmail: providerData?.email ? providerData.email : ['könyvelő email'],
    customerName: tempData.companylegalname
      ? tempData.companylegalname
      : tempData.companyctcname
      ? tempData.companyctcname
      : ['kereső neve'],
    customerPhone: tempData.companyPhone ? tempData.companyPhone : ['könyvelő telefon'],
    customerEmail: tempData.email ? tempData.email : ['könyvelő email'],
    vat:
      tempData.vatFilter === true || tempData.vatFilter === 'true'
        ? `Igen, ${companyText[tempData.vatPayment]}`
        : 'Nem',
    foreignTrade:
      tempData.foreign_trade === true || tempData.foreign_trade === 'true'
        ? `Igen, ${companyText[tempData.foreigntradeEU]}`
        : 'Nem',
    services: servicesHU,
  };

  for (let i in languagesText) {
    if (tempData.languages[i]) {
      languages.push(languagesText[i]);
    }
  }

  Object.entries(servicesText).forEach(([key, value]) => {
    tempData.services.forEach((e) => {
      if (e === key) {
        servicesHU.push(value);
      }
    });
  });
  switch (tempData.chosendate) {
    case 'asap':
      pickedDate = 'Néhány napon belül.';
      break;
    case '2weeks':
      pickedDate = 'Egy-két héten belül';
      break;
    case '2months':
      pickedDate = 'Egy-két hónapon belül';
      break;
    case '6months':
      pickedDate = 'Későbbi időpontban';
      break;
    case 'idk':
      pickedDate = 'Csak érdeklődik';
      break;

    default:
      pickedDate = tempData.chosendate;
  }

  switch (chosenTemp) {
    case 'Admin - Customer - BupaIntro':
      template = `<footer>Kedves ...</footer>
          <p>          
            Egyéni vállalkozás alapítás - minden egy helyen, szakértői segítséggel - ráadásul akár ingyen! Amennyiben a BUPA-n 
            keresztül indítja el Egyéni vállalkozásának bejegyzését és utána egy első évben ingyenes MBH META számlacsomagot nyit, úgy:
          </p>
          <ul>
            <li>örökké ingyenesen használhatja a BUPA digitális számlázót;</li>
            <li>egy évig ingyenesen vezetheti  számláját az MBH-nál (META számlacsomag választása esetén);</li>
            <li>
              az EV alapítás teljes díját (11.900 forint, amiért cserében egy szakértő könyvelő vezeti végig az alapítás folyamatán) 
              jóváírjuk a bankszámláján - így ez gyakorlatilag teljesen ingyenes ebben az esetben.{' '}
            </li>
          </ul>
          <p>
            Ha érdekli a lehetőség, kérem kattintson az alábbi linkre:
            <a href="https://bupa.hu/bupa/ev-alapitas?utm_source=bookkeepie&utm_medium=referral&utm_campaign=company&utm_term=EV_start">
              BUPA vállalkozás indítás
            </a>
          </p>
          <p>
            Ha bizonytalan a megfelelő cég- és adózási formát illetően, és szeretné egy könyvelővel egyeztetni ez ügyben, ebben is 
            segítenek tapasztalt könyvelő kollégáink, sőt! Vállalkozási és adózási forma konzultációnk 19.900.- os díjából most a BUPA 
            ügyfeleknek 50% kedvezményt adunk.
          </p>
          <p>Üdvözlettel,</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - noCtcClosing':
      template = `<p>Kedves ...</p>
          <p>
            Többszöri próbálkozás után sem tudtuk elérni Önt sem emailben, sem telefonon, így sajnos le kell zárnunk a
            könyvelő keresését a rendszerünkben.
          </p>
          <p>További sok sikert kívánunk a vállalkozásában.</p>
          <p>Üdvözlettel,</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - customerPing':
      template = `<p>Kedves</p>
          <p>
            Kérem vegye fel a kapcsolatot a könyvelőjével ${data.providerName} , mivel többször is próbálta elérni, telefonon
            ${data.providerPhone} és emailben is.
          </p>
          <p>Ha már nem aktuális a könyvelő keresés, kérem azt is jelezze felénk!</p>
          <p>Üdvözlettel,</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - priceOk':
      template = `<p>Kedves ...</p>
          <p>
            Jó hírünk van! Az ügyfél ${data.customerName} szóban elfogadta az egyedi árat, kérlek keresd őt, várja a hívásodat.
            Ha nem érnéd el, kérjek írj neki emailt!
          </p>
          <p>Elérhetőségei: [telefon], [email].</p>
          <p>Üdvözlettel,</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - emptyMail':
      template = `<p>Kedves ...</p>
          <p>Üdvözlettel,</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - eTradeFormRequest':
      template = `<p>Kedves ...</p>
          <p>
            Webáruháza kapcsán egyedi árajánlatot fogunk készíteni Önnek. Szeretnénk további információkat bekérni az
            alábbi kérdőívvel, hogy árajánlatát pontosítani tudjuk:
          </p>
          <a href="https://t40tz85n2uw.typeform.com/to/DN6wqjJw">
            <p>https://t40tz85n2uw.typeform.com/to/DN6wqjJw</p>
          </a>
          <p>E-mailben vagy telefonon fogjuk Önt keresni a kiajánlott árral!</p>
          <p>További kellemes napot kívánok!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - requestDataBack':
      template = `<p>Kedves ...</p>
          <p>Mikorra várhatjuk az adatbekérő visszaküldését?</p>
          <p>Szükséges lenne a továbblépéshez, köszönöm!</p>
          <p>Üdvözlettel,</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - requestContractBack':
      template = `<p>Kedves ...</p>
          <p>Mikorra várhatjuk a szerződés visszaküldését?</p>
          <p>Szükséges lenne a továbblépéshez, köszönöm!</p>
          <p>Üdvözlettel,</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - taxConCanHappen':
      template = `<p>Kedves ...</p>
          <p>${data.customerName} rendezte a számlát, mehet majd vele a tanácsadás, köszönöm!</p>
          <p>
            Ha megtörtént jelezd kérlek, miben maradtatok, és akkor küldjük neki az adatbekérőt, szerződést a további
            folyamathoz, köszönöm!
          </p>
          <p>További klassz napot kívánok!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - goodToGo':
      template = `<p><strong>Kedves ...!</strong></p>
          <p>
            Hivatkozásban küldöm a megrendelőt, el tudjátok kezdeni a közös munkát ......... új ügyfeleddel!
          </p>
          <p>
            <strong>
              MEGRENDELŐ PDF LINK
            </strong>
          </p>
          <p>
            További kellemes napot kívánok!
          </p>
          <p>Üdvözlettel:</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - sendingContract':
      template = `<p>Tisztelt Ügyfelünk,</p>
          <p>Nagyon örülünk, hogy bizalmával megtisztelt és minket választott!</p>
          <p>Hivatkozásban küldöm a könyvelési szolgáltatásról szóló szerződését.</p>
          <p>Ezt két módon juttathatja vissza hozzánk aláírva:</p>
          <ul>
            <li>
              Vagy kinyomtatja, aláírja és elküldi nekünk postai úton a Bookkeepie.com International Kft címére (1071
              Budapest, Damjanich utca 19. 2. em. 15.)
            </li>
            <li>
              Vagy az Ügyfélkapuja segítségével elektronikusan írja alá - ez egyszerűbb, mint ahogy elsőre hangzik és
              védjük vele a fákat is:
              <ul>
                <li>
                  kattintson az alábbi linkre:
                  <a href="https://magyarorszag.hu/szuf_avdh_feltoltes">https://magyarorszag.hu/szuf_avdh_feltoltes</a>
                </li>
                <li>lépjen be az ügyfélkapu felhasználó nevével és jelszavával</li>
                <li>töltse fel az általunk csatolt szerződést</li>
                <li>
                  írja alá ügyfélkapuva <a href="https://www.youtube.com/watch?v=KXfdX7joCt4">(magyarázó videó itt)</a>
                </li>
                <li>töltse le a már aláírt szerződést</li>
              </ul>
            </li>
            <li>
              és küldje vissza válaszlevélben erre az e-mailünkre (figyeljen, hogy az aláírt verziót csatolja) Bármilyen
              kérdés esetén szívesen állunk rendelkezésére.
            </li>
          </ul>
          <p>Köszönettel,</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - requestUpdate':
      template = `<p>Kedves ...</p>
          <p>Elérted az ügyfelet, fel tudtátok venni a kapcsolatot?</p>
          <p>Köszönöm!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - yourprovider':
      template = `<p>Kedves ...</p>
          <p>
            [Szolgáltató neve] lesz az Ön tanácsadója, könyvelője, aki [Megkeresés ideje] napon telefonon fogja keresni.
          </p>
          <p>
            [Szolgáltató megszólítása] elérhetősége: [Szolgáltató telefonszáma], [Szolgáltató e-mail], [Szolgáltató
            calendly link]
          </p>
          <p>
            Kiajánlott ár:
          </p>
          <ul>
            <li>Vállalkozás indítás egyszeri díja: 11.900 Ft</li>
            <li>Könyvelési díj: Ft / hó</li>
            <li>Bérszámfejtés: Ft / fő / hó</li>
          </ul>
          <p><strong>
            Mi történik a könyvelővel való egyeztetés után?
          </strong></p>
          <p>
            1. A megbeszélés után <strong>szeretnénk visszajelzést kérni Öntől</strong>, hogy miben maradtak könyvelővel 
            a további lépések kapcsán. Amennyiben nem elégedett vagy nem nyerte el tetszését a kiajánlott könyvelőnk a 
            megbeszélés alatt, kérem jelezze felénk és biztosítunk Önnek másik könyvelőt. 
          </p>
          <p>
            2. <strong>Ha úgy dönt, hogy szeretne a könyvelővel együtt dolgozni</strong>, akkor <strong>emailben küldjük 
            Önnek a szerződéshez szükséges adatbekérőt</strong>. Amennyiben kérdése lenne a kitöltéssel kapcsolatban, 
            kérem ügyfélszolgálatunkat keresse fel, NE a könyvelőjét.
          </p>
          <p>
            3. Az adatbekérő kitöltése után <strong>szerződéstervezetet küldünk</strong> Önnek emaileben. 
            Az emailben szerepel a szerződés aláírásának 2 módja:
            <ul>
              <li>vagy postai úton küldi vissza részünkre, </li>
              <li>vagy ügyfélkapuja segítségével elektronikusan is aláírhatja.</li>
            </ul>
            <i>
              (Fontos tudnia, hogy ha az elektronikus aláírást választja, az aláírás után nem küldi el részünkre a rendszer 
              automatikusan az aláírt dokumentumot. Kérem töltse le, és válaszlevélben küldje el részünkre.)
            </i>
          </p>
          <p>
            4. A szerződés aláírását követően <strong>elküldjük az első havi számlát</strong>. Ha ezt rendezte, 
            akkor indulhat a könyvelővel a közös munka. (Ez biztosíték a könyvelő partnereink felé, hogy az elvégzett 
            munkájuk garantáltan kifizetésre kerül.)
          </p>
          <p>
            Amennyiben a könyvelő nem éri el telefonon, emailben fog jelentkezni, legyenek szívesek felvenni egymással a kapcsolatot.
          </p>
          <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - introductiontoprovider':
      template = `<p>Tisztelt leendő Ügyfelünk!</p>
          <p>
            Mai telefonbeszélgetésünkre hivatkozva, mellékelten küldöm a Bookkeepie bemutatkozó anyagát. Weboldalunkon
            keresztül könyvelőirodáknak segítünk abban, hogy egyszerűen és gyorsan találhassák meg megfelelő üzleti
            partnereiket.
          </p>
          <p>
            <ul>
              Miért éri meg a Bookkeepie-hez csatlakozni?
              <li>
                A könyvelő megválaszthatja, hogy milyen ügyfelek ajánlatkéréseit szeretné fogadni. Szűrőbeállításaink
                lehetőséget adnak szolgáltató igényei szerint megszabni az ajánlatot kérő cégek körét. Ez azt
                biztosítja, hogy csakis olyan ajánlatot kapjon, melyek mindenben megfelelnek az Ön igényeinek.
              </li>
              <li>Előszűrjük az ügyfeleket, biztosítva azt, hogy csak az igazán komoly érdeklődők jussanak el Önhöz</li>
              <li>Az előfizetési díjat úgy számoltuk ki, hogy az már egy ügyfél megszerzése esetén megtérüljön.</li>
              <li>
                Nem kell az ügyféllel az áron alkudozni, és az esetleges nemfizetés esetén a behajtást mi intézzük.
              </li>
              <li>
                Megosztható “digitális névjegykártya” is része az előfizetési csomagnak, amivel igényes és informatív
                módon mutatkozhat be potenciális ügyfeleinek
              </li>
            </ul>
          </p>
          <p>Miben nyújt még többet Önnek a Bookkeepie?</p>
          <p>
            Az alábbi oldalon nemcsak a jelenlegi helyzetéről kap valós képet, hanem segítséget is ahhoz, ha fejleszteni
            szeretne rajta. Minősítésének eredményét ügyfeleivel is meg tudja osztani, így ők is láthatják, mennyire
            felkészült Ön a minden eddiginél gyorsabban terjedő digitalizáció témakörében.
          </p>
          <a href="https://www.digitaliskonyvelok.hu">
            <p>https://www.digitaliskonyvelok.hu</p>
          </a>
          <p>
            További információkért, és a regisztráció megkezdéséért kattintson az alábbi a linkre vagy olvassa el a
            mellékelt bemutatkozónkat!
          </p>
          <a href="https://www.bookkeepie.com/hu/konyveloi-regisztracio">
            <p>https://www.bookkeepie.com/hu/konyveloi-regisztracio</p>
          </a>
          <p>Bármilyen kérdésére örömmel válaszolok, keressen bizalommal!</p>
          <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - sendingrfpdetails':
      template = `<p>Kedves ...</p>
          <p>Rövidesen küldöm az alábbi ügyfelekhez tartozó ajánlatkérések részleteit.</p>
          <p>[ügyfelek lista]</p>
          <p>
            Kérlek a mai nap folyamán keresd őket, ha esetleg nem tudtad őket elérni, akkor jelezz vissza légy szíves!
          </p>
          <p>További tartalmas napot kívánok!</p>

          <p>Üdvözlettel:</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - caffinalize':
      template = `<p>Kedves ...</p>
          <p>${data.customerName} fel tudtátok venni a kapcsolatot, megtörtént a tanácsadás?</p>
          <p>Ha igen miben maradtatok, mi lesz nála a cégforma?</p>
          <p>Köszönöm!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - requestforreview':
      template = `<p>Kedves ...</p>
          <p>Először is szeretném megköszönni, hogy időt szán ránk érékelésével!</p>
          <p>Kérem értékelését válaszlevélben vagy a nina@bookkeepie.com emailcímre küldje el részemre.</p>
          <p>Az értékelés 3 módon teheti meg:</p>
          <ol>
          <li>Ír egy-két mondatot nekünk e-mailben, küld hozzá egy fotót, amit kirakhatunk a weboldalunkra vagy a
            facebook oldalunkra. (Ha ír egy rövid mondatot, ami nem nagyon marketing szagú, azt szívesen kiírjuk a cége
            neve mellé, hátha ezzel tudunk egy kis érdeklődést generálni)
          </li>
          <li>Az előző ponttal együtt, ha hagy egy ötös értékelést rólunk, esetleg egy két kedves szót, az elképesztően
            sokat segít, hogy mások is lássák, hogy tényleg gyorsan, érdemi segítséget kaphatnak tőlünk. Ha ez lenne a
            legszimpatikusabb mód, akkor az alábbi linkek egyikén értékelést hagyhat.
          </li>
          <ul>
            <li>
              <a href="https://goo.gl/maps/yMjvHgHgi8MogLnx7">google maps</a>
            </li>
            <li>
              <a href="https://www.facebook.com/BookkeepieHU">facebook</a>
            </li>
            <li>
              <a href="https://www.trustpilot.com/evaluate/bookkeepie.com">trustpilot</a>
            </li>
          </ul>
          <li>Ez a pont egy kicsit izgalmasabb, mert hálánk jeléül kedvezményben részesítjük</li>
          </ol>
          <p>
            Egy rövid (1-2 perces) videós ajánlást kérünk, amiket otthon, telefonnal fel tud venni, küldünk hozzá
            kérdéseket és mindenféle sallang vagy extra nélkül csak mondja el, hogy milyen volt velünk dolgozni. Ez az
            utóbbi persze nem zárja ki a többi értékelési módot, de jelenleg ez nekünk a legértékesebb, ezért 20 ezer
            forint kedvezményt adunk a következő havi könyvelési díjból (ha a könyvelési díja ennél alacsonyabb összegű,
            akkor az a hónap ajándék).
          </p>
          <p>Az elkészült videó a nina@bookkeepie.com email címre küldje vagy töltse fel erre a Google Drive linkre.</p>
          <p>
            Kérem jelezze részemre, ha a 3. pontban szereplő kedvezményes értékelést választja és küldöm a támogató
            kérdéseket a videó készítéséhez.
          </p>
          <p>További kellemes napot kívánok!</p>
          <p>Üdvözlettel:</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - reviewguide':
      template = `<p>Kedves ...</p>
          <p>Először is szeretném megköszönni, hogy időt szán ránk érékelésével!</p>
          <p>Kérem értékelését válaszlevélben vagy a nina@bookkeepie.com emailcímre küldje el részemre.</p>
          <p>
            A videós értékeléshez szeretnék küldeni Önnek témaköri támogatást, amelyeket felhasználhat a videó készítése
            során:
          </p>
          <p>
            a; Miért döntött úgy, hogy minket választ? (Fájdalmak előtte + mik voltak a fő kérdései és MI GYŐZTE MEG) -
            könyvelő keresés és cégalapítás kapcsán is
          </p>
          <p>b; Milyen volt az élete előtte? (Lehetőleg kicsit részletezni a nehézségeket, aggályokat, problémákat.)</p>
          <p>
            c; Mi volt a félelme, amin a legtöbbet gondolkodott, mielőtt konkrétan belevágott? (Ami miatt esetleg nem
            volt még 100%-os a dologban)
          </p>
          <p>d; Mi győzte meg, hogy belevágjon mégis?</p>
          <p>e;Milyen (egyszerű) volt az egész folyamat? (kezdettől végig)</p>
          <p>
            f; Mennyire érte meg? Próbált esetleg magának megoldani a könyvelést? Illetve ha korábban keresett már
            könyvelőt, azzal összevetve mennyivel gyorsabb, egyszerűbb?
          </p>
          <p>g; Milyen érzés, hogy ez a probléma el van intézve? (Felszabadult, szabad, stb)</p>
          <p>h; Milyen lenne az élete, ha nem végezte volna el ezt az egészet? (vs milyen most?)</p>
          <p>i; Kinek ajánlaná legjobban?</p>
          <p>j; Mi volt az egészben a legjobb?</p>
          <p>
            k; Ha előre tudta volna, hogy ilyen jó lesz, akkor mennyivel korábban csinálta volna ezt meg és ez mennyi
            energiát/pénzt,/időt spórolt volna meg?
          </p>
          <p>
            Ahogy telefonban is említettem, a videós értékelés beérkezése után hálánk jeléül szeretnénk megajándékozni
            20.000 Ft kedvezménnyel a következő havi könyvelési díjból!
          </p>
          <p>Köszönjük rugalmasságát és gyorsaságát!</p>
          <p>Köszönettel:</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - cafunpaid':
      template = `<p>Kedves ...</p>
          <p>Nyilvántartásunk szerint az adózási forma konzultáció számlája mai napig nem került kifizetésre!</p>
          <p>
            <p>
              [Számla száma] számú számlája késedelmes, a kintlevőség összege [Számla összege] Ft, a fizetési határidő
              napja [Számla lejárta] volt.
            </p>
            <p>Kérem, minél hamarabb rendezze a számlát, hogy tovább tudjunk lépni a folyamatokkal.</p>
            <p>Amennyiben nem tart igényt szolgáltatásunkra, kérem azt is jelezze felém!</p>
          </p>
          <p>Várom szíves visszajelzését!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - searchflowlink':
      template = `<p>Kedves ...</p>
          <p>Mai napon történt megkeresésünkre hivatkozva, küldöm Önnek a linket, amelyen könyvelőt tud keresni:</p>
          <a href="https://dashboard.konyvelo-net.hu/company/request/services">
            <p>https://dashboard.konyvelo-net.hu/company/request/services</p>
          </a>
          <p>
            <p>Az oldalon található pontokon kell végigmenni, és a végén adja ki a találatokat.</p>
            <p>Be tudja írni mely területen keres könyvelőt, és milyen feltételekkel.</p>
          </p>
          <p>Ha még bármilyen kérdése van, nyugodtan keressen minket, további szép napot!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - contractexpired':
      template = `<p>Kedves ...</p>
          <p>
            Próbáltam Önt többször elérni egyeztetés céljából telefonon az alábbi telefonszámról [Saját telefonszám]
            sajnos sikertelenül.
          </p>
          <p>Szeretném tájékoztatni, hogy Bookkeepie könyvelési szolgáltatást tartalmazó szerződése lejárt.</p>
          <p>
            A szerződésben szereplő 1 évig garantált ár a szerződést lejártát követően az infláció árával emelkedett.
          </p>
          <p>1 évig garantált könyvelési ár: [Előző könyvelési ár]</p>
          <p>Inflációval emelt könyvelési ár: [Emelt könyvelési ár]</p>
          <p>
            Szeretném tájékoztatni, hogy kedvezményesebb összeget tudunk Önnek ajánlani amennyiben értékelést küld
            részünkre az elmúlt év könyvelő keresési és könyvelési tapasztalatairól. Ha szeretne élni a lehetőséggel
            kérem jelezze felém.
          </p>
          <p>Ha úgy dönt nem szeretne további szolgáltatásokat kérni tőlünk kérem azt is jelezze felém.</p>
          <p>Várom szíves visszajelzését!</p>
          <p>További kellemes napot kívánok!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - browsing':
      template = `<p>Tisztelt Érdeklődő!</p>
          <p>Rendszerünk jelezte, hogy érdeklődött az oldalunkon.</p>
          <p>
            Tudok Önnek valamiben segíteni? Amennyiben elküldi a telefonszámát, szívesen tájékoztatom a részletekről,
            illetve amennyiben érdekes lehet az Ön számára, szívesen csatolok egy bemutatkozó anyagot.
          </p>
          <p>Várom szíves visszajelzését, addig is további szép napot!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - aspcouldntreach':
      template = `<p>Kedves ...!</p>
        <p>
          <strong>        
          Örömmel fogadtuk könyvelőkeresési szándékát!
          </strong>
        </p>
        <p>
          Megkíséreltem elérni Önt telefonon az alábbi számról [Saját telefonszám] - sajnos sikertelenül.
        </p>
        <p>
          Kérem, amikor Önnek alkalmas legyen kedves napközben visszahívni, vagy az alábbi lehetőségek egyikén 
          időpontot foglalni egy 5-10 perces rövid egyeztetésre, hogy össze tudjam kötni, az Ön igényeinek 
          legjobban megfelelő könyvelővel.
        </p>
        <p>
          <strong>
            Időpontok:
          </strong>
        </p>
        <p>
          <a href="https://cal.com/team/bookkeepie-magyarorszag/bookkeepie-ugyintezes-idopontfoglalas-delelott?date=2024-10-11&month=2024-10">DÉLELŐTTI IDŐPONTOK</a>
        </p>
        <p>
          <a href="https://cal.com/team/bookkeepie-magyarorszag/delutani-bookkeepie-ugyintezes-idopontfoglalas?date=2024-10-10&month=2024-10">DÉLUTÁNI IDŐPONTOK</a>
        </p>
        <p>
          A beszélgetés, és az az ajánlatkérés természetesen ingyenes, és 1 évig garantáljuk az árat a szolgáltatásra,
          ha most visszajelez.
        </p>
        <p>Köszönettel,</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - cafcouldntreach':
      template = `<p>Kedves ...</p>
          <p>
            Örömmel fogadtuk könyvelő keresési szándékát! Megkíséreltem elérni Önt telefonon az alábbi számról [Saját
            telefonszám] - sajnos sikertelenül. Kérem, amikor Önnek alkalmas legyen kedves napközben visszahívni, vagy
            e-mailben jelezni, hogy mikor kereshetem, egy 5-10 perces rövid egyeztetésre, hogy össze tudjam kötni, az Ön
            igényeinek legjobban megfelelő könyvelővel.
          </p>
          <p>
            A beszélgetés, és az az ajánlatkérés természetesen ingyenes, és 1 évig garantáljuk az árat a szolgáltatásra,
            ha most visszajelez.
          </p>
          <p>Köszönettel,</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - reglink':
      template = `<p>Kedves ...</p>
          <p>
            Ezen a linken tud csatlakozni:{' '}
            <a href="https://dashboard.konyvelo-net.hu/provider/register">
              https://dashboard.konyvelo-net.hu/provider/register
            </a>
          </p>
          <p>Ha bármilyen kérdés merülne fel, nyugodtan keressen!</p>
          <p>Üdvözlettel,</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - accountrequestform':
      template = `<p>Kedves ...</p>
        <p>
            Legyen szíves az adótanácsadási szolgáltatásról szóló számlához elküldeni az alábbi adatokat, hogy azután
            elindulhasson a könyvelővel a közös munka.
        </p>
        <p>Amennyiben még nincsen vállalkozás, akkor a magánszemély adatai elegendőek.</p>
        <p>
          <ul>
              <li>magánszemély esetén: név, cím, e-mail cím</li>
              <li>vállalkozás esetén: név, cím (székhely), adószám, e-mail cím</li>
          </ul>
        </p>
        <p>
        A beszélgetés után várom majd a visszajelzését, hogy a könyvelési szolgáltatást is igénybe kívánja-e venni,
        és amennyiben igen, akkor egy új form indítása szükséges (csak könyvelésre)
        </p>
        <p>Köszönöm!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - contractdatarequestform':
      template = `<p>Kedves ...!</p>
          <p>
            Az általunk ajánlott szakemberrel történt egyeztetésük után,  küldöm Önnek ajánlatunkat, 
            illetve minden egyéb fontos információt.
          </p>
          <p><strong>
            Kiajánlott ár:
          </strong></p> 
          <ul>
            <li>Vállalkozás indítás egyszeri díja: 11.900 Ft</li>
            <li>Könyvelési díj:  Ft / hó</li>
            <li>Bérszámfejtés: Ft/ fő / hó</li>
          </ul>

          <p>
            Amennyiben a könyvelő személye és a fenti árak is megfelelőek, már csak egyetlen lépés 
            választja el attól, hogy megállapodjunk.
          </p>
          <p>
            Kérjük adja meg néhány adatát az alábbi kérdőíven keresztül, hogy ez alapján elkészíthessük a szerződését.
          </p>
          <a href="https://bit.ly/3NFdfVG">
            <p>https://bit.ly/3NFdfVG</p>
          </a>
          <p>
            Ha szeretné elolvasni előre, a bianco szerződést 
            <a href="https://drive.google.com/file/d/1SMvX23MyI3zyICizyueGX9FMqF_95ROZ/view">itt találja</a>
          </p>
          <p>
            Bármilyen kérdése vagy visszajelzése volna, kérem keressen bizalommal e-mailben vagy telefonon.
          </p>
          <p>Üdvözlettel,</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - firstinvoiceaftercontract':
      template = `<p>Kedves ...!</p>
            <p>
              Köszönöm szépen az aláírt szerződést, mai napon küldöm Önnek az első havi számláját, melynek 
              fizetési határideje 2 nap, hogy a közös munka minnél hamarabb elkezdődhessen.
            </p>
            <p>
              További könyvelési számláit 8 napos fizetési határidővel fogjuk küldeni Önnek minden hónap első napján. 
            </p>
            <p>
              További kérdés és kérés esetén keressen bizalommal!
            </p>
            <p>Üdvözlettel,</p>
            <p id="signature"></p>
            `;
      break;
    case 'Admin - Customer - contractsign':
      template = `<p>Kedves!</p>
          <p>
            Nagyon örülünk, hogy bizalmával megtisztelt és minket választott! Alább küldöm a könyvelői szolgáltatásról
            szóló szerződését.
          </p>
          <p>Ezt két módon juttathatja vissza hozzánk aláírva:</p>
          <br />
          <ol>
            <li>
              <p>
                Vagy kinyomtatja, aláírja és elküldi nekünk postai úton a Bookkeepie címére (1071 Budapest, Damjanich
                utca 19. 2.em. 15.)
              </p>
              <br />

              <p>
                <ul>
                  Vagy az Ügyfélkapuja segítségével elektronikusan írja alá - ez egyszerűbb mint ahogy elsőre hangzik és
                  védjük vele a fákat is:
                  <li>
                    kattintson az alábbi linkre:
                    <a href="https://magyarorszag.hu/szuf_avdh_feltoltes">
                      https://magyarorszag.hu/szuf_avdh_feltoltes
                    </a>
                  </li>
                  <li>lépjen be az ügyfélkapu felhasználó nevével és jelszavával</li>
                  <li>töltse fel az általunk készített szerződést</li>
                  <li>
                    írja alá ügyfélkapuval
                    <a href="https://www.youtube.com/watch?v=KXfdX7joCt4">(magyarázó videó itt)</a>
                  </li>
                  <li>töltse le a már aláírt szerződést</li>
                </ul>
              </p>
            </li>
            <li>
              <p>és küldje vissza válasz levélben erre az emailünkre (figyeljen, hogy az aláírt verziót csatolja),</p>
            </li>
          </ol>
          <p>Szerződését az alábbi linkről tudja letölteni:</p>
          <p>[Szerződés link]</p>
          <p>Bármi kérdés esetén szívesen állunk rendelkezésére.</p>
          <p>Köszönettel,</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - multiplecompanyreq':
      template = `<p>Kedves ...</p>
          <p>Mai beszélgetésünkre hivatkozva küldöm az információkat.</p>
          <p>Az alábbi linken éri el a könyvelő kereséssel kapcsolatos kérdéseket:</p>
          <p>[Több céges link]</p>
          <p>
            Kérem, amennyire csak tudja pontosan válaszolja meg a kérdéseket, cégenként egy oszlopban. Ez alapján fogunk
            tudni releváns könyvelőket és ajánlatokat adni. Amennyiben a szolgáltatásoknál nem szerepel, amit Ön
            szeretne, nyugodtan tüntesse fel a "speciális igényeknél" a 18. sorban.
          </p>
          <p>Várva szíves visszajelzését annak kapcsán is, hogy mikortól bízná meg a könyvelőt?</p>
          <p>Amikor visszaküldte a kérdőívet, keresni fogom a további részletekkel:</p>
          <p>További tartalmas napot kívánok, köszönettel!</p>
        <p id="signature"></p>
          `;
      break;

    case 'Admin - Customer - satisfactionaftertalkwithsp':
      template = `<p>Kedves ...</p>
          <p>
            Szeretnék érdeklődni, hogy mik voltak a tapasztalatai, benyomásai a könyvelőjével ( ${data.providerName} ) való
            beszélgetés után?
          </p>
          <p>Miben maradtak a könyvelési szolgáltatás kapcsán?</p>
          <p>Várva szíves visszajelzését, további tartalmas napot kívánok!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - wascontactdone':
      template = `<p>Kedves ...</p>
          <p>${data.customerName} fel tudtátok venni a kapcsolatot?</p>
          <p>Ha igen miben maradtatok?</p>
          <p>Köszönöm!</p>
        <p id="signature"></p>
          `;
      break;

    case 'Admin - Provider - reviewtemplate':
      template = `<p>Kedves ...</p>
          <p>Örömmel köszöntöm a felhasználóink sorában!</p>
          <p>
            A korábban beszélteknek megfelelően az alábbiakban küldöm azt a linket, amin a könyvelő a korábbi ügyfelei
            ajánlásait tudja kérni:
            <a href="https://forms.gle/vNVZXyw9pfN14Tv68"> https://forms.gle/vNVZXyw9pfN14Tv68</a>
          </p>
          <p>Egy könyvelő irodától 10 ilyen ajánlást tudunk feltölteni.</p>
          <p>
            Hogy megkönnyítsük az ajánlás kérést egy rövid üzenetet is összeállítottunk, amit tovább tud küldeni
            ügyfelei részére.
          </p>
          <p>"Kedves Ügyfelünk,</p>
          <p>
            Nemrég regisztráltunk a Bookkeepie internetes könyvelő kereső portálra, ahol az alapvető információkon felül
            a szolgáltatói profilokhoz ügyfél értékeléseket és ajánlásokat is gyűjtenek.
          </p>
          <p>
            Mint egyik kulcs ügyfelünktől, szeretnék Tőletek is egy 3 perces segítséget kérni az alábbi értékelői
            kérdőív kitöltésével:
          </p>
          <a href="https://forms.gle/vNVZXyw9pfN14Tv68">
            <p>https://forms.gle/vNVZXyw9pfN14Tv68</p>
          </a>
          <p>
            Ha bármilyen kérdés volna ezzel kapcsolatban, kérlek keressenek engem vagy a Bookkeepie csapatát
            (info-hu@bookkeepie.com ).
          </p>
          <p>Üdvözlettel,</p>
          <p>Aláírás</p>
          <p>További eredményes napot kívánok!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - responserequest2':
      template = `<p>Kedves ...</p>
          <p>Várom szíves visszajelzését, ha úgy dönt, hogy minket választ, és bíz meg a könyvelő kereséssel.</p>
          <p>Ha már nem aktuális a keresés, kérem azt is jelezze, köszönöm!</p>
          <p>Üdvözlettel:</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - newcafcustomer':
      template = `<p>Kedves ...</p>
          <p>
            Küldöm az ügyfélről az infókat, kérlek vedd fel vele ekkor ( [Ekkor] ) a kapcsolatot, ha nem éred el
            telefonon, akkor küldd el neki a mail címed, hogy el tudjátok egymást érni. 
          </p>
          <p>Kérlek jelezd, ha már találtatok időpontot, az adótanácsadás után pedig beszéljünk, hogy miben maradtatok, meg tudtatok-e állapodni, mi lesz nála a végleges cégforma?</p>

          <p>A tanácsadás alatt / után, kérlek töltsd ki az alábbi linken található kérdőívet: <br /> <a href='https://dashboard-hu.bookkeepie.com/provider/vafform/${
            tempData._id
          }'>--- Form kitöltése itt ---</a> </p>
<ul>
<li>Ügyfél neve: ${data.customerName}</li>
<li>Ügyfél email címe: ${data.customerEmail}</li>
<li>Ügyfél telefon: ${data.customerPhone}</li>
<li>Kiajánlott ár: </li>
<li>Könyvelő megbízási díja: </li>
<li>Igényelt szolgáltatások: ${servicesHU} </li>
<li>Alkalmazottak száma, fő: ${tempData.noofemployees}</li>
<li>Éves árbevétel, millió forint: ${tempData.yearlyrevenue}</li>
<li>Bejövő számlák, havonta: ${tempData.incominginvoices}</li>
<li>Kimenő számlák, havonta: ${tempData.outgoinginvoices}</li>
<li>Iparág: ${companyText[tempData.industry]}</li>

<li>Cégforma: ${tempData.companytype}</li>
<li>Irányítószám: ${tempData.zip_code}</li>
<li>ÁFA alanyiság: ${data.vat}</li>
<li>Végez külkereskedelmet? ${data.foreignTrade}</li>
<li>Beszélt nyelv(ek): ${languages}</li>
<li>Mikortól kéri a szolgáltatást: ${pickedDate}</li>

<!-- GENERAL -->

${
  tempData.industryspecifics?.general?.simplifiedpayroll?.status
    ? `<li>EFO-s bérszámfejtés: 
  ${tempData.industryspecifics.general.simplifiedpayroll.status === 'true' ? 'Igen' : 'Nem'}</li>
  ${
    tempData.industryspecifics.general.simplifiedpayroll.status === 'true' &&
    tempData.industryspecifics.general.simplifiedpayroll.count
      ? `<li>EFO-s munkavállalók száma: ${tempData.industryspecifics.general.simplifiedpayroll.count}</li>`
      : ''
  }`
    : ''
}

${
  tempData.industryspecifics?.general?.registersimplifiedpayroll?.status
    ? `<li>Az EFO-s munkatársak bejelentését az ügyfél végzi: 
  ${tempData.industryspecifics.general.registersimplifiedpayroll.status === 'true' ? 'Igen' : 'Nem'}</li>`
    : ''
}

${
  tempData.industryspecifics?.general?.severallocations?.status
    ? `<li>Több telephellyel rendelkezik: 
  ${tempData.industryspecifics.general.severallocations.status === 'true' ? 'Igen' : 'Nem'}</li>
  ${
    tempData.industryspecifics.general.severallocations.status === 'true'
      ? `<li>Telephelyek száma: ${tempData.industryspecifics.general.severallocations.locationcount || 'N/A'}</li>`
      : ''
  }`
    : ''
}

${
  tempData.industryspecifics?.general?.companycar?.status
    ? `<li>Céges autóval rendelkezik: 
  ${tempData.industryspecifics.general.companycar.status === 'true' ? 'Igen' : 'Nem'}</li>
  ${
    tempData.industryspecifics.general.companycar.status === 'true'
      ? `<li>Autók száma: ${tempData.industryspecifics.general.companycar.carcount || 'N/A'}</li>`
      : ''
  }`
    : ''
}

${
  tempData.industryspecifics?.general?.cashregister?.status
    ? `<li>Pénztárgéppel rendelkezik: 
  ${tempData.industryspecifics.general.cashregister.status === 'true' ? 'Igen' : 'Nem'}</li>
  ${
    tempData.industryspecifics.general.cashregister.status === 'true'
      ? `<li>Pénztárgépek száma: ${tempData.industryspecifics.general.cashregister.cashregistercount || 'N/A'}</li>`
      : ''
  }`
    : ''
}

${
  tempData.industryspecifics?.general?.multipleaccounts?.status
    ? `<li>Több bankszámlával rendelkezik: 
  ${tempData.industryspecifics.general.multipleaccounts.status === 'true' ? 'Igen' : 'Nem'}</li>
  ${
    tempData.industryspecifics.general.multipleaccounts.status === 'true'
      ? `<li>Bankszámlák száma: ${tempData.industryspecifics.general.multipleaccounts.accountcount || 'N/A'}</li>`
      : ''
  }`
    : ''
}

${
  tempData.industryspecifics?.general?.customertype?.selectedoption
    ? `<li>Ügyféltípus: 
  ${tempData.industryspecifics.general.customertype.selectedoption === 'private' ? 'Kizárólag magánszemélyek' : ''}
  ${
    tempData.industryspecifics.general.customertype.selectedoption === 'mainlyprivate'
      ? 'Főként magánszemélyek, de van egy-két vállalkozás is'
      : ''
  }
  ${
    tempData.industryspecifics.general.customertype.selectedoption === 'mixed'
      ? 'Magánszemélyek és vállalkozások vegyesen'
      : ''
  }
  ${tempData.industryspecifics.general.customertype.selectedoption === 'business' ? 'Kizárólag vállalkozások' : ''}
  ${tempData.industryspecifics.general.customertype.selectedoption === 'idk' ? 'Nem tudom' : ''}</li>`
    : ''
}

${
  tempData.industryspecifics?.general?.cashflowvat?.status
    ? `<li>Pénzforgalmi ÁFÁs: 
  ${tempData.industryspecifics.general.cashflowvat.status === 'true' ? 'Igen' : 'Nem'}</li>`
    : ''
}

${
  tempData.industryspecifics?.general?.loanreview?.status
    ? `<li>Hitelfelülvizsgálat: 
  ${tempData.industryspecifics.general.loanreview.status === 'true' ? 'Igen' : 'Nem'}</li>
  <li>Felülvizsgálatok száma: ${tempData.industryspecifics.general.loanreview.reviewcount || 'N/A'}</li>
  <li>Gyakorisága: 
  ${
    tempData.industryspecifics.general.loanreview.reviewfrequency === 'monthly'
      ? 'Havi'
      : tempData.industryspecifics.general.loanreview.reviewfrequency === 'quarterly'
      ? 'Negyedéves'
      : tempData.industryspecifics.general.loanreview.reviewfrequency === 'semiannually'
      ? 'Féléves'
      : tempData.industryspecifics.general.loanreview.reviewfrequency === 'annually'
      ? 'Éves'
      : tempData.industryspecifics.general.loanreview.reviewfrequency === 'idk'
      ? 'Nem tudom'
      : 'N/A'
  }
  </li>`
    : ''
}

${
  tempData.industryspecifics?.general?.earlyclosure?.status
    ? `<li>Évközi zárás: 
  ${tempData.industryspecifics.general.earlyclosure.status === 'true' ? 'Igen' : 'Nem'}</li>
  <li>Gyakorisága: 
  ${
    tempData.industryspecifics.general.earlyclosure.earlyclosurefrequency === 'monthly'
      ? 'Havi'
      : tempData.industryspecifics.general.earlyclosure.earlyclosurefrequency === 'quarterly'
      ? 'Negyedéves'
      : tempData.industryspecifics.general.earlyclosure.earlyclosurefrequency === 'semiannually'
      ? 'Féléves'
      : 'N/A'
  }
  </li>`
    : ''
}

${
  tempData.industryspecifics?.general?.preparingstatements?.status
    ? `<li>Statisztikák, kimutatások készítése az ügyfél kérésére (havi maximum 2 óra terjedelemben): 
  ${tempData.industryspecifics.general.preparingstatements.status === 'true' ? 'Igen' : 'Nem'}</li>`
    : ''
}

${
  tempData.industryspecifics?.general?.invoicing?.status
    ? `<li>A könyvelő számláz az ügyvezető/vállalkozó helyett: 
  ${tempData.industryspecifics.general.invoicing.status === 'true' ? 'Igen' : 'Nem'}</li>`
    : ''
}

${
  tempData.industryspecifics?.general?.transfering?.status
    ? `<li>A könyvelő indít el banki utalásokat az ügyvezető/vállalkozó helyett: 
  ${tempData.industryspecifics.general.transfering.status === 'true' ? 'Igen' : 'Nem'}</li>`
    : ''
}

${
  tempData.industryspecifics?.general?.auditcooperation?.status
    ? `<li>Könyvvizsgálatra kötelezett a vállalkozás: 
  ${tempData.industryspecifics.general.auditcooperation.status === 'true' ? 'Igen' : 'Nem'}</li>`
    : ''
}

${
  tempData.industryspecifics?.general?.vatadjusting?.status
    ? `<li>ÁFA körön kívüli bevétel: 
  ${tempData.industryspecifics.general.vatadjusting.status === 'true' ? 'Igen' : 'Nem'}</li>`
    : ''
}

${
  tempData.industryspecifics?.general?.projectsupport?.status
    ? `<li>Projekt vagy költséghely szintű számviteli nyilvántartás vezetése: 
  ${tempData.industryspecifics.general.projectsupport.status === 'true' ? 'Igen' : 'Nem'}</li>`
    : ''
}

${
  tempData.industryspecifics?.general?.environmentalfees?.status === 'true'
    ? `<li>Környezetvédelmi termékdíjak: Igen</li>
  <li>Termékdíjak kezelése: 
  ${
    tempData.industryspecifics.general.environmentalfees.handlingenvfees === 'accountant'
      ? 'A könyvelőtől várom'
      : 'Magam végzem el'
  }</li>`
    : tempData.industryspecifics?.general?.environmentalfees?.status === 'false'
    ? `<li>Környezetvédelmi termékdíjak: Nem</li>`
    : ''
}

<!-- INDUSTRY-SPECIFIC -->

${
  tempData.industry === 'vehicle' && tempData.industryspecifics?.vehicle?.differentialvat
    ? `<li>Gépjárműipar - Különbözeti adózás: 
  ${tempData.industryspecifics.vehicle.differentialvat.automotivepartstrading ? 'Gépjármű alkatrész kereskedelem' : ''}
  ${tempData.industryspecifics.vehicle.differentialvat.trading ? ', Gépjármű kereskedelem' : ''}
  ${tempData.industryspecifics.vehicle.differentialvat.service ? ', Szervizszolgáltatás' : ''}
  ${
    tempData.industryspecifics.vehicle.differentialvat.other
      ? `, Egyéb: ${tempData.industryspecifics.vehicle.differentialvat.otherinput || 'N/A'}`
      : ''
  }
  </li>`
    : ''
}

${
  tempData.industry === 'etrade' && tempData.industryspecifics?.etrade?.etradesells
    ? `<li>E-kereskedelem - Termékek amiket a webshop értékesít: 
  ${tempData.industryspecifics.etrade.etradesells.physicalproducts ? 'Fizikai termékek' : ''}
  ${tempData.industryspecifics.etrade.etradesells.services ? ', Szolgáltatások' : ''}
  ${tempData.industryspecifics.etrade.etradesells.intellectualproperty ? ', Szellemi tulajdon' : ''}
  ${
    tempData.industryspecifics.etrade.etradesells.other
      ? `, Egyéb: ${tempData.industryspecifics.etrade.etradesells.otherinput || 'N/A'}`
      : ''
  }
  </li>
  ${
    tempData.industryspecifics.etrade?.matchinginvoicesetrade?.matchingmethod
      ? `<li>E-kereskedelem - A beérkező összegek és kiállított számlák összepárosítása:
    ${
      tempData.industryspecifics.etrade.matchinginvoicesetrade.matchingmethod === 'true'
        ? 'Könyvelő segítségét kéri'
        : 'Ő végzi az összepárosítást'
    }
    </li>`
      : ''
  }
  ${
    tempData.industryspecifics?.etrade?.deadlinecompliance?.status
      ? `<li>E-kereskedelem - Vállalja, hogy az összepárosításhoz a könyvelési anyagokat a könyvelő által kért határidőre, struktúrában és formátumban adja át:
  ${tempData.industryspecifics.etrade.deadlinecompliance.status === 'true' ? 'Igen' : 'Nem'}</li>`
      : ''
  }`
    : ''
}

${
  tempData.industry === 'construction' && tempData.industryspecifics?.construction?.constructiontype
    ? `<li>Építőipar - Az építőipari munkák jellege: 
  ${tempData.industryspecifics.construction.constructiontype.specializedworks ? 'Szakipari munkák' : ''}
  ${tempData.industryspecifics.construction.constructiontype.generalcontracting ? ', Generálkivitelezés' : ''}
  ${tempData.industryspecifics.construction.constructiontype.constructionprojects ? ', Építési projektek' : ''}
  ${
    tempData.industryspecifics.construction.constructiontype.other
      ? `, Egyéb: ${tempData.industryspecifics.construction.constructiontype.otherinput || 'N/A'}`
      : ''
  }
  </li>`
    : ''
}

${
  tempData.industry === 'IT' && tempData.industryspecifics?.IT?.ittype
    ? `<li>IT - Az informatikai munkák jellege: 
  ${tempData.industryspecifics.IT.ittype.wagereview ? 'Bérfejlesztés' : ''}
  ${tempData.industryspecifics.IT.ittype.projectwork ? ', Projektmunka' : ''}
  ${tempData.industryspecifics.IT.ittype.ownprodsoftdev ? ', Saját termék/szoftver fejlesztése' : ''}
  ${
    tempData.industryspecifics.IT.ittype.other
      ? `, Egyéb: ${tempData.industryspecifics.IT.ittype.otherinput || 'N/A'}`
      : ''
  }
  </li>`
    : ''
}

${
  tempData.industry === 'agriculture' && tempData.industryspecifics?.agriculture?.agriculturetype
    ? `<li>Mező- és erdőgazdálkodás, halászat - Munka jellege: 
  ${tempData.industryspecifics.agriculture.agriculturetype.livestockfarming ? 'Állattenyésztés' : ''}
  ${tempData.industryspecifics.agriculture.agriculturetype.cropproduction ? ', Növénytermesztés' : ''}
  ${
    tempData.industryspecifics.agriculture.agriculturetype.other
      ? `, Egyéb: ${tempData.industryspecifics.agriculture.agriculturetype.otherinput || 'N/A'}`
      : ''
  }
  </li>`
    : ''
}

${
  tempData.industry === 'art' && tempData.industryspecifics?.art?.arttype
    ? `<li>Művészet - A művészeti munka jellege: 
  ${tempData.industryspecifics.art.arttype.createartworks ? 'Műtárgyakat/művészeti alkotásokat hozok létre' : ''}
  ${tempData.industryspecifics.art.arttype.performingartist ? ', Előadóművész vagyok' : ''}
  ${tempData.industryspecifics.art.arttype.tradeartworks ? ', Műtárgyakkal/művészeti alkotásokkal kereskedek' : ''}
  ${
    tempData.industryspecifics.art.arttype.other
      ? `, Egyéb: ${tempData.industryspecifics.art.arttype.otherinput || 'N/A'}`
      : ''
  }
  </li>`
    : ''
}

${
  tempData.industry === 'beauty' && tempData.industryspecifics?.beauty?.beautyproductsales?.status
    ? `<li>Szépségipari termékek értékesítése: 
  ${tempData.industryspecifics.beauty.beautyproductsales.status === 'yes' ? 'Igen' : 'Nem'}
  </li>`
    : ''
}

${
  tempData.industry === 'travel' && tempData.industryspecifics?.travel?.travelpayment
    ? `<li>Szállás, vendéglátás - Fizetési mód: 
  ${tempData.industryspecifics.travel.travelpayment.transfer ? 'Átutalás' : ''}
  ${tempData.industryspecifics.travel.travelpayment.card ? ', Bankkártya' : ''}
  ${tempData.industryspecifics.travel.travelpayment.benefit ? ', Készpénz' : ''}
  </li>
  ${
    tempData.industryspecifics.travel?.matchinginvoicestravel?.matchingmethod
      ? `<li>Szállás, vendéglátás - A beérkező összegek és kiállított számlák összepárosítása: 
    ${
      tempData.industryspecifics.travel.matchinginvoicestravel.matchingmethod === 'true'
        ? 'Könyvelő segítségét kéri'
        : 'Ő végzi az összepárosítást'
    }
    </li>`
      : ''
  }
  ${
    tempData.industryspecifics?.travel?.deadlinecompliancetravel?.status
      ? `<li>Szállás/vendéglátás - Vállalja, hogy az összepárosításhoz a könyvelési anyagokat a könyvelő által kért határidőre, struktúrában és formátumban adja át: 
  ${tempData.industryspecifics.travel.deadlinecompliancetravel.status === 'true' ? 'Igen' : 'Nem'}</li>`
      : ''
  }`
    : ''
}

${
  tempData.industry === 'retail' && tempData.industryspecifics?.retail?.retailpayment
    ? `<li>Kiskereskedelem - Fizetési mód: 
  ${tempData.industryspecifics.retail.retailpayment.transfer ? 'Átutalás' : ''}
  ${tempData.industryspecifics.retail.retailpayment.card ? ', Bankkártya' : ''}
  </li>
  ${
    tempData.industryspecifics.retail?.matchinginvoicesretail?.matchingmethod
      ? `<li>Kiskereskedelem - A beérkező összegek és kiállított számlák összepárosítása: 
    ${
      tempData.industryspecifics.retail.matchinginvoicesretail.matchingmethod === 'true'
        ? 'Könyvelő segítségét kéri'
        : 'Ő végzi az összepárosítást'
    }
    </li>`
      : ''
  }
  ${
    tempData.industryspecifics?.retail?.deadlinecomplianceretail?.status
      ? `<li>Kiskereskedelem - Vállalja, hogy az összepárosításhoz a könyvelési anyagokat a könyvelő által kért határidőre, struktúrában és formátumban adja át: 
  ${tempData.industryspecifics.retail.deadlinecomplianceretail.status === 'true' ? 'Igen' : 'Nem'}</li>`
      : ''
  }`
    : ''
}

${
  tempData.industry === 'wholesale' && tempData.industryspecifics?.wholesale?.wholesalepayment
    ? `<li>Nagykereskedelem - Fizetési mód: 
  ${tempData.industryspecifics.wholesale.wholesalepayment.transfer ? 'Átutalás' : ''}
  ${tempData.industryspecifics.wholesale.wholesalepayment.card ? ', Bankkártya' : ''}
  </li>
  ${
    tempData.industryspecifics.wholesale?.matchinginvoiceswholesale?.matchingmethod
      ? `<li>Nagykereskedelem - A beérkező összegek és kiállított számlák összepárosítása: 
    ${
      tempData.industryspecifics.wholesale.matchinginvoiceswholesale.matchingmethod === 'true'
        ? 'Könyvelő segítségét kéri'
        : 'Ő végzi az összepárosítást'
    }
    </li>`
      : ''
  }
  ${
    tempData.industryspecifics?.wholesale?.deadlinecompliancewholesale?.status
      ? `<li>Nagykereskedelem - Vállalja, hogy az összepárosításhoz a könyvelési anyagokat a könyvelő által kért határidőre, struktúrában és formátumban adja át:
  ${tempData.industryspecifics.wholesale.deadlinecompliancewholesale.status === 'true' ? 'Igen' : 'Nem'}</li>`
      : ''
  }`
    : ''
}

${
  tempData.industry === 'finance' && tempData.industryspecifics?.finance?.complianceauditreview?.status
    ? `<li>Pénzügy, biztosítás - Van olyan MNB felülvizsgálat vagy bármilyen felügyeleti ellenőrzés, amiben részt kell vennie a könyvelőnek: 
  ${tempData.industryspecifics.finance.complianceauditreview.status === 'yes' ? 'Igen' : 'Nem'}
  </li>`
    : ''
}

${
  tempData.industry === 'medicine' && tempData.industryspecifics?.medicine?.medicinetype
    ? `<li>Egészségügyi tevékenység: 
  ${tempData.industryspecifics.medicine.medicinetype.doctor ? '<li>Orvos</li>' : ''}
  ${tempData.industryspecifics.medicine.medicinetype.pharmacy ? '<li>Gyógyszertár</li>' : ''}
  ${tempData.industryspecifics.medicine.medicinetype.nurse ? '<li>Ápoló</li>' : ''}
  ${tempData.industryspecifics.medicine.medicinetype.vet ? '<li>Állatorvos</li>' : ''}
  ${
    tempData.industryspecifics.medicine.medicinetype.other
      ? `<li>Egyéb: ${tempData.industryspecifics.medicine.medicinetype.otherinput || 'N/A'}</li>`
      : ''
  }
  </li>`
    : ''
}

${
  tempData.industry === 'courier' && tempData.industryspecifics?.courier?.matchinginvoicescourier?.matchingmethod
    ? `<li>Futár - A beérkező összegek és kiállított számlák összepárosítása: 
  ${
    tempData.industryspecifics.courier.matchinginvoicescourier.matchingmethod === 'true'
      ? 'Könyvelő segítségét kéri'
      : 'Ő végzi az összepárosítást'
  }
  </li>
  ${
    tempData.industryspecifics?.courier?.deadlinecompliancecourier?.status
      ? `<li>Futár - Vállalja, hogy az összepárosításhoz a könyvelési anyagokat a könyvelő által kért határidőre, struktúrában és formátumban adja át:
  ${tempData.industryspecifics.courier.deadlinecompliancecourier.status === 'true' ? 'Igen' : 'Nem'}</li>`
      : ''
  }`
    : ''
}
  
</ul>
          <p>Köszönöm, és további tartalmas napot kívánok!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - newcustomer':
      template = `<p>Kedves ...</p>
        <p>Szeretnék Neked új ügyfelet adni, mikor tudunk róla beszélni pár percben?</p>
        <p>Várom visszahívásodat, vagy mailedet, köszönöm előre is!</p>
        <p>Addig is tartalmas napot kívánok!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - verbalconfirmation':
      template = `<p>Kedves ...</p>
          <p>Legutóbbi beszélgetésünkkor jelezte, hogy érdekli Önt a szolgáltatásunk, szeretne regisztrálni, és szívesen
            csatlakozik hozzánk.
          </p>
          <p>Van bármilyen kérdése ennek kapcsán? Szívesen segítek!</p>
          <p>
            Amennyiben ebben a hónap regisztrál, és az éves Ügyfélgarancia csomagot választja a 13. hónap ajándék az Ön
            számára.
          </p>
          <p>Addig is további szép napot!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - isstillinterested':
      template = `<p>Kedves ...</p>
          <p>Várom szíves visszajelzését, ha úgy dönt, hogy minket választ, és bíz meg a könyvelő kereséssel.</p>
          <p>Ha már nem aktuális a keresés, kérem azt is jelezze, köszönöm!</p>
          <p>Üdvözlettel:</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - cafdoneisaccountatnneeded':
      template = `<p>Kedves ...</p>
          <p>
            ${data.providerName} jelezte, hogy megtörtént a tanácsadás, szükség lesz könyvelői szolgáltatásra is a jövőben?
          </p>
          <p>Várva szíves válaszát,</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - evformrequest':
      template = `<p>Kedves ...</p>
          <p>${data.providerName} jelezte, hogy beszéltek, és szeretné elindítani az EV-t</p>
          <p>Mikorra várhatjuk az adatbekérő visszaküldését?</p>
          <p>Várva szíves visszajelzését, köszönöm!</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - acknowledgement':
      template = `<p>Kedves ...</p>
          <p>Értem, köszönöm a visszajelzést!</p>
          <p>További tartalmas napot kívánok!</p>
        <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - accountingunpaid':
      template = `<p>Kedves ...</p>
          <p>Nyilvántartásunk szerint számláját a mai napig nem rendezte. [Számla száma] számú számlája késedelmes, a kintlevőség összege [Számla összege] Ft, a fizetési határidő napja [Számla lejárta] volt. </p>
          <p>Kérem, minél hamarabb rendezze a számlát, hogy meg tudják kezdeni a közös munkát könyvelőjével.  Kérem vegye figyelembe, hogy 15 napos késedelem esetén a könyvelési szolgáltatás felfüggesztésre kerülhet, míg 30 napos késedelem esetén jogunkban áll azonnali hatállyal felmondani.</p>
        <p>Várom szíves visszajelzését!</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - individiualoffer':
      template = `<p>Kedves ...</p>
          <p>Elkészült az egyedi ajánlat, webáruház könyvelésére az alábbi árajánlatot tudjuk Önnek ajánlani:</p>
          <ul><li> Havi könyvelési díj (nettó): </li></ul>
          <p>Várom visszajelzését az árajánlatunkra és hogy léphetünk-e tovább a folyamatban. </p>
        <p>További kellemes napot kívánok!</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - proposalaccepted':
      template = `<p>Kedves ...</p>
          <p>Jó hírünk van! Az ügyfél ${data.customerName} elfogadta az ajánlatot, kérlek keresd őt, várja a hívásodat. Ha nem érnéd el, kérjek írj neki emailt!</p>
          <p>Elérhetőségei: ${data.customerPhone}, ${data.customerEmail}</p>
        <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - contractinprogress':
      template = `<p>Kedves ...</p>
          <p>Jó hírünk van! Az ügyfél ${data.customerName} szerződése készül, jelentkezünk, amint indulhat a közös munka!</p>
        <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - bupakftinfo':
      template = `<p>Kedves ...</p>
       <p>
        Szeretnénk a figyelmébe ajánlani az alábbi lehetőséget Kft alapítása kapcsán: amennyiben a BUPA platformon keresztül 
        alapítja meg a Kft-jét (egyszemélyes Kft) és utána egy első évben ingyenes MBH META számlacsomagot nyit, úgy:
       </p>
       <ul>
        <li>örökké ingyenesen használhatja a BUPA digitális számlázót;</li>
        <li>egy évig ingyenesen vezetheti a számláit az MBH-nál (META számlacsomag választása esetén);</li>
        <li>a cégalapítás díjából 30.000 Ft jóváírásra kerül az újonnan megnyitott bankszámláján.</li>
        </ul>
       <p>Ha érdekli a lehetőség, kérem kattintson a linkre: 
        <a href='https://bupa.hu/bupa/kft-alapitas?utm_source=bookkeepie&utm_medium=referral&utm_campaign=company&utm_term=Kft_start'>BUPA Kft alapítás</a>
       </p>
       <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - bkregev':
      template = `<p>Kedves ...</p>
          <p>Köszönjük, hogy vállalkozása elindításához a Bookkeepie szolgáltatásait veszi igénybe!</p>
       <p>A folyamat következő lépése, hogy a lenti "Kezdjük el" linkre kattint, és az ezen keresztül megnyíló online kérdőíven megadja néhány személyes adatát, valamint csatolja az azonosításhoz szükséges személyes okmányok másolatát.</p>
       <a href='https://t40tz85n2uw.typeform.com/evkerdoiv'><p>Kezdjük el!</p></a>
       <p>Fontos! A vállalkozás indítási folyamat során kérjük, figyeljen oda az alábbiakra:</p>
       <ul><li>- Ügyeljen rá, hogy a csatolt személyes okmányok mindkét oldala olvashatóan kerüljön feltöltésre, illetve, hogy pontosan azokat az adatokat adja meg számunkra, amik az okmányokon szerepelnek.</li>
       <li>Bármikor félbehagyhatja és újrakezdheti az online kérdőív kitöltését, a megadott adatok nem fognak elveszni.</li></ul>
       <p>Ha fentiek közül, vagy a kérdőív kitöltése során bármi nem világos, jelezze ezt emailben vagy telefonon, keresni fogunk!</p>
       <p>Most pedig nincs más hátra, mint előre: gratulálunk a vállalkozás indításhoz!</p>
       <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - bkregekft':
      template = `<p>Kedves Cégalapító!</p>
          <p>Köszönjük, hogy cégalapításához a Bookkeepie szolgáltatásait veszi igénybe!</p>
          <p>A folyamat következő lépése, hogy a lenti "Kezdjük el" linkre kattint, és az ezen keresztül megnyíló online kérdőíven megadja néhány személyes adatát, valamint csatolja az azonosításhoz szükséges személyes okmányok másolatát.</p>
       <a href='https://t40tz85n2uw.typeform.com/ekftkerdoiv'><p>Kezdjük el!</p></a>
       <p>Fontos! A cégalapítási folyamat során kérjük figyeljen oda az alábbiakra:</p>
       <ul><li>Ügyeljen rá, hogy a csatolt személyes okmányok mindkét oldala olvashatóan kerüljön feltöltésre, illetve, hogy pontosan azokat az adatokat adja meg számunkra, amik az okmányokon szerepelnek.</li>
       <li>Bármikor félbehagyhatja és újrakezdheti az online cégalapítási kérdőív kitöltését, a megadott adatok nem fognak elveszni.</li>
       <li>Ellenőrizze, hogy a kiválasztott cégnév más vállalkozás által még nem használt; jó megoldás egy Google keresés, de még jobb az e-cégjegyzék használata, a Cégbíróság ugyanis ezt nézi.</li></ul>
       <p>Ha fentiek közül, vagy a kérdőív kitöltése során bármi nem világos, jelezze ezt emailben vagy telefonon, keresni fogom!</p>
       <p>Most pedig nincs más hátra, mint előre: Jó alapítást! </p>
       <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - bkregtkft':
      template = `<p>Kedves Cégalapító!</p>
          <p>Köszönjük, hogy cégalapításához a Bookkeepie szolgáltatásait veszi igénybe!</p>
          <p>A folyamat következő lépése, hogy a lenti "Kezdjük el" linkre kattint, és az ezen keresztül megnyíló online kérdőíven megadja néhány személyes adatát, valamint csatolja az azonosításhoz szükséges személyes okmányok másolatát.</p>
       <a href='https://t40tz85n2uw.typeform.com/kftkerdoiv'><p>Kezdjük el!</p></a>
       <p>Fontos! A cégalapítási folyamat során kérjük figyeljen oda az alábbiakra:</p>
       <ul><li>Ügyeljen rá, hogy a csatolt személyes okmányok mindkét oldala olvashatóan kerüljön feltöltésre, illetve, hogy pontosan azokat az adatokat adja meg számunkra, amik az okmányokon szerepelnek.</li>
       <li>Bármikor félbehagyhatja és újrakezdheti az online cégalapítási kérdőív kitöltését, a megadott adatok nem fognak elveszni.</li>
       <li>Ellenőrizze, hogy a kiválasztott cégnév más vállalkozás által még nem használt; jó megoldás egy Google keresés, de még jobb az e-cégjegyzék használata, a Cégbíróság ugyanis ezt nézi.</li></ul>
       <p>Ha fentiek közül, vagy a kérdőív kitöltése során bármi nem világos, jelezze ezt emailben vagy telefonon, keresni fogom!</p>
       <p>Most pedig nincs más hátra, mint előre: Jó alapítást! </p>
       <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - bkregebt':
      template = `<p>Kedves Cégalapító!</p>
          <p>Köszönjük, hogy cégalapításához a Bookkeepie szolgáltatásait veszi igénybe!</p>
          <p>A folyamat következő lépése, hogy a lenti "Kezdjük el" linkre kattint, és az ezen keresztül megnyíló online kérdőíven megadja néhány személyes adatát, valamint csatolja az azonosításhoz szükséges személyes okmányok másolatát.</p>
       <a href='https://t40tz85n2uw.typeform.com/btkerdoiv'><p>Kezdjük el!</p></a>
       <p>Fontos! A cégalapítási folyamat során kérjük figyeljen oda az alábbiakra:</p>
       <ul><li>Ügyeljen rá, hogy a csatolt személyes okmányok mindkét oldala olvashatóan kerüljön feltöltésre, illetve, hogy pontosan azokat az adatokat adja meg számunkra, amik az okmányokon szerepelnek.</li>
       <li>Bármikor félbehagyhatja és újrakezdheti az online cégalapítási kérdőív kitöltését, a megadott adatok nem fognak elveszni.</li>
       <li>Ellenőrizze, hogy a kiválasztott cégnév más vállalkozás által még nem használt; jó megoldás egy Google keresés, de még jobb az e-cégjegyzék használata, a Cégbíróság ugyanis ezt nézi.</li></ul>
       <p>Ha fentiek közül, vagy a kérdőív kitöltése során bármi nem világos, jelezze ezt emailben vagy telefonon, keresni fogom!</p>
       <p>Most pedig nincs más hátra, mint előre: Jó alapítást! </p>
       <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - regunpaid':
      template = `<p>Kedves ... </p>
          <p>[dátum] napon kiküldtük a vállalkozás indításról szóló számlát.</p>
       <p>Kérjük, rendezze a számlát, hogy tovább tudjunk lépni a folyamatban!</p>
       <p>Fontos! A cégalapítási folyamat során kérjük figyeljen oda az alábbiakra:</p>
       <ul><li>Ügyeljen rá, hogy a csatolt személyes okmányok mindkét oldala olvashatóan kerüljön feltöltésre, illetve, hogy pontosan azokat az adatokat adja meg számunkra, amik az okmányokon szerepelnek.</li>
       <li>Bármikor félbehagyhatja és újrakezdheti az online cégalapítási kérdőív kitöltését, a megadott adatok nem fognak elveszni.</li>
       <li>Ellenőrizze, hogy a kiválasztott cégnév más vállalkozás által még nem használt; jó megoldás egy Google keresés, de még jobb az e-cégjegyzék használata, a Cégbíróság ugyanis ezt nézi.</li></ul>
       <p>Ha fentiek közül, vagy a kérdőív kitöltése során bármi nem világos, jelezze ezt emailben vagy telefonon, keresni fogom!</p>
       <p>Most pedig nincs más hátra, mint előre: Jó alapítást! </p>
       <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - regnoreply':
      template = `<p>Kedves ... </p>
          <p>Örömmel fogadtuk vállalkozás indítási szándékát!</p>
       <p>Megkíséreltem elérni Önt telefonon az alábbi számról [Saját telefonszám] - sajnos sikertelenül.</p>
       <p>Kérem, amikor Önnek alkalmas legyen kedves napközben visszahívni, vagy e-mailben jelezni, hogy mikor kereshetem, egy 5-10 perces rövid egyeztetésre, hogy át tudjuk beszélni a folyamat következő lépéseit! </p>
       <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - regrequestDataBack':
      template = `<p>Kedves ... </p>
          <p>Az adatbekérő űrlapot nem töltötte még ki. Érdeklődnék, hogy aktuális még a vállalkozás indítás? Amennyiben esetleg már nem, kérem, azt is jelezze felénk.</p>
       <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - connectcusttosp':
      template = `<p>Kedves ... </p>
          <p>Egy új ügyfél kapcsán kereslek, aki egyéni vállalkozást szeretne indítani. Kérlek keresd őt, egyeztessetek időpontot az EV indításra: </p>
          <p>${data.customerName}, ${data.customerPhone}, ${data.customerEmail}</p>
          <p>Kérlek, ha már kétszer próbáltad hívni, és emailt is küldtél neki, de nem reagál, jelezd felénk, hogy keressük!</p>
       <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - yourprovidertostartev':
      template = `<p>Kedves ... </p>
          <p>Jó hírünk van, hamarosan elindulhat az egyéni vállalkozása! Könyvelő partnerünk keresni fogja időpont egyeztetéssel kapcsolatban:</p>
          <p>${data.providerName}, ${data.providerPhone}, ${data.providerEmail}</p>
          <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - aspendcontract':
      template = `<p>Kedves ... </p>
          <p>Könyvelőjével ${data.providerName} egyeztetve jelen levelünkkel megerősítjük, hogy a könyvelési szerződése <dátum> napján megszűnik, az utolsó könyvelt hónap: [év hónap]</p>
          <p>További sok sikert kívánunk és köszönjük, hogy a Bookkeepie szolgáltatásait vette igénybe.</p>
          <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - readytostartev':
      template = `<p>Kedves ... </p>
          <p>Kérlek egyeztessetek időpontot az ügyféllel, hogy mikor indítjátok az egyéni vállalkozását: </p>
          <p>Elérhetősége: ${data.customerName},telefon: ${data.customerPhone}, e-mail: ${data.customerEmail}</p>
          <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - vafdoneinfos':
      template = `<p>Kedves ${tempData.companyctcname} </p>
          <p>Könyvelője a konzultáció során elhangzottak alapján az alábbi vállalkozási, illetve adózási formát találta az Ön számára a legelőnyösebbnek:</p>
          <p>Ajánlatkérő neve: ${tempData.companyctcname}</p>
          <p>Ajánlatkérő e-mail: ${tempData.email}</p>
          <p>Ajánlatkérő telefon: ${tempData.companyPhone}</p>
          <p>Irányítószám: ${tempData.zip_code}</p>
          <p>Cégforma: ${companyText[tempData.companytype]}</p>
          <p>Adózási forma: ${tempData.taxform}</p>
          <p>Áfa alanyiság: ${companyText[tempData.vatFilter]}</p>
          <p>Áfa gyakoriság: ${companyText[tempData.vatPayment]}</p>
          <p>Alkalmazottak száma: ${tempData.noofemployees}</p>
          <p>Éves árbevétel: ${tempData.yearlyrevenue}</p>
          <p>Bejövő számlák: ${tempData.incominginvoices}</p>
          <p>Kimenő számlák: ${tempData.outgoinginvoices}</p>
          <p>Iparág: ${companyText[tempData.industry]}</p>
          <p>Külkereskedelem: ${companyText[tempData.foreign_trade]}</p>
          <p>Külkereskedelem irány: ${companyText[tempData.foreigntradeEU]}</p>
          <br />
          <p>Ha segíthetünk a vállalkozás indításában is, annak díja ${foundingpriceslist[tempData.companytype]} Ft.</p>
          <p>Könyvelési díjat a fentiek alapján már tudunk kalkulálni, az ajánlattal kollégánk hamarosan keresi telefonon.</p>
          <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - aftervafreq':
      template = `<p>Kedves ${tempData.companyctcname} </p>
          <p>Köszönjük!</p>
          <p>A szolgáltatási díjat sikeresen befizetted!</p>
          <p>A könyvelő, aki a Vállalkozási és Adózási Forma tanácsadást nyújtja: ${data.providerName}</p>
          <p>Emailben vagy telefonon tudsz vele időpontot egyeztetni a konzultációra </p>
          <p>${data.providerPhone}, ${data.providerEmail} </p>
          <p>Bármi kérdés esetén keress minket bizalommal!</p>
          <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - matchmaking keresőnek':
      template = `<p>Kedves ${tempData.companyctcname}! </p>
          <p>Örömmel értesítünk, hogy ${providerData.provider_info.companyname} fog segíteni megalapítani az egyéni vállalkozásod. </p>
          <p>a) Ezen a linken tudsz időpontot foglalni a konzultációra: <a href="${providerData.provider_info.bookinglink}">időpontfoglalás</a></p>
          <p>b) Ezen a telefonszámon tudsz időpontot egyeztetni a könyvelővel: ${providerData.provider_info.phone}</p>
          <p>A konzultáció során figyelj a következőkre: </p>
          <ul>
            <li>légy számítógép előtt, jó internetkapcsolattal</li>
            <li>készítsd elő az ügyfélkapu bejelentkezési adatokat (felhasználónév, jelszó)</li>
          </ul>
          <p>Bármi kérdés esetén keress minket bizalommal!</p>
          <p>Üdvözlettel:</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - matchmaking könyvelőnek':
      template = `<p>Kedves ...! </p>
          <p>Egyéni vállalkozás létrehozására kér konzultációs szolgáltatást: ${data.customerName} </p>
          <p>Az ügyfél üzenete: ${tempData.reqMessage}</p>
          <p>Ajánlatkérő telefonszáma: ${data.customerPhone} </p>
          <p>Ajánlatkérő email címe: ${data.customerEmail} </p>
          <p>Iparág: ${companyText[tempData.industry]} </p>
          <p>Vállalkozás formája: ${companyText[tempData.companytype]} </p>
          <p>Szolgáltatás: ${data.services.join(', ')} </p>
          <p>Konzultáció típusa: ${companyText[tempData.taxconform]} </p>
          <p>Szolgáltatás ára: ${tempData.originalSinglePrice} Ft </p>
          <p>Ezen a felületen tudod végrehajtani a konzultációt: <a href="https://dashboard-hu.bookkeepie.com/provider/regform/${
            tempData._id
          }">Tovább>></a></p>
          <p>Köszönjük!</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - servicesuspension':
      template = `
          <p>Tisztelt Ügyfelünk,</p>
          <p>Tájékoztatjuk, hogy lejárt számlatartozás miatt <strong>könyvelési szolgáltatása a mai nappal felfüggesztésre került</strong>.<br>Az érintett, késedelemes számla sorszáma: <span style="color:blue;">[számla sorszáma]</span></p>
          <p>A felfüggesztés alatti, nem beadott bevallás vagy egyéb el nem végzett könyvelői feladat miatt kapott bírságért felelősséget nem vállalunk. A szolgáltatás azonnal újraindul, amint a kintlevőség rendezésre került.</p>
          <p>Amennyiben a késdelem eléri a 30 napot, úgy jogunkban áll azonnali hatállyal szerződést bontani (ez nem jelenti a fennálló követelésekről való lemondást).</p>
          <p>Amennyiben a számlával kapcsolatban kérdése, vagy kérése lenne, kérem jelezze.</p>
          <p>Üdvözlettel,</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - servicesuspension':
      template = `
          <p>Kedves Partnerünk,</p>
          <p>Kérjük, hogy ${data.customerName} kapcsán függessze fel a könyvelési szogláltatást a mai nappal, mivel lejárt számlatartozása van.<br>Amint az ügyfél fizette a számláját, jelezni fogjuk, és a szolgáltatás folytatódhat.</p>
          <p>Amennyiben nem rendezi a tartozást, és a késedelem eléri a 30 napot, jogunkban áll az ügyféllel azonnali hatállyal szerződést bontani. Ha erre kerülne a sor, azonnal jelezni fogjuk, illetve kérjük, hogy a könyvelési anyagok átadása kapcsán egyeztessen az ügyféllel.</p>
          <p>Üdvözlettel,</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - servicerestart':
      template = `
          <p>Tisztelt Ügyfelünk,</p>
          <p>Mivel tartozása rendezésre került (ezúton is köszönjük a befizetést), a könyvelési szolgáltatás folytatódhat. Erről tájékoztattuk a könyvelőjét is.</p>
          <p>Üdvözlettel,</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - servicerestart':
      template = `
          <p>Kedves Partnerünk,</p>
          <p>Az alábbi ügyfele rendezte a késedelemes tartozását, a könyvelési szolgáltatás folytatódhat:<br>${data.customerName}</p>
          <p>Kérjük, vegye fel vele a kapcsolatot.</p>
          <p>Köszönjük,</p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Customer - servicetermination':
      template = `
          <p>Tisztelt Ügyfelünk,</p>
          <p>Nyilvántartásunk szerint a tartozásait korábbi levelünk óta sem rendezte, a késedelem mértéke pedig elérte a 30 napot.<br>Írásban a tartozással kapcsolatos kérdés, kérés nem érkezett felénk, így sajnos <strong>kénytelenek vagyunk a könyvelési szerződést a mai nappal felmondani</strong>.</p>
          <p>A könyvelési anyagok átadása kapcsán kértük a könyvelőjét, hogy vegye fel Önnel a kapcsolatot. </p>
          <p>Üdvözlettel, </p>
          <p id="signature"></p>
          `;
      break;
    case 'Admin - Provider - servicetermination':
      template = `
          <p>Kedves Partnerünk,</p>
          <p>30 napon túli késedelmes számlatartozás miatt sajnos kénytelenek voltunk az alábbi ügyféllel felmondani a szerződést:<br>${data.customerName}</p>
          <p>Kérjük, hogy a könyvelési anyagok átadása kapcsán egyeztessenek - és ha kérhetjük, ennek kapcsán jelezzék felénk is, hogy miben maradtak.</p>
          <p>Köszönjük szépen,<br>Üdvözlettel,</p>
          <p id="signature"></p>
          `;
      break;
    default:
      return `<p id="signature"></p>`;
  }
  // console.log('TEMPDATA', tempData);
  // console.log('PROVIDERDATA', providerData);
  return template;
};

import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Dialog from '@mui/material/Dialog';
import { columns } from './referralsTableConfig';
import ReferralDisplay from './referralDisplay/ReferralDisplay';

const ReferralsTable = ({ referrals }) => {
  const [tableData, setTableData] = useState([]);
  const [selectedRefID, setSelectedRefID] = useState('');
  const [dialogState, setDialogState] = React.useState(false);

  function formatDate(date = new Date()) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  useEffect(() => {
    let temp = [];
    if (referrals) {
      referrals.forEach((referral) => {
        temp.push({
          refID: referral._id,
          refererEmail: referral.refererEmail,
          refCode: referral.refCode,
          reqID: referral.reqID,
          dateCreated: formatDate(referral.dateCreated),
        });
      });
    }
    setTableData(temp);
  }, [referrals]);

  const handleClose = () => {
    setDialogState(false);
  };

  const options = {
    filterType: 'checkbox',
    rowHover: true,
    selectableRows: false,
    onRowClick: (rowData, rowMeta) => {
      //if columns get rearranged change the index here -- unfortunately rowData is flattened
      setSelectedRefID(rowData[0]);
      setDialogState(true);
    },
  };
  return (
    <div>
      <MUIDataTable data={tableData} columns={columns} options={options} />
      <Dialog fullWidth={true} maxWidth={'xl'} open={dialogState} onClose={handleClose}>
        <ReferralDisplay referralID={selectedRefID} />
      </Dialog>
    </div>
  );
};

export default ReferralsTable;

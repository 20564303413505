import React, { useEffect, useState, useContext, useCallback } from 'react';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import { EnvContext } from 'src/context/EnvContext';
import { GetProvidersContext } from 'src/pages/Providers';
import { fetchData } from 'src/fetch';
import { profileTabs } from './profileHelper';
import Account from './tabs/Account';
import Package from './tabs/Package';
import Profile from './tabs/Profile';
import Services from './tabs/Services';
import Restrictions from './tabs/Restrictions';
import SpecRestrictions from './tabs/SpecRestrictions';
import Pricing from './tabs/Pricing';
import Experience from './tabs/Experience';
import Qualifications from './tabs/Qualifications';
import Reviews from './tabs/Reviews';
import Subscription from './tabs/Subscription';
import BankInvoSoft from './tabs/BankInvoSoft';

export const ProviderContext = React.createContext();

const ProviderProfile = ({ providerID }) => {
  const [accountData, setAccountData] = useState(null);
  const [providerData, setProviderData] = useState(null);
  const [selectedTab, setSelectedTab] = useState('account');
  const { getProviders } = useContext(GetProvidersContext);

  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;

  const getAccountData = useCallback(async () => {
    await fetchData(`${baseUrl}/api/admin/get-user/${providerID}`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setAccountData(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl, providerID]);

  const getProviderData = useCallback(async () => {
    await fetchData(`${baseUrl}/api/admin/get-provider/${providerID}`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setProviderData(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl, providerID]);

  useEffect(() => {
    getAccountData();
    getProviderData();
  }, [getAccountData, getProviderData]);

  const updateData = async (path, updateParam) => {
    console.log(updateParam, 'updateParam');
    await fetchData(`${baseUrl}/api/admin/update-${path}`, 'PATCH', localStorage.getItem('JWT'), {
      providerID: providerID,
      updateParam: updateParam,
    })
      .then(async (response) => {
        let resp = await response.json();
        if (resp.message === 'Updated') {
          let objKey = Object.keys(updateParam)[0];
          updateContext(path, updateParam);
          if (objKey === 'provider_subscription.activeSubscription' || path === 'user') {
            await getProviders();
          }
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const reloadData = async () => {
    await getProviders();
    await getProviderData();
    await getAccountData();
  };

  const updateContext = async (path, updateParam) => {
    let objKey = Object.keys(updateParam)[0];
    let keys = objKey.split('.');

    if (path === 'provider') {
      if (keys[1]) {
        setProviderData({
          ...providerData,
          [keys[0]]: { ...providerData[keys[0]], [keys[1]]: updateParam[objKey] },
        });
      } else if (!keys[1] && keys[0]) {
        setProviderData({
          ...providerData,
          [keys[0]]: updateParam[objKey],
        });
      }
    } else if (path === 'user') {
      setAccountData({
        ...accountData,
        [keys[0]]: updateParam[objKey],
      });
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabContent = {
    account: <Account />,
    package: <Package />,
    profile: <Profile />,
    services: <Services />,
    restrictions: <Restrictions />,
    specrestrictions: <SpecRestrictions />,
    bankinvosoft: <BankInvoSoft />,
    pricing: <Pricing />,
    experience: <Experience />,
    reviews: <Reviews />,
    qualifications: <Qualifications />,
    subscription: <Subscription />,
  };

  return (
    <ProviderContext.Provider
      value={{ accountData, setAccountData, providerData, setProviderData, updateData, reloadData }}
    >
      <div className="ProviderProfileContainer">
        {providerData ? (
          <div>
            <div>
              <TabContext value={selectedTab}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  {profileTabs.map((item) => {
                    return <Tab key={item.id} label={item.label} value={item.id} />;
                  })}
                </TabList>
              </TabContext>
            </div>
            <div>{tabContent[selectedTab]}</div>
          </div>
        ) : (
          <>Loading</>
        )}
      </div>
    </ProviderContext.Provider>
  );
};

export default ProviderProfile;

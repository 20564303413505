import React, { useEffect, useCallback } from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { EnvContext } from './context/EnvContext';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './globalStyle.scss';
// ----------------------------------------------------------------------

import Amplify from 'aws-amplify';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

function App() {
  const [baseUrl, setBaseUrl] = React.useState(process.env.REACT_APP_BACKEND_API || 'https://api.konyvelo-net.hu');

  const setCognitoIdToken = useCallback(() => {
    for (let key in localStorage) {
      if (key.includes('idToken')) {
        localStorage.setItem('cognitoID', localStorage[key]);
      }
    }
  }, []);

  useEffect(() => {
    setCognitoIdToken();
  }, [setCognitoIdToken]);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <EnvContext.Provider value={{ baseUrl, setBaseUrl }}>
        <Router />
      </EnvContext.Provider>
    </ThemeProvider>
  );
}

export default withAuthenticator(App);

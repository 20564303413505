import React, { useEffect, useState, useContext } from 'react';
import MuiSwitch from 'src/components/Switch';
import { bankingInput, invoicingInput, softwareInput } from '../profileHelper';
import { ProviderContext } from '../ProviderProfile';

const BankInvoSoft = () => {
  const [data, setData] = useState(null);
  const { providerData, updateData } = useContext(ProviderContext);

  useEffect(() => {
    if (providerData) {
      setData({
        provider_banking_filter: providerData.provider_banking_filter,
        provider_invoicing_filter: providerData.provider_invoicing_filter,
        provider_software_filter: providerData.provider_software_filter,
      });
    }
  }, [providerData]);

  const bankHandleCheck = async (id, value) => {
    let modelKey = 'provider_banking_filter';
    let tempObj = data[modelKey];
    let newObj = tempObj.filter((i) => i.id === id)[0];
    newObj.on = value;
    setData({ ...data, [modelKey]: tempObj });
    await updateData('provider', { [modelKey]: tempObj });
  };
  const invoHandleCheck = async (id, value) => {
    let modelKey = 'provider_invoicing_filter';
    let tempObj = data[modelKey];
    let newObj = tempObj.filter((i) => i.id === id)[0];
    newObj.on = value;
    setData({ ...data, [modelKey]: tempObj });
    await updateData('provider', { [modelKey]: tempObj });
  };

  const softwareHandleCheck = async (id, value) => {
    let modelKey = 'provider_software_filter';
    let tempObj = data[modelKey];
    let newObj = tempObj.filter((i) => i.id === id)[0];
    newObj.on = value;
    setData({ ...data, [modelKey]: tempObj });
    await updateData('provider', { [modelKey]: tempObj });
  };

  const numberHandle = async (id, value, modelKey) => {
    let tempObj = data[modelKey];
    let newObj = tempObj.filter((i) => i.id === id)[0];
    newObj.value = value;
    setData({ ...data, [modelKey]: tempObj });
    await updateData('provider', { [modelKey]: tempObj });
  };

  const getObject = (id, array) => {
    return array.filter((e) => e.id === id)[0];
  };

  return (
    <div className="TabContent">
      {data ? (
        <div className="TabContentFlexRow">
          <div className="Padding12">
            <b>Korlátozás bankok szerint</b>
            {bankingInput.map((item, i) => {
              //console.log(item, 'ITEM IN MAP');
              let fetchedData = getObject(item.id, data.provider_banking_filter);
              //console.log(fetchedData, 'FECCSED DATA');
              return (
                <div className="Width350" style={{ display: 'flex', justifyContent: 'space-between' }} key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.id}`}
                    value={fetchedData.on}
                    //value={data[item.modelKey][item.on]}
                    onChange={bankHandleCheck}
                    style={{ width: '100%', justifyContent: 'space-between' }}
                  />
                  <input
                    type="number"
                    id={`${item.id}`}
                    value={fetchedData.value}
                    onChange={(e) => numberHandle(e.target.id, e.target.value, 'provider_banking_filter')}
                    style={{ width: '30px', marginLeft: '6px' }}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <b>Korlátozás számlázó szerint</b>
            {invoicingInput.map((item, i) => {
              let fetchedData = getObject(item.id, data.provider_invoicing_filter);
              //console.log(fetchedData);
              return (
                <div className="Width350" style={{ display: 'flex', justifyContent: 'space-between' }} key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.id}`}
                    value={fetchedData.on}
                    //value={data[item.modelKey][item.on]}
                    onChange={invoHandleCheck}
                    style={{ width: '100%', justifyContent: 'space-between' }}
                  />
                  <input
                    type="number"
                    id={`${item.id}`}
                    value={parseInt(fetchedData.value)}
                    onChange={(e) => numberHandle(e.target.id, e.target.value, 'provider_invoicing_filter')}
                    style={{ width: '30px', marginLeft: '6px' }}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <b>Korlátozás könyvelő szoftver szerint</b>
            {softwareInput.map((item, i) => {
              let fetchedData = getObject(item.id, data.provider_software_filter);
              //console.log(fetchedData);
              return (
                <div className="Width350" style={{ display: 'flex', justifyContent: 'space-between' }} key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.id}`}
                    value={fetchedData.on}
                    //value={data[item.modelKey][item.on]}
                    onChange={softwareHandleCheck}
                    style={{ width: '100%', justifyContent: 'space-between' }}
                  />
                  <input
                    type="number"
                    id={`${item.id}`}
                    value={fetchedData.value}
                    onChange={(e) => numberHandle(e.target.id, e.target.value, 'provider_software_filter')}
                    style={{ width: '30px', marginLeft: '6px' }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default BankInvoSoft;

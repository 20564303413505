import React, { useEffect, useContext, useCallback, useState } from 'react';
// material
import { Stack, Container, Typography, Button } from '@mui/material';
import Iconify from '../components/Iconify';
import Dialog from '@mui/material/Dialog';
// components
import Page from '../components/Page';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData } from 'src/fetch';
import CouponsTable from 'src/sections/@dashboard/coupons/CouponsTable';
import '../sections/@dashboard/coupons/coupons.scss';
import AddCoupon from 'src/sections/@dashboard/coupons/addCoupon/AddCoupon';

export default function Coupons() {
  const [coupons, setCoupons] = useState([]);
  const [dialogState, setDialogState] = React.useState(false);
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;

  const getCoupons = useCallback(async () => {
    await fetchData(`${baseUrl}/api/coupons/all`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setCoupons(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl]);

  useEffect(() => {
    getCoupons();
  }, [getCoupons]);

  const handleClose = () => {
    setDialogState(false);
  };

  return (
    <Page title="Users">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Coupons
          </Typography>
          <Button variant="contained" onClick={() => setDialogState(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Coupon
          </Button>
        </Stack>
        <CouponsTable coupons={coupons} />
        <Dialog fullWidth={true} maxWidth={'md'} open={dialogState} onClose={handleClose}>
          <AddCoupon handleClose={handleClose} getCoupons={getCoupons} />
        </Dialog>
      </Container>
    </Page>
  );
}

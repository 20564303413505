import React, { useEffect, useState, useContext } from 'react';
import MuiSwitch from 'src/components/Switch';
import { ProviderContext } from '../ProviderProfile';
import RadioInputMultiChoice from 'src/components/RadioInputMultiChoice';

const qualificationsInput = [
  { label: 'MKOE', id: 'mkoe', modelKey: 'provider_qualifications' },
  { label: 'Mérlegképes könyvelő', id: 'cpa', modelKey: 'provider_qualifications' },
];

const Qualifications = () => {
  const [data, setData] = useState(null);
  const { providerData, updateData } = useContext(ProviderContext);

  useEffect(() => {
    let temp = { provider_qualifications: { mkoe: false, cpa: false }, digital_badge: 'tbd' };
    if (providerData) {
      if (providerData.provider_qualifications) {
        temp.provider_qualifications = providerData.provider_qualifications;
      }
      if (providerData.digital_badge) {
        temp.digital_badge = providerData.digital_badge;
      }
    }
    setData(temp);
  }, [providerData]);

  const handleCheck = async (id, value) => {
    setData({ ...data, [id]: value });
    await updateData('provider', { [id]: value });
  };

  const handleChange = async (id, value) => {
    await updateData('provider', { [id]: value });
  };

  return (
    <div className="TabContent">
      {data ? (
        <div className="TabContentFlexRow">
          <div className="Padding12">
            <b>Minősítések</b>
            {qualificationsInput.map((item, i) => {
              return (
                <div className="Width350" key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.modelKey}.${item.id}`}
                    value={data[item.modelKey][item.id]}
                    onChange={handleCheck}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <div>
              <RadioInputMultiChoice
                onChange={handleChange}
                id={'digital_badge'}
                valueProp={data.digital_badge}
                label={'Digitális minősítés'}
                options={[
                  { label: 'Arany', value: '2' },
                  { label: 'Ezüst', value: '1' },
                  { label: 'Nem ért el fokozatot', value: '0' },
                  { label: 'Nem minősített', value: 'tbd' },
                ]}
              />
            </div>
          </div>
        </div>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default Qualifications;

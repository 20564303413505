import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { Button } from '@mui/material';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData, fetchData2 } from 'src/fetch';
import {
  TextField,
  MenuItem,
  ListItemText,
  Select,
  Checkbox,
  TextareaAutosize,
  Switch,
  Slider,
  Box,
} from '@mui/material';
import { cafStatusList, aspStatusList, herStatusList } from '../statusHelper';
import Dropdown from '../../../../../components/Dropdown';

export const services = [
  { value: 'legalentityfounding', label: 'Cégalapítás' },
  { value: 'taxconsultingsimple', label: 'CAF' },
  { value: 'taxconsultingcomplex', label: 'Komplex adótanácsadás' },
  { value: 'accounting', label: 'Könyvelés' },
  { value: 'payroll', label: 'Bérszámfejtés' },
  { value: 'taxconsulting', label: 'Folyamatos adótanácsadás' },
];

const StatusValidation = ({ statusID }) => {
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;
  console.log(baseUrl, 'baseUrlbaseUrlbaseUrlbaseUrlbaseUrlbaseUrl');
  const [message, setMessage] = useState('');
  const [callNote, setCallNote] = useState('');
  const [requestData, setRequestData] = useState();
  const [tempData, setTempData] = useState();
  const [tempStatus, setTempStatus] = useState();
  const [matchScore, setMatchScore] = useState();
  const [updatedProvides, setUpdatedProviders] = useState();
  const [discountObj, setDiscountObj] = useState();
  const [tempObj, setTempObj] = useState({
    isUnderCollection: false,
    chosendate: '',
    startingdate: 0,
    accounting: false,
    taxconsulting: false,
    taxconsultingsimple: false,
    taxconsultingcomplex: false,
    legalentityfounding: false,
    payroll: false,
    audit: false,
    liquidation: false,
    languagefilter: false,
    languages: {
      english: false,
      german: false,
      spanish: false,
      french: false,
      romanian: false,
      chinese: false,
      russian: false,
    },
    noofemployees: 0,
    yearlyrevenue: 0,
    incominginvoices: 0,
    outgoinginvoices: 0,
    industry: '',
    companytype: '',
    companyctcname: '',
    zip_code: '',
    foreign_trade: false,
    foreigntradeEU: '',
    newlyfounded: false,
    vatFilter: '',
    vatPayment: '',
    taxform: 'idk',
    email: '',
    gdpr: false,
    marketing: false,
    reqMessage: '',
    companyPhone: '',
    onlyBrowsing: false,
    isDiscounted: false,
    discountCode: '',
    bankingpref: {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      opt8: false,
      other: false,
      bankidk: false,
    },
    invoicingpref: {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      other: false,
      invoicingidk: false,
    },
    softwarepref: {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      opt8: false,
      opt9: false,
      opt10: false,
      opt11: false,
      opt12: false,
      opt13: false,
      opt14: false,
      opt15: false,
      opt16: false,
      opt17: false,
      opt18: false,
      opt19: false,
      opt20: false,
      other: false,
    },
    multiplecompany: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    utm_term: '',
    gclid: '',
    dclid: '',
    industryinput: '',
    proposedSinglePrice: '',
    proposedContPrice: '',
    originalSinglePrice: '',
    originalContPrice: '',
    statuslog: [],
    responsibleagent: '',
    chosenprovider: '',
    chosenlawyer: '',
    howdidyoufindus: '',
    howdidyoufindusinput: '',
    whychangingaccountant: '',
    messagelog: [],
    isfakecherry: false,
    interestedInBupa: false,
    whereElseDidSearch: [],
    whatCanWeImprove: '',
    howHardWasIt: '',
    howHardNr: 0,
    whyNotUs: '',
    whyNotUsText: '',
    companylegalname: '',
    selectedProvGrp: '',
  });
  const [servicesPicked, setServicesPicked] = useState([]);
  const [placesSearched, setPlacesSearched] = useState([]);
  const [languagesPicked, setLanguagesPicked] = useState([]);
  const [chosenBank, setChosenBank] = useState([]);
  const [chosenInvo, setChosenInvo] = useState([]);
  const [chosenSoft, setChosenSoft] = useState([]);
  const [usedDiscount, setUsedDiscount] = useState();
  const [isChanged, setIsChanged] = useState(false);
  const [selectedGrp, setSelectedGrp] = useState(null);
  const [selectedEtradeSells, setSelectedEtradeSells] = useState([]);
  const [selectedEtradeCourier, setSelectedEtradeCourier] = useState([]);
  const [selectedEtradeDropoff, setSelectedEtradeDropoff] = useState([]);
  const [selectedEtradePayment, setSelectedEtradePayment] = useState([]);
  const [selectedRetailPayment, setSelectedRetailPayment] = useState([]);
  const [selectedWholesalePayment, setSelectedWholesalePayment] = useState([]);
  const [selectedVehicleOptions, setSelectedVehicleOptions] = useState([]);
  const [selectedConstructionOptions, setSelectedConstructionOptions] = useState([]);
  const [selectedAgricultureOptions, setSelectedAgricultureOptions] = useState([]);
  const [selectedITOptions, setSelectedITOptions] = useState([]);
  const [selectedArtOptions, setSelectedArtOptions] = useState([]);
  const [selectedTravelPayment, setSelectedTravelPayment] = useState([]);
  const [selectedMedicineOptions, setSelectedMedicineOptions] = useState([]);
  const [selectedMedicinePayment, setSelectedMedicinePayment] = useState([]);

  const bankPref = [
    { value: 'opt1', label: 'OTP' },
    { value: 'opt2', label: 'Erste' },
    { value: 'opt3', label: 'MBH (BB, MKB, Takarék)' },
    { value: 'opt4', label: 'Raiffeisen' },
    { value: 'opt5', label: 'UniCredit' },
    { value: 'opt6', label: 'K&H' },
    { value: 'opt7', label: 'Magnet' },
    { value: 'opt8', label: 'CIB' },
    { value: 'other', label: 'Egyéb' },
    { value: 'bankidk', label: 'Még nem tudom' },
  ];
  const invoPref = [
    { value: 'opt1', label: 'Számlázz.hu' },
    { value: 'opt2', label: 'Billingo' },
    { value: 'opt3', label: 'Billcity' },
    { value: 'opt4', label: 'Billsoft' },
    { value: 'opt5', label: 'SAP' },
    { value: 'opt6', label: 'NAV Online Számla' },
    { value: 'opt7', label: 'BUPA' },
    { value: 'other', label: 'Egyéb' },
    { value: 'invoicingidk', label: 'Még nem tudom' },
  ];
  const softPref = [
    { value: 'opt1', label: 'Apollo' },
    { value: 'opt2', label: 'Cobra' },
    { value: 'opt3', label: 'ComboSoft' },
    { value: 'opt4', label: 'Forint-Soft' },
    { value: 'opt5', label: 'Hessyn' },
    { value: 'opt6', label: 'IMA' },
    { value: 'opt7', label: 'Infotéka' },
    { value: 'opt8', label: 'Kulcs-Szoft' },
    { value: 'opt9', label: 'Libra' },
    { value: 'opt10', label: 'Makrodigit' },
    { value: 'opt11', label: 'Maxi-Tax' },
    { value: 'opt12', label: 'Nagy Machinátor' },
    { value: 'opt13', label: 'Novitax' },
    { value: 'opt14', label: 'QualitySoft' },
    { value: 'opt15', label: 'Relax' },
    { value: 'opt16', label: 'RLB' },
    { value: 'opt17', label: 'SAP' },
    { value: 'opt18', label: 'Smartbooks' },
    { value: 'opt19', label: 'TenSoft' },
    { value: 'opt20', label: 'Webkönyvelő' },
    { value: 'other', label: 'Egyéb' },
  ];

  const whereDidSearch = [
    { value: 'google', label: 'Google' },
    { value: 'facebook', label: 'Facebook' },
    { value: 'tiktok', label: 'Tik-tok' },
    { value: 'friend', label: 'Ismerős' },
    { value: 'bupa', label: 'BUPA' },
    { value: 'szamlazzhu', label: 'Számlázz.hu' },
    { value: 'freelancerblog', label: 'Freelancerblog' },
    { value: 'glamour', label: 'Glamour' },
    { value: 'otherweb', label: 'Más weboldal' },
    { value: 'bookkeepieblog', label: 'Bookkeepie Blog' },
    { value: 'other', label: 'Egyéb' },
    { value: 'idk', label: 'Nem emlékszik' },
  ];

  const languages = ['english', 'german', 'french', 'spanish', 'romanian', 'chinese', 'russian'];

  const sliderMarks = [
    { value: 1, label: 'könnyű' },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5, label: 'nehéz' },
  ];

  const etradeSellsOptions = [
    { value: 'physicalproducts', label: 'Fizikai termékek' },
    { value: 'services', label: 'Szolgáltatások (konzultáció, tanácsadás, képzés)' },
    { value: 'intellectualproperty', label: 'Szellemi tulajdon (ebook, elearning)' },
    { value: 'other', label: 'Egyéb' },
  ];

  const etradeCourierOptions = useMemo(
    () => [
      { value: 'mpl', label: 'Magyar Posta (MPL)' },
      { value: 'gls', label: 'GLS' },
      { value: 'dpd', label: 'DPD' },
      { value: 'sprinter', label: 'Sprinter' },
      { value: 'expressone', label: 'Express One' },
      { value: 'tntexpress', label: 'TNT Express' },
      { value: 'fedex', label: 'FedEx' },
      { value: 'ups', label: 'UPS' },
      { value: 'dhlexpress', label: 'DHL Express' },
      { value: 'other', label: 'Egyéb' },
    ],
    []
  );

  const etradeDropoffOptions = useMemo(
    () => [
      { value: 'foxpost', label: 'FOXPOST' },
      { value: 'alzabox', label: 'AlzaBox' },
      { value: 'packeta', label: 'Packeta' },
      { value: 'glscsomagpont', label: 'GLS CsomagPont' },
      { value: 'dpdpickupcsomagpont', label: 'DPD Pickup CsomagPont' },
      { value: 'mplpostapont', label: 'MPL PostaPont' },
      { value: 'other', label: 'Egyéb' },
    ],
    []
  );

  const etradePaymentOptions = useMemo(
    () => [
      { value: 'transfer', label: 'Banki átutalás' },
      { value: 'card', label: 'Bankkártyás fizetés' },
    ],
    []
  );

  const retailPaymentOptions = useMemo(
    () => [
      { value: 'transfer', label: 'Banki átutalás' },
      { value: 'card', label: 'Bankkártyás fizetés' },
    ],
    []
  );

  const wholesalePaymentOptions = useMemo(
    () => [
      { value: 'transfer', label: 'Banki átutalás' },
      { value: 'card', label: 'Bankkártyás fizetés' },
    ],
    []
  );

  const vehicleOptions = useMemo(
    () => [
      { value: 'automotivepartstrading', label: 'Gépjármű alkatrész kereskedelem' },
      { value: 'trading', label: 'Gépjármű kereskedelem' },
      { value: 'service', label: 'Gépjármű szerviz' },
      { value: 'other', label: 'Egyéb' },
    ],
    []
  );

  const constructionOptions = useMemo(
    () => [
      { value: 'specializedworks', label: 'Szakipari munkák' },
      { value: 'generalcontracting', label: 'Generál kivitelezés' },
      { value: 'constructionprojects', label: 'Építőipari projektek' },
      { value: 'other', label: 'Egyéb' },
    ],
    []
  );

  const agricultureOptions = useMemo(
    () => [
      { value: 'livestockfarming', label: 'Állattartás' },
      { value: 'cropproduction', label: 'Növénytermesztés' },
      { value: 'other', label: 'Egyéb' },
    ],
    []
  );

  const ITOptions = useMemo(
    () => [
      { value: 'wagereview', label: 'Bérfejlesztés' },
      { value: 'projectwork', label: 'Projektmunka' },
      { value: 'ownprodsoftdev', label: 'Saját termék/szoftver fejlesztése' },
      { value: 'other', label: 'Egyéb' },
    ],
    []
  );

  const artOptions = useMemo(
    () => [
      { value: 'createartworks', label: 'Műtárgyakat/művészeti alkotásokat hozok létre' },
      { value: 'performingartist', label: 'Előadóművész vagyok' },
      { value: 'tradeartworks', label: 'Műtárgyakkal/művészeti alkotásokkal kereskedek' },
      { value: 'other', label: 'Egyéb' },
    ],
    []
  );

  const travelPaymentOptions = useMemo(
    () => [
      { value: 'transfer', label: 'Banki átutalás' },
      { value: 'card', label: 'Bankkártyás fizetés' },
      { value: 'benefit', label: 'Szép kártyás fizetés' },
    ],
    []
  );

  const medicineOptions = useMemo(
    () => [
      { value: 'doctor', label: 'Orvos' },
      { value: 'pharmacy', label: 'Gyógyszertár' },
      { value: 'nurse', label: 'Ápoló' },
      { value: 'vet', label: 'Állatorvos' },
      { value: 'other', label: 'Egyéb' },
    ],
    []
  );

  const medicinePaymentOptions = useMemo(
    () => [
      { value: 'card', label: 'Bankkártyás fizetés' },
      { value: 'benefit', label: 'Szép kártyás fizetés' },
    ],
    []
  );

  const updateCallLog = async (reqID, newMessage) => {
    await fetchData(`${baseUrl}/api/aspreq/calllog/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newMessage,
    })
      .then(async (response) => {
        let resp = await response.json();
        reloadData();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  //  console.log(tempData, 'tempData');

  // const getStatusData = useCallback(async () => {
  //   await fetchData(`${baseUrl}/api/aspreq/getstatus/${statusID}`, 'GET', localStorage.getItem('JWT'))
  //     .then(async (response) => {
  //       let resp = await response.json();
  //       //console.log(resp, 'RESP');

  //       setTempStatus(resp.reqstatus);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       alert(err);
  //     });
  // }, [baseUrl, statusID]);

  // useEffect(() => {
  //   getStatusData();
  // }, [getStatusData]);

  const updateStatusStatus = async (reqID, newValue) => {
    await fetchData(`${baseUrl}/api/aspreq/status/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newValue,
    })
      .then(async (response) => {
        let resp = await response.json();

        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  // const reloadStatusData = async () => {
  //   await getStatusData();
  // };

  const couponCheck = useCallback(async (data) => {
    await fetchData(`${baseUrl}/api/coupons/get`, 'POST', localStorage.getItem('JWT'), data)
      .then(async (response) => {
        let resp = await response.json();
        setDiscountObj(resp);
        if (tempData.companytype === 'singleprimary' || 'singlesecondary' || 'kiva' || 'vszja') {
          setUsedDiscount(resp.discount);
          console.log('kicsi');
        } else {
          setUsedDiscount(resp.discountLarge);
          console.log('nagy');
        }
        if (tempData.originalContPrice === tempData.proposedContPrice) {
          console.log('egyforma ar', tempData);
          // if (tempData.selectedProvGrp !== '') {
          if (
            tempData.companytype === 'singleprimary' ||
            tempData.companytype === 'singlesecondary' ||
            tempData.companytype === 'kiva' ||
            tempData.companytype === 'vszja'
          ) {
            let newvalue;
            if (tempData.selectedProvGrp === 'ev') {
              newvalue = `${parseInt(tempData.potential_providers[0][0].contMinPrice) - resp.discount} - ${
                parseInt(tempData.potential_providers[0][0].contMaxPrice) - resp.discount
              }`;
            } else if (tempData.selectedProvGrp === 'office') {
              newvalue = `${parseInt(tempData.potential_providers[1][0].contMinPrice) - resp.discount} - ${
                parseInt(tempData.potential_providers[1][0].contMaxPrice) - resp.discount
              }`;
            } else if (tempData.selectedProvGrp === 'enterprise') {
              newvalue = `${parseInt(tempData.potential_providers[2][0].contMinPrice) - resp.discount} - ${
                parseInt(tempData.potential_providers[2][0].contMaxPrice) - resp.discount
              }`;
            }
            console.log(newvalue, 'NYUVELJU SZMOLL');
            setTempData((prevState) => ({
              ...prevState,
              proposedContPrice: newvalue,
            }));
            setIsChanged(true);
          } else {
            let newvalue;
            if (tempData.selectedProvGrp === 'ev') {
              newvalue = `${parseInt(tempData.potential_providers[0][0].contMinPrice) - resp.discountLarge} - ${
                parseInt(tempData.potential_providers[0][0].contMaxPrice) - resp.discountLarge
              }`;
            } else if (tempData.selectedProvGrp === 'office') {
              newvalue = `${parseInt(tempData.potential_providers[1][0].contMinPrice) - resp.discountLarge} - ${
                parseInt(tempData.potential_providers[1][0].contMaxPrice) - resp.discountLarge
              }`;
            } else if (tempData.selectedProvGrp === 'enterprise') {
              newvalue = `${parseInt(tempData.potential_providers[2][0].contMinPrice) - resp.discountLarge} - ${
                parseInt(tempData.potential_providers[2][0].contMaxPrice) - resp.discountLarge
              }`;
            }
            // console.log(newvalue, 'NYUVELJU LARDZS');
            setTempData((prevState) => ({
              ...prevState,
              proposedContPrice: newvalue,
            }));
            setIsChanged(true);
          }
          // } else {
          //   if (
          //     tempData.companytype === 'singleprimary' ||
          //     tempData.companytype === 'singlesecondary' ||
          //     tempData.companytype === 'kiva' ||
          //     tempData.companytype === 'vszja'
          //   ) {
          //     let newvalue = parseInt(tempObj.originalContPrice.split('.').join('')) - resp.discount;
          //     //console.log(newvalue, 'NYUVELJU SZMOLL');
          //     setTempData((prevState) => ({
          //       ...prevState,
          //       proposedContPrice: newvalue,
          //     }));
          //     setIsChanged(true);
          //   } else {
          //     let newvalue = parseInt(tempObj.originalContPrice.split('.').join('')) - resp.discountLarge;
          //     // console.log(newvalue, 'NYUVELJU LARDZS');
          //     setTempData((prevState) => ({
          //       ...prevState,
          //       proposedContPrice: newvalue,
          //     }));
          //     setIsChanged(true);
          //   }
          // }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const getRequestData = useCallback(
    async (statusID) => {
      await fetchData(`${baseUrl}/api/aspreq/${statusID}`, 'GET', localStorage.getItem('JWT'))
        .then(async (response) => {
          let resp = await response.json();
          //console.log(resp, 'RESP');
          setRequestData(resp);
          setTempData(resp);
          console.log(resp, 'RESPONSE');
          setTempStatus(resp.reqstatus);
          setServicesPicked(resp.services);
          if (resp.selectedProvGrp) {
            console.log('SELECTED GRP', resp.selectedProvGrp);
            setSelectedGrp(resp.selectedProvGrp);
          }
          if (resp.discountCode) {
            resp.discountCode &&
              (await couponCheck({
                couponCode: resp.discountCode,
                flow: 'search',
              }));
          }
          tempData && matchObjects(tempObj, tempData);
          if (resp.languages !== null && resp.languages !== undefined) {
            let newLangs = [];
            for (const [key, value] of Object.entries(resp.languages)) {
              if (value === true) {
                newLangs.push(key);
              }
            }
            newLangs.length !== 0 && setLanguagesPicked(newLangs);
            setPlacesSearched(resp.whereElseDidSearch);
          }
          if ((resp.bankingpref !== null || resp.bankingpref !== undefined) && resp.bankingpref) {
            let newBanks = [];
            for (const [key, value] of Object.entries(resp.bankingpref)) {
              if (value === true) {
                newBanks.push(key);
              }
            }
            setChosenBank(newBanks);
          }
          if ((resp.invoicingpref !== null || resp.invoicingpref !== undefined) && resp.invoicingpref) {
            let newInvo = [];
            for (const [key, value] of Object.entries(resp.invoicingpref)) {
              if (value === true) {
                newInvo.push(key);
              }
            }
            setChosenInvo(newInvo);
          }
          if ((resp.softwarepref !== null || resp.softwarepref !== undefined) && resp.softwarepref) {
            let newSoft = [];
            for (const [key, value] of Object.entries(resp.softwarepref)) {
              if (value === true) {
                newSoft.push(key);
              }
            }
            setChosenSoft(newSoft);
          }
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    },
    [baseUrl, statusID]
  );

  //console.log(tempData, 'TEMPDATA');

  useEffect(() => {
    //getStatusData();
    getRequestData(statusID);
  }, []);

  const reloadData = async () => {
    await getRequestData(statusID);
  };

  const matchObjects = useCallback(
    (targetObj, sourceObj) => {
      for (const [targetObjkey, targetObjvalue] of Object.entries(targetObj)) {
        for (const [sourceObjkey, sourceObjvalue] of Object.entries(sourceObj)) {
          if (targetObjkey === sourceObjkey) {
            setTempObj((prevState) => ({ ...prevState, [targetObjkey]: sourceObjvalue }));
          }
          if (sourceObjkey === 'services') {
            sourceObjvalue.forEach((serv) => {
              setTempObj((prevState) => ({ ...prevState, [serv]: true }));
            });
          }
        }
      }
    },
    [tempData]
  );

  const setMatchScoreObject = useCallback(() => {
    let splitStrings = [];
    let splitMatches = [];
    let mSObj = {
      distanceWeight: 1,
      priceWeight: 1,
      reviewWeight: 1,
      experienceWeight: 1,
      liabilityInsWeight: 1,
      badgeWeight: 1,
      phoneWeight: 1,
      availabilityWeight: 1,
    };
    typeof tempData.preferences === 'string' && tempData.preferences.split(',').forEach((e) => splitMatches.push(e));

    splitMatches.forEach((e) => splitStrings.push(e.split(':')));
    for (const rows in splitStrings) {
      if (splitStrings[rows][0].trim() === 'közelség') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.distanceWeight = '1';
        } else {
          mSObj.distanceWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'iparági tapasztalat') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.experienceWeight = '1';
        } else {
          mSObj.experienceWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'értékelés') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.reviewWeight = '1';
        } else {
          mSObj.reviewWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'biztosítás') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.liabilityInsWeight = '1';
        } else {
          mSObj.liabilityInsWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'ár') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.priceWeight = '1';
        } else {
          mSObj.priceWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'digitalizáltság') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.badgeWeight = '1';
        } else {
          mSObj.badgeWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'telefonos kapcs') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.phoneWeight = '1';
        } else {
          mSObj.phoneWeight = parseInt(splitStrings[rows][1]);
        }
      } else if (splitStrings[rows][0].trim() === 'folyamatos elérhetőség') {
        if (splitStrings[rows][1] === 'undefinded') {
          mSObj.availabilityWeight = '1';
        } else {
          mSObj.availabilityWeight = parseInt(splitStrings[rows][1]);
        }
      }
    }
    //console.log(mSObj, 'mSObjmSObjmSObjmSObj');
    setMatchScore(mSObj);
  });

  const callHandler = useCallback(
    (event) => {
      setCallNote(event.target.value);
    },
    [callNote, setCallNote]
  );

  useEffect(() => {
    tempData && setMatchScoreObject();
    //console.log(matchScore, 'MS OBJ');
  }, [tempData, tempObj]);

  const updateRequestData = useCallback(
    async (statusID, updateParams) => {
      await fetchData2(`${baseUrl}/api/aspreq/${statusID}`, 'PATCH', 'B3OeO1K7-KcEcE8PdI4Eb', {
        requestId: statusID,
        updateParams: updateParams,
      })
        .then(async (response) => {
          console.log('AXIOS RESPONSE: ', response);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    },
    [baseUrl, statusID]
  );

  const sendMail = async (tempObj) => {
    await fetchData(`${baseUrl}/api/aspreq/send/mail`, 'post', localStorage.getItem('JWT'), tempObj)
      .then(async (response) => {
        let resp = await response.json();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const updateOffer = async (tempObj, matchScore) => {
    const company_req = {
      company_req: tempObj,
      match_score_weights: matchScore,
    };
    //console.log(company_req, 'KOMPANI REQ');
    // if (
    //   tempData.selectedProvGrp === 'ev' ||
    //   tempData.selectedProvGrp === 'office' ||
    //   tempData.selectedProvGrp === 'enterprise'
    // ) {
    await fetchData2(`${baseUrl}/api/providerofferstop3/`, 'post', 'B3OeO1K7-KcEcE8PdI4Eb', company_req)
      .then(async (response) => {
        let resp = await response.json();
        console.log(resp, 'RESPONSE IN UPDATE');
        setUpdatedProviders(resp.matchingProviders);
        setTempObj((prevState) => ({
          ...prevState,
          potential_providers: resp.matchingProviders,
        }));
        setTempData((prevState) => ({
          ...prevState,
          potential_providers: resp.matchingProviders,
        }));
        if (tempData.selectedProvGrp === 'ev') {
          console.log('EV IN USEEFFECT');
          setTempObj((prevState) => ({
            ...prevState,
            proposedContPrice: `${resp.matchingProviders[0][0].contMinPrice - (usedDiscount || 0)} - ${
              resp.matchingProviders[0][0].contMaxPrice - (usedDiscount || 0)
            }`,
            originalContPrice: `${resp.matchingProviders[0][0].contMinPrice} - ${resp.matchingProviders[0][0].contMaxPrice}`,
            originalSinglePrice: resp.matchingProviders[0][0].singleEndPrice,
          }));
          // console.log('RESPONSE', resp);
          setTempData((prevState) => ({
            ...prevState,
            proposedContPrice: `${resp.matchingProviders[0][0].contMinPrice - (usedDiscount || 0)} - ${
              resp.matchingProviders[0][0].contMaxPrice - (usedDiscount || 0)
            }`,
            originalContPrice: `${resp.matchingProviders[0][0].contMinPrice} - ${resp.matchingProviders[0][0].contMaxPrice}`,
            originalSinglePrice: resp.matchingProviders[0][0].singleEndPrice,
          }));
        } else if (tempData.selectedProvGrp === 'office') {
          console.log('OFFICE IN USEEFFECT');
          setTempObj((prevState) => ({
            ...prevState,
            proposedContPrice: `${resp.matchingProviders[1][0].contMinPrice - (usedDiscount || 0)} - ${
              resp.matchingProviders[1][0].contMaxPrice - (usedDiscount || 0)
            }`,
            originalContPrice: `${resp.matchingProviders[1][0].contMinPrice} - ${resp.matchingProviders[1][0].contMaxPrice}`,
            originalSinglePrice: resp.matchingProviders[1][0].singleEndPrice,
          }));
          setTempData((prevState) => ({
            ...prevState,
            proposedContPrice: `${resp.matchingProviders[1][0].contMinPrice - (usedDiscount || 0)} - ${
              resp.matchingProviders[1][0].contMaxPrice - (usedDiscount || 0)
            }`,
            originalContPrice: `${resp.matchingProviders[1][0].contMinPrice} - ${resp.matchingProviders[1][0].contMaxPrice}`,
            originalSinglePrice: resp.matchingProviders[1][0].singleEndPrice,
          }));
        } else if (tempData.selectedProvGrp === 'enterprise') {
          console.log('ENTERPRISE IN USEEFFECT');
          setTempObj((prevState) => ({
            ...prevState,
            proposedContPrice: `${resp.matchingProviders[2][0].contMinPrice - (usedDiscount || 0)} - ${
              resp.matchingProviders[2][0].contMaxPrice - (usedDiscount || 0)
            }`,
            originalContPrice: `${resp.matchingProviders[2][0].contMinPrice} - ${resp.matchingProviders[2][0].contMaxPrice}`,
            originalSinglePrice: resp.matchingProviders[2][0].singleEndPrice,
          }));
          setTempData((prevState) => ({
            ...prevState,
            proposedContPrice: `${resp.matchingProviders[2][0].contMinPrice - (usedDiscount || 0)} - ${
              resp.matchingProviders[2][0].contMaxPrice - (usedDiscount || 0)
            }`,
            originalContPrice: `${resp.matchingProviders[2][0].contMinPrice} - ${resp.matchingProviders[2][0].contMaxPrice}`,
            originalSinglePrice: resp.matchingProviders[2][0].singleEndPrice,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
    // } else {
    //   await fetchData2(`${baseUrl}/api/provideroffers/`, 'post', 'B3OeO1K7-KcEcE8PdI4Eb', company_req)
    //     .then(async (response) => {
    //       let resp = await response.json();
    //       //console.log(resp, 'RESPONSE IN UPDATE');
    //       setUpdatedProviders(resp.matchingProviders);

    //       setTempObj((prevState) => ({
    //         ...prevState,
    //         potential_providers: resp.matchingProviders,
    //         proposedContPrice: resp.matchingProviders[0].contEndPrice - (usedDiscount || 0),
    //         originalContPrice: resp.matchingProviders[0].contEndPrice,
    //         originalSinglePrice: resp.matchingProviders[0].singleEndPrice,
    //       }));
    //       setTempData((prevState) => ({
    //         ...prevState,
    //         potential_providers: resp.matchingProviders,
    //         originalContPrice: resp.matchingProviders[0].contEndPrice,
    //         originalSinglePrice: resp.matchingProviders[0].singleEndPrice,
    //         proposedContPrice: resp.matchingProviders[0].contEndPrice - (usedDiscount || 0),
    //       }));
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       alert(err);
    //     });
    // }
  };

  const updateStatusMessage = async (reqID, newMessage) => {
    await fetchData(`${baseUrl}/api/aspreq/message/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newMessage,
    })
      .then(async (response) => {
        let resp = await response.json();
        reloadData();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const serviceChange = (event) => {
    let serv = [];

    services.forEach((service) => {
      setTempObj((prevState) => ({ ...prevState, [service.value]: false, services: serv }));
      setTempData((prevState) => ({ ...prevState, [service.value]: false }));
    });

    const {
      target: { value },
    } = event;
    value.forEach((service) => {
      setTempObj((prevState) => ({ ...prevState, [service.value]: true }));
      setTempData((prevState) => ({ ...prevState, [service.value]: true }));
    });
    setServicesPicked(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setTempObj((prevState) => ({ ...prevState, services: servicesPicked }));
    setTempData((prevState) => ({ ...prevState, services: servicesPicked }));
  };
  const whereDidSearchChange = (event) => {
    const {
      target: { value },
    } = event;

    setPlacesSearched(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    setTempObj((prevState) => ({ ...prevState, whereElseDidSearch: placesSearched }));
    setTempData((prevState) => ({ ...prevState, whereElseDidSearch: placesSearched }));
    setIsChanged(true);
    console.log(tempObj, 'TEMP');
  };

  useEffect(() => {
    if (placesSearched.length > 0) {
      setTempObj((prevState) => ({ ...prevState, whereElseDidSearch: placesSearched }));
      setTempData((prevState) => ({ ...prevState, whereElseDidSearch: placesSearched }));
    }
  }, [placesSearched]);

  useEffect(() => {
    if (servicesPicked.length > 0) {
      setTempObj((prevState) => ({ ...prevState, services: servicesPicked }));
      setTempData((prevState) => ({ ...prevState, services: servicesPicked }));
    }
  }, [servicesPicked]);

  const languageChange = (event) => {
    let tempLang = {
      hungarian: true,
      chinese: false,
      english: false,
      german: false,
      spanish: false,
      french: false,
      russian: false,
      romania: false,
    };

    const {
      target: { value },
    } = event;
    value.forEach((lang) => (tempLang[lang] = true));
    setTempObj((prevState) => ({ ...prevState, languages: tempLang }));
    setTempData((prevState) => ({ ...prevState, languages: tempLang }));

    setLanguagesPicked(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const setBankData = (event) => {
    let tempObj = {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      opt8: false,
      other: false,
      bankidk: false,
    };
    const {
      target: { value },
    } = event;
    value.forEach((opt) => (tempObj[opt] = true));
    setTempObj((prevState) => ({ ...prevState, bankingpref: tempObj }));
    setTempData((prevState) => ({ ...prevState, bankingpref: tempObj }));

    setChosenBank(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const setInvoData = (event) => {
    let tempObj = {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      other: false,
      invoicingidk: true,
    };
    const {
      target: { value },
    } = event;
    value.forEach((opt) => (tempObj[opt] = true));
    setTempObj((prevState) => ({ ...prevState, invoicingpref: tempObj }));
    setTempData((prevState) => ({ ...prevState, invoicingpref: tempObj }));

    setChosenInvo(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const setSoftwareData = (event) => {
    let tempObj = {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      opt8: false,
      opt9: false,
      opt10: false,
      opt11: false,
      opt12: false,
      opt13: false,
      opt14: false,
      opt15: false,
      opt16: false,
      opt17: false,
      opt18: false,
      opt19: false,
      opt20: false,
      other: false,
    };
    const {
      target: { value },
    } = event;
    value.forEach((opt) => (tempObj[opt] = true));
    setTempObj((prevState) => ({ ...prevState, softwarepref: tempObj }));
    setTempData((prevState) => ({ ...prevState, softwarepref: tempObj }));

    setChosenSoft(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const singleValueHandler = useCallback((event) => {
    setTempObj((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
    setTempData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));

    setIsChanged(true);
  });

  const switchHandler = (e) => {
    console.log(e.target.value, typeof e.target.value, 'TARGET');

    setTempObj((prevState) => ({ ...prevState, [e.target.name]: e.target.checked }));
    setTempData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
    setIsChanged(true);
    console.log(tempData, 'tempData', tempObj);
  };

  const messageHandler = useCallback(
    (event) => {
      setMessage(event.target.value);
    },
    [message, setMessage]
  );

  useEffect(() => {
    tempData && matchObjects(tempObj, tempData);
  }, [tempData]);

  const setPriceOnGrpChange = (event) => {
    if (tempData) {
      console.log(tempData, 'TEMPDATA IN GRPCHANGE');
      if (event.target.value === 'ev') {
        console.log('EV IN USEEFFECT');
        setTempObj((prevState) => ({
          ...prevState,
          proposedContPrice: `${tempData.potential_providers[0][0].contMinPrice - (usedDiscount || 0)} - ${
            tempData.potential_providers[0][0].contMaxPrice - (usedDiscount || 0)
          }`,
          originalContPrice: `${tempData.potential_providers[0][0].contMinPrice} - ${tempData.potential_providers[0][0].contMaxPrice}`,
          originalSinglePrice: tempData.potential_providers[0][0].singleEndPrice,
        }));
        setTempData((prevState) => ({
          ...prevState,
          proposedContPrice: `${tempData.potential_providers[0][0].contMinPrice - (usedDiscount || 0)} - ${
            tempData.potential_providers[0][0].contMaxPrice - (usedDiscount || 0)
          }`,
          originalContPrice: `${tempData.potential_providers[0][0].contMinPrice} - ${tempData.potential_providers[0][0].contMaxPrice}`,
          originalSinglePrice: tempData.potential_providers[0][0].singleEndPrice,
        }));
      } else if (event.target.value === 'office') {
        console.log('OFFICE IN USEEFFECT');
        setTempObj((prevState) => ({
          ...prevState,
          proposedContPrice: `${tempData.potential_providers[1][0].contMinPrice - (usedDiscount || 0)} - ${
            tempData.potential_providers[1][0].contMaxPrice - (usedDiscount || 0)
          }`,
          originalContPrice: `${tempData.potential_providers[1][0].contMinPrice} - ${tempData.potential_providers[1][0].contMaxPrice}`,
          originalSinglePrice: tempData.potential_providers[1][0].singleEndPrice,
        }));
        setTempData((prevState) => ({
          ...prevState,
          proposedContPrice: `${tempData.potential_providers[1][0].contMinPrice - (usedDiscount || 0)} - ${
            tempData.potential_providers[1][0].contMaxPrice - (usedDiscount || 0)
          }`,
          originalContPrice: `${tempData.potential_providers[1][0].contMinPrice} - ${tempData.potential_providers[1][0].contMaxPrice}`,
          originalSinglePrice: tempData.potential_providers[1][0].singleEndPrice,
        }));
      } else if (event.target.value === 'enterprise') {
        console.log('ENTERPRISE IN USEEFFECT');
        setTempObj((prevState) => ({
          ...prevState,
          proposedContPrice: `${tempData.potential_providers[2][0].contMinPrice - (usedDiscount || 0)} - ${
            tempData.potential_providers[2][0].contMaxPrice - (usedDiscount || 0)
          }`,
          originalContPrice: `${tempData.potential_providers[2][0].contMinPrice} - ${tempData.potential_providers[2][0].contMaxPrice}`,
          originalSinglePrice: tempData.potential_providers[2][0].singleEndPrice,
        }));
        setTempData((prevState) => ({
          ...prevState,
          proposedContPrice: `${tempData.potential_providers[2][0].contMinPrice - (usedDiscount || 0)} - ${
            tempData.potential_providers[2][0].contMaxPrice - (usedDiscount || 0)
          }`,
          originalContPrice: `${tempData.potential_providers[2][0].contMinPrice} - ${tempData.potential_providers[2][0].contMaxPrice}`,
          originalSinglePrice: tempData.potential_providers[2][0].singleEndPrice,
        }));
      }
    }
  };

  const handleEtradeSellsChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedEtradeSells(selectedValues);

    const updatedEtradeSells = {
      physicalproducts: selectedValues.includes('physicalproducts'),
      services: selectedValues.includes('services'),
      intellectualproperty: selectedValues.includes('intellectualproperty'),
      other: selectedValues.includes('other'),
      otherinput: tempData?.industryspecifics?.etrade?.etradesells?.otherinput || '',
    };

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        etrade: {
          ...prevState.industryspecifics?.etrade,
          etradesells: updatedEtradeSells,
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        etrade: {
          ...prevState.industryspecifics?.etrade,
          etradesells: updatedEtradeSells,
        },
      },
    }));

    setIsChanged(true);
  };

  const handleEtradeSellsOtherInputChange = (event) => {
    const value = event.target.value;

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        etrade: {
          ...prevState.industryspecifics?.etrade,
          etradesells: {
            ...prevState.industryspecifics?.etrade?.etradesells,
            otherinput: value,
          },
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        etrade: {
          ...prevState.industryspecifics?.etrade,
          etradesells: {
            ...prevState.industryspecifics?.etrade?.etradesells,
            otherinput: value,
          },
        },
      },
    }));

    setIsChanged(true);
  };

  const handleEtradeCourierSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedEtradeCourier(selectedValues);

    const updatedEtradeMultipleCourier = etradeCourierOptions.reduce((acc, opt) => {
      acc[opt.value] = selectedValues.includes(opt.value);
      return acc;
    }, {});

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        etrade: {
          ...prevState.industryspecifics?.etrade,
          etrademultiplecourier: updatedEtradeMultipleCourier,
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        etrade: {
          ...prevState.industryspecifics?.etrade,
          etrademultiplecourier: updatedEtradeMultipleCourier,
        },
      },
    }));

    setIsChanged(true);
  };

  const handleEtradeDropoffSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedEtradeDropoff(selectedValues);

    const updatedEtradeMultipleDropoff = etradeDropoffOptions.reduce((acc, opt) => {
      acc[opt.value] = selectedValues.includes(opt.value);
      return acc;
    }, {});

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        etrade: {
          ...prevState.industryspecifics?.etrade,
          etrademultipledropoff: updatedEtradeMultipleDropoff,
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        etrade: {
          ...prevState.industryspecifics?.etrade,
          etrademultipledropoff: updatedEtradeMultipleDropoff,
        },
      },
    }));

    setIsChanged(true);
  };

  const handleEtradePaymentSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedEtradePayment(selectedValues);

    const updatedEtradePayment = etradePaymentOptions.reduce((acc, opt) => {
      acc[opt.value] = selectedValues.includes(opt.value);
      return acc;
    }, {});

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        etrade: {
          ...prevState.industryspecifics?.etrade,
          etradepayment: updatedEtradePayment,
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        etrade: {
          ...prevState.industryspecifics?.etrade,
          etradepayment: updatedEtradePayment,
        },
      },
    }));

    setIsChanged(true);
  };

  const handleRetailPaymentSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedRetailPayment(selectedValues);

    const updatedRetailPayment = retailPaymentOptions.reduce((acc, opt) => {
      acc[opt.value] = selectedValues.includes(opt.value);
      return acc;
    }, {});

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        retail: {
          ...prevState.industryspecifics?.retail,
          retailpayment: updatedRetailPayment,
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        retail: {
          ...prevState.industryspecifics?.retail,
          retailpayment: updatedRetailPayment,
        },
      },
    }));

    setIsChanged(true);
  };

  const handleWholesalePaymentSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedWholesalePayment(selectedValues);

    const updatedWholesalePayment = wholesalePaymentOptions.reduce((acc, opt) => {
      acc[opt.value] = selectedValues.includes(opt.value);
      return acc;
    }, {});

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        wholesale: {
          ...prevState.industryspecifics?.wholesale,
          wholesalepayment: updatedWholesalePayment,
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        wholesale: {
          ...prevState.industryspecifics?.wholesale,
          wholesalepayment: updatedWholesalePayment,
        },
      },
    }));

    setIsChanged(true);
  };

  const handleVehicleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedVehicleOptions(selectedValues);

    const otherInputValue = tempData?.industryspecifics?.vehicle?.differentialvat?.otherinput || '';

    const updatedVehicleIndustry = vehicleOptions.reduce((acc, opt) => {
      acc[opt.value] = selectedValues.includes(opt.value);
      return acc;
    }, {});

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        vehicle: {
          ...prevState.industryspecifics?.vehicle,
          differentialvat: {
            ...updatedVehicleIndustry,
            otherinput: otherInputValue,
          },
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        vehicle: {
          ...prevState.industryspecifics?.vehicle,
          differentialvat: {
            ...updatedVehicleIndustry,
            otherinput: otherInputValue,
          },
        },
      },
    }));

    setIsChanged(true);
  };

  const handleVehicleOtherInputChange = (event) => {
    const { value } = event.target;

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        vehicle: {
          ...prevState.industryspecifics.vehicle,
          differentialvat: {
            ...prevState.industryspecifics.vehicle.differentialvat,
            otherinput: value,
          },
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        vehicle: {
          ...prevState.industryspecifics.vehicle,
          differentialvat: {
            ...prevState.industryspecifics.vehicle.differentialvat,
            otherinput: value,
          },
        },
      },
    }));

    setIsChanged(true);
  };

  const handleConstructionSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedConstructionOptions(selectedValues);

    const otherInputValue = tempData?.industryspecifics?.construction?.constructiontype?.otherinput || '';

    const updatedConstructionIndustry = constructionOptions.reduce((acc, opt) => {
      acc[opt.value] = selectedValues.includes(opt.value);
      return acc;
    }, {});

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        construction: {
          ...prevState.industryspecifics?.construction,
          constructiontype: {
            ...updatedConstructionIndustry,
            otherinput: otherInputValue,
          },
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        construction: {
          ...prevState.industryspecifics?.construction,
          constructiontype: {
            ...updatedConstructionIndustry,
            otherinput: otherInputValue,
          },
        },
      },
    }));

    setIsChanged(true);
  };

  const handleConstructionOtherInputChange = (event) => {
    const { value } = event.target;

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        construction: {
          ...prevState.industryspecifics.construction,
          constructiontype: {
            ...prevState.industryspecifics.construction.constructiontype,
            otherinput: value,
          },
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        construction: {
          ...prevState.industryspecifics.construction,
          constructiontype: {
            ...prevState.industryspecifics.construction.constructiontype,
            otherinput: value,
          },
        },
      },
    }));

    setIsChanged(true);
  };

  const handleAgricultureSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedAgricultureOptions(selectedValues);

    const updatedAgricultureIndustry = agricultureOptions.reduce((acc, opt) => {
      acc[opt.value] = selectedValues.includes(opt.value);
      return acc;
    }, {});

    const otherInputValue = tempData?.industryspecifics?.agriculture?.agriculturetype?.otherinput || '';

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        agriculture: {
          ...prevState.industryspecifics?.agriculture,
          agriculturetype: {
            ...updatedAgricultureIndustry,
            otherinput: otherInputValue,
          },
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        agriculture: {
          ...prevState.industryspecifics?.agriculture,
          agriculturetype: {
            ...updatedAgricultureIndustry,
            otherinput: otherInputValue,
          },
        },
      },
    }));

    setIsChanged(true);
  };

  const handleAgricultureOtherInputChange = (event) => {
    const { value } = event.target;

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        agriculture: {
          ...prevState.industryspecifics?.agriculture,
          agriculturetype: {
            ...prevState.industryspecifics.agriculture.agriculturetype,
            otherinput: value,
          },
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        agriculture: {
          ...prevState.industryspecifics?.agriculture,
          agriculturetype: {
            ...prevState.industryspecifics.agriculture.agriculturetype,
            otherinput: value,
          },
        },
      },
    }));

    setIsChanged(true);
  };

  const handleITSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedITOptions(selectedValues);

    const updatedITIndustry = ITOptions.reduce((acc, opt) => {
      acc[opt.value] = selectedValues.includes(opt.value);
      return acc;
    }, {});

    const otherInputValue = tempData?.industryspecifics?.IT?.ittype?.otherinput || '';

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        IT: {
          ...prevState.industryspecifics?.IT,
          ittype: {
            ...updatedITIndustry,
            otherinput: otherInputValue,
          },
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        IT: {
          ...prevState.industryspecifics?.IT,
          ittype: {
            ...updatedITIndustry,
            otherinput: otherInputValue,
          },
        },
      },
    }));

    setIsChanged(true);
  };

  const handleITOtherInputChange = (event) => {
    const { value } = event.target;

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        IT: {
          ...prevState.industryspecifics?.IT,
          ittype: {
            ...prevState.industryspecifics.IT.ittype,
            otherinput: value,
          },
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        IT: {
          ...prevState.industryspecifics?.IT,
          ittype: {
            ...prevState.industryspecifics.IT.ittype,
            otherinput: value,
          },
        },
      },
    }));

    setIsChanged(true);
  };

  const handleArtSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedArtOptions(selectedValues);

    const updatedArtIndustry = artOptions.reduce((acc, opt) => {
      acc[opt.value] = selectedValues.includes(opt.value);
      return acc;
    }, {});

    const otherInputValue = tempData?.industryspecifics?.art?.arttype?.otherinput || '';

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        art: {
          ...prevState.industryspecifics?.art,
          arttype: {
            ...updatedArtIndustry,
            otherinput: otherInputValue,
          },
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        art: {
          ...prevState.industryspecifics?.art,
          arttype: {
            ...updatedArtIndustry,
            otherinput: otherInputValue,
          },
        },
      },
    }));

    setIsChanged(true);
  };

  const handleArtOtherInputChange = (event) => {
    const { value } = event.target;

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        art: {
          ...prevState.industryspecifics?.art,
          arttype: {
            ...prevState.industryspecifics.art.arttype,
            otherinput: value,
          },
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        art: {
          ...prevState.industryspecifics?.art,
          arttype: {
            ...prevState.industryspecifics.art.arttype,
            otherinput: value,
          },
        },
      },
    }));

    setIsChanged(true);
  };

  const handleTravelPaymentSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedTravelPayment(selectedValues);

    const updatedTravelPayment = travelPaymentOptions.reduce((acc, opt) => {
      acc[opt.value] = selectedValues.includes(opt.value);
      return acc;
    }, {});

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        travel: {
          ...prevState.industryspecifics?.travel,
          travelpayment: updatedTravelPayment,
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        travel: {
          ...prevState.industryspecifics?.travel,
          travelpayment: updatedTravelPayment,
        },
      },
    }));

    setIsChanged(true);
  };

  const handleMedicineSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedMedicineOptions(selectedValues);

    const updatedMedicineIndustry = medicineOptions.reduce((acc, opt) => {
      acc[opt.value] = selectedValues.includes(opt.value);
      return acc;
    }, {});

    const otherInputValue = tempData?.industryspecifics?.medicine?.medicinetype?.otherinput || '';

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        medicine: {
          ...prevState.industryspecifics?.medicine,
          medicinetype: {
            ...updatedMedicineIndustry,
            otherinput: otherInputValue,
          },
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        medicine: {
          ...prevState.industryspecifics?.medicine,
          medicinetype: {
            ...updatedMedicineIndustry,
            otherinput: otherInputValue,
          },
        },
      },
    }));

    setIsChanged(true);
  };

  const handleMedicineOtherInputChange = (event) => {
    const { value } = event.target;

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        medicine: {
          ...prevState.industryspecifics?.medicine,
          medicinetype: {
            ...prevState.industryspecifics.medicine.medicinetype,
            otherinput: value,
          },
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        medicine: {
          ...prevState.industryspecifics?.medicine,
          medicinetype: {
            ...prevState.industryspecifics.medicine.medicinetype,
            otherinput: value,
          },
        },
      },
    }));

    setIsChanged(true);
  };

  const handleMedicinePaymentSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectedValues = typeof value === 'string' ? value.split(',') : value;
    setSelectedMedicinePayment(selectedValues);

    const updatedMedicinePayment = medicinePaymentOptions.reduce((acc, opt) => {
      acc[opt.value] = selectedValues.includes(opt.value);
      return acc;
    }, {});

    setTempData((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        medicine: {
          ...prevState.industryspecifics?.medicine,
          medicinepayment: updatedMedicinePayment,
        },
      },
    }));

    setTempObj((prevState) => ({
      ...prevState,
      industryspecifics: {
        ...prevState.industryspecifics,
        medicine: {
          ...prevState.industryspecifics?.medicine,
          medicinepayment: updatedMedicinePayment,
        },
      },
    }));

    setIsChanged(true);
  };

  useEffect(() => {
    const etradesells = tempData?.industryspecifics?.etrade?.etradesells || {};
    const selected = [];
    if (etradesells.physicalproducts) selected.push('physicalproducts');
    if (etradesells.services) selected.push('services');
    if (etradesells.intellectualproperty) selected.push('intellectualproperty');
    if (etradesells.other) selected.push('other');
    setSelectedEtradeSells(selected);

    const etrademultiplecourier = tempData?.industryspecifics?.etrade?.etrademultiplecourier || {};
    const selectedCourier = etradeCourierOptions
      .filter((opt) => etrademultiplecourier[opt.value])
      .map((opt) => opt.value);
    setSelectedEtradeCourier(selectedCourier);

    const etrademultipledropoff = tempData?.industryspecifics?.etrade?.etrademultipledropoff || {};
    const selectedDropoff = etradeDropoffOptions
      .filter((opt) => etrademultipledropoff[opt.value])
      .map((opt) => opt.value);
    setSelectedEtradeDropoff(selectedDropoff);

    const etradepayment = tempData?.industryspecifics?.etrade?.etradepayment || {};
    const selectedPayment = etradePaymentOptions.filter((opt) => etradepayment[opt.value]).map((opt) => opt.value);
    setSelectedEtradePayment(selectedPayment);

    const retailpayment = tempData?.industryspecifics?.retail?.retailpayment || {};
    const selectedRetailPayment = retailPaymentOptions
      .filter((opt) => retailpayment[opt.value])
      .map((opt) => opt.value);
    setSelectedRetailPayment(selectedRetailPayment);

    const wholesalepayment = tempData?.industryspecifics?.wholesale?.wholesalepayment || {};
    const selectedWholesalePayment = wholesalePaymentOptions
      .filter((opt) => wholesalepayment[opt.value])
      .map((opt) => opt.value);
    setSelectedWholesalePayment(selectedWholesalePayment);

    const vehicleIndustry = tempData?.industryspecifics?.vehicle?.differentialvat || {};
    const selectedVehicle = vehicleOptions.filter((opt) => vehicleIndustry[opt.value]).map((opt) => opt.value);
    setSelectedVehicleOptions(selectedVehicle);

    const constructionIndustry = tempData?.industryspecifics?.construction?.constructiontype || {};
    const selectedConstruction = constructionOptions
      .filter((opt) => constructionIndustry[opt.value])
      .map((opt) => opt.value);
    setSelectedConstructionOptions(selectedConstruction);

    const agricultureIndustry = tempData?.industryspecifics?.agriculture?.agriculturetype || {};
    const selectedAgriculture = agricultureOptions
      .filter((opt) => agricultureIndustry[opt.value])
      .map((opt) => opt.value);
    setSelectedAgricultureOptions(selectedAgriculture);

    const ITIndustry = tempData?.industryspecifics?.IT?.ittype || {};
    const selectedIT = ITOptions.filter((opt) => ITIndustry[opt.value]).map((opt) => opt.value);
    setSelectedITOptions(selectedIT);

    const artIndustry = tempData?.industryspecifics?.art?.arttype || {};
    const selectedArt = artOptions.filter((opt) => artIndustry[opt.value]).map((opt) => opt.value);
    setSelectedArtOptions(selectedArt);

    const travelpayment = tempData?.industryspecifics?.travel?.travelpayment || {};
    const selectedTravelPayment = travelPaymentOptions
      .filter((opt) => travelpayment[opt.value])
      .map((opt) => opt.value);
    setSelectedTravelPayment(selectedTravelPayment);

    const medicineIndustry = tempData?.industryspecifics?.medicine?.medicinetype || {};
    const selectedMedicine = medicineOptions.filter((opt) => medicineIndustry[opt.value]).map((opt) => opt.value);
    setSelectedMedicineOptions(selectedMedicine);

    const medicinepayment = tempData?.industryspecifics?.medicine?.medicinepayment || {};
    const selectedMedicinePayment = medicinePaymentOptions
      .filter((opt) => medicinepayment[opt.value])
      .map((opt) => opt.value);
    setSelectedMedicinePayment(selectedMedicinePayment);
  }, [
    tempData,
    etradeCourierOptions,
    etradeDropoffOptions,
    etradePaymentOptions,
    vehicleOptions,
    retailPaymentOptions,
    wholesalePaymentOptions,
    constructionOptions,
    agricultureOptions,
    ITOptions,
    artOptions,
    travelPaymentOptions,
    medicineOptions,
    medicinePaymentOptions,
  ]);

  const renderEtradeIndustryContent = tempData?.industry === 'etrade';
  const renderRetailIndustryContent = tempData?.industry === 'retail';
  const renderWholesaleIndustryContent = tempData?.industry === 'wholesale';
  const renderVehicleIndustryContent = tempData?.industry === 'vehicle';
  const renderConstructionIndustryContent = tempData?.industry === 'construction';
  const renderAgricultureIndustryContent = tempData?.industry === 'agriculture';
  const renderITIndustryContent = tempData?.industry === 'IT';
  const renderArtIndustryContent = tempData?.industry === 'art';
  const renderBeautyIndustryContent = tempData?.industry === 'beauty';
  const renderTravelIndustryContent = tempData?.industry === 'travel';
  const renderFinanceIndustryContent = tempData?.industry === 'finance';
  const renderMedicineIndustryContent = tempData?.industry === 'medicine';
  const renderCourierIndustryContent = tempData?.industry === 'courier';

  useEffect(() => {
    // console.log('INDUSTRY NAME', tempData?.industry);
    // console.log('INDUSTRY SPECIFICS', tempData?.industryspecifics);
    // console.log('TEMPDATA', tempData);
  }, [tempData]);

  return (
    <div>
      {tempData ? (
        <div className="validationGrid">
          <div className="validationChild1">
            {tempData.isfakecherry ? (
              <p style={{ fontWeight: '700', fontSize: '20px', color: 'red' }}>Cherrypickről jött!</p>
            ) : tempData.selectedProvGrp !== '' &&
              tempData.selectedProvGrp !== null &&
              tempData.selectedProvGrp !== undefined ? (
              <p style={{ fontWeight: '700', fontSize: '20px', color: 'red' }}>ASPről jött TOP3!</p>
            ) : (
              <p style={{ fontWeight: '700', fontSize: '20px', color: 'red' }}>ASPről jött!</p>
            )}
            <p>
              BUPA-s keresés?: <b>{tempData.BupaID ? 'IGEN' : 'NEM'}</b>{' '}
              <span style={{ marginLeft: '2rem' }}>
                BUPA érdekli: nem{' '}
                <Switch
                  checked={tempObj.interestedInBupa}
                  value={tempObj.interestedInBupa}
                  onChange={(e) => switchHandler(e)}
                  name="interestedInBupa"
                />{' '}
                igen
              </span>
            </p>
            <span style={{ marginLeft: '2rem' }}>
              Behajtás alatt: nem{' '}
              <Switch
                checked={tempObj.isUnderCollection}
                value={tempObj.isUnderCollection}
                onChange={(e) => switchHandler(e)}
                name="isUnderCollection"
              />{' '}
              igen
            </span>
            {/* CALCOM LINK */}
            <div style={{ display: 'flex', margin: '8px 0' }}>
              <p>Cal.com foglalás:</p>
              <p style={{ marginLeft: '76px' }}>
                {tempData.bookings && tempData.bookings.length > 0 ? (
                  tempData.bookings.map((booking, index) => {
                    const uid = booking.uid ?? "unknown";

                    const calComUrl = `https://cal.com/booking/${uid}`;

                    const date = new Date(booking.startTime);
                    const formattedDate = `${date.getFullYear()}.${String(date.getMonth() + 1).padStart(2, '0')}.${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;

                    return (
                      <span key={index}>
                        <a href={calComUrl} target="_blank" rel="noopener noreferrer">
                          {/* {index + 1}. Időpontfoglalás */}
                          {/* Időpontfoglalás */}
                          {/* {booking.startTime} */}
                          {formattedDate}
                        </a>
                        <br />
                      </span>
                    );
                  })
                ) : (
                  "Nincsen foglalás"
                )}
              </p>
            </div>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Ajánlatkérő neve: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="companyctcname"
                name="companyctcname"
                onChange={singleValueHandler}
                style={{ width: '300px' }}
                variant="standard"
                value={tempData.companyctcname}
                sx={{ fontSize: 14 }}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Ajánlatkérő e-mail: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="email"
                name="email"
                onChange={singleValueHandler}
                style={{ width: '300px' }}
                variant="standard"
                value={tempData.email}
                sx={{ fontSize: 14 }}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Ajánlatkérő telefon: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="companyPhone"
                name="companyPhone"
                onChange={singleValueHandler}
                style={{ width: '300px' }}
                variant="standard"
                value={tempData.companyPhone}
                sx={{ fontSize: 14 }}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Irányítószám: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="zip_code"
                name="zip_code"
                onChange={singleValueHandler}
                style={{ width: '300px' }}
                variant="standard"
                value={tempData.zip_code}
                sx={{ fontSize: 14 }}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Mikortól kéri: </span>
              <Select
                id="chosendate"
                name="chosendate"
                onChange={singleValueHandler}
                value={tempData.chosendate}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={'asap'}>Minél hamarabb</MenuItem>
                <MenuItem value={'2weeks'}>1-2 hét</MenuItem>
                <MenuItem value={'2months'}>1-2 hónap</MenuItem>
                <MenuItem value={'6months'}>5-6 hónap</MenuItem>
              </Select>
            </p>
            {/* {tempData.selectedProvGrp !== '' && ( */}
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Kiválasztott csoport: </span>
              <Select
                id="selectedProvGrp"
                name="selectedProvGrp"
                onChange={(event) => {
                  singleValueHandler(event);
                  setPriceOnGrpChange(event);
                }}
                value={tempData.selectedProvGrp || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={''}>Nincs kiválasztva</MenuItem>
                <MenuItem value={'ev'}>EV</MenuItem>
                <MenuItem value={'office'}>Kis Iroda</MenuItem>
                <MenuItem value={'enterprise'}>Nagy Iroda</MenuItem>
              </Select>
            </p>
            {/* )} */}
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Megbízás: </span>
              <Select
                id="isCommission"
                name="isCommission"
                onChange={singleValueHandler}
                value={tempData.isCommission}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={true}>Igen</MenuItem>
                <MenuItem value={false}>Nem</MenuItem>
              </Select>
            </p>
            {!tempData.isfakecherry && (
              <>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Folyamatos ár: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    sx={{ fontSize: 14 }}
                    style={{ width: '300px' }}
                    variant="standard"
                    value={tempData.originalContPrice}
                  />
                </p>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Folyamatos ár(kedvezményes):{' '}
                  </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    sx={{ fontSize: 14 }}
                    style={{ width: '300px' }}
                    variant="standard"
                    value={tempObj.proposedContPrice}
                  />
                </p>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Egyszeri ár: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    sx={{ fontSize: 14 }}
                    style={{ width: '300px' }}
                    variant="standard"
                    value={tempData.originalSinglePrice}
                  />
                </p>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Kedvezménykód: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    sx={{ fontSize: 14 }}
                    style={{ width: '300px' }}
                    variant="standard"
                    value={tempData.discountCode}
                    onChange={(event) => {
                      setTempData((prevState) => ({ ...prevState, discountCode: event.target.value }));
                      setTempObj((prevState) => ({ ...prevState, discountCode: event.target.value }));
                    }}
                  />
                </p>
                <p>
                  <Button
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    sx={{ fontSize: 14 }}
                    style={{ width: '100%' }}
                    variant="standard"
                    disabled={tempData.discountCode === '' ? true : false}
                    onClick={async () => {
                      await couponCheck({
                        couponCode: tempData.discountCode,
                        flow: 'search',
                      });
                      console.log(discountObj, 'discountObj');
                      // if (
                      //   tempData.companytype === 'singleprimary' ||
                      //   tempData.companytype === 'singlesecondary' ||
                      //   tempData.companytype === 'kiva' ||
                      //   tempData.companytype === 'vszja'
                      // ) {
                      //   setTempObj((prevState) => ({
                      //     ...prevState,
                      //     proposedContPrice: parseInt(tempObj.originalContPrice) - parseInt(discountObj.discount),
                      //   }));
                      // } else {
                      //   setTempObj((prevState) => ({
                      //     ...prevState,
                      //     proposedContPrice: parseInt(tempObj.originalContPrice) - parseInt(discountObj.discountLarge),
                      //   }));
                      // }
                    }}
                  >
                    <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Kód check </span>
                  </Button>
                </p>
              </>
            )}
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Üzenet: </span>
              <TextareaAutosize
                inputProps={{ style: { fontSize: 14, padding: 2, border: 'none' } }}
                style={{ width: 'calc(80% - 200px)' }}
                variant="standard"
                value={tempData.reqMessage}
                sx={{ fontSize: 14 }}
                maxRows={2}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Új alapítású: </span>
              <Select
                id="newlyfounded"
                name="newlyfounded"
                onChange={singleValueHandler}
                value={tempData.newlyfounded}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={true}>Igen</MenuItem>
                <MenuItem value={false}>Nem</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Szolgáltatás:</span>
              <Select
                variant="standard"
                onChange={(event) => {
                  serviceChange(event);
                }}
                multiple
                value={servicesPicked}
                // renderValue={(selected) => selected.join(', ')}
                renderValue={(selected) =>
                  selected.map((service) => services.find((s) => s.value === service)?.label).join(', ')
                }
                style={{ width: '300px' }}
                sx={{ fontSize: 14 }}
              >
                {services.map((service) => (
                  <MenuItem key={service.value} value={service.value}>
                    <Checkbox checked={servicesPicked.indexOf(service.value) > -1} />
                    <ListItemText primary={service.label} />
                  </MenuItem>
                ))}
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Alkalmazottak: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="noofemployees"
                name="noofemployees"
                onChange={singleValueHandler}
                style={{ width: '300px' }}
                variant="standard"
                value={tempData.noofemployees}
                sx={{ fontSize: 14 }}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Éves árbevétel: </span>
              <Select
                id="yearlyrevenue"
                name="yearlyrevenue"
                onChange={singleValueHandler}
                value={tempData.yearlyrevenue}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={5}>0-5 millió</MenuItem>
                <MenuItem value={10}>5-12 millió</MenuItem>
                <MenuItem value={50}>12-50 millió</MenuItem>
                <MenuItem value={100}>50-100 millió</MenuItem>
                <MenuItem value={200}>100-200 millió</MenuItem>
                <MenuItem value={500}>200-500 millió</MenuItem>
                <MenuItem value={1999}>500-2000 millió</MenuItem>
                <MenuItem value={2000}>2000+ millió</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Bejövő számlák: </span>
              <Select
                id="incominginvoices"
                name="incominginvoices"
                onChange={singleValueHandler}
                value={tempData.incominginvoices}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={10}>1-10 között</MenuItem>
                <MenuItem value={20}>11-20 között</MenuItem>
                <MenuItem value={50}>21-50 között</MenuItem>
                <MenuItem value={100}>51-100 között</MenuItem>
                <MenuItem value={200}>101-200 között</MenuItem>
                <MenuItem value={500}>201-500 között</MenuItem>
                <MenuItem value={1000}>501-1000 között</MenuItem>
                <MenuItem value={2000}>1000 fölött</MenuItem>
                <MenuItem value={0}>Nem tudja</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>kimenő számlák: </span>
              <Select
                id="outgoinginvoices"
                name="outgoinginvoices"
                onChange={singleValueHandler}
                value={tempData.outgoinginvoices}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={0}>0</MenuItem>
                <MenuItem value={10}>1-10 között</MenuItem>
                <MenuItem value={20}>11-20 között</MenuItem>
                <MenuItem value={50}>21-50 között</MenuItem>
                <MenuItem value={100}>51-100 között</MenuItem>
                <MenuItem value={200}>101-200 között</MenuItem>
                <MenuItem value={500}>201-500 között</MenuItem>
                <MenuItem value={1000}>501-1000 között</MenuItem>
                <MenuItem value={2000}>1000 fölött</MenuItem>
                <MenuItem value={0}>Nem tudja</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Iparág: </span>
              <Select
                id="industry"
                name="industry"
                onChange={singleValueHandler}
                value={tempData.industry}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={'IT'}>Informatika</MenuItem>
                <MenuItem value={'construction'}>Építőipar</MenuItem>
                <MenuItem value={'etrade'}>E-kereskedelem</MenuItem>
                {/* <MenuItem value={'trade'}>Kis-és nagyker</MenuItem> */}
                <MenuItem value={'retail'}>Kiskereskedelem</MenuItem>
                <MenuItem value={'wholesale'}>Nagykereskedelem</MenuItem>
                <MenuItem value={'art'}>Művészet</MenuItem>
                <MenuItem value={'education'}>Oktatás, Kutatás</MenuItem>
                <MenuItem value={'medicine'}>Egészségügy</MenuItem>
                <MenuItem value={'vehicle'}>Gépjármű Ipar</MenuItem>
                <MenuItem value={'manufacturing'}>Gyártás és Feldolgozó Ipar</MenuItem>
                <MenuItem value={'realestate'}>Ingatlankereskedelem</MenuItem>
                <MenuItem value={'agriculture'}>Mező-és erdőgazdálkodás, halászat</MenuItem>
                <MenuItem value={'finance'}>Pénzügy, biztosítás</MenuItem>
                <MenuItem value={'travel'}>Szállás, Vendéglátás</MenuItem>
                <MenuItem value={'startup'}>Start up</MenuItem>
                <MenuItem value={'logistics'}>Szállítás, raktározás</MenuItem>
                <MenuItem value={'courier'}>Futár</MenuItem>
                <MenuItem value={'beauty'}>Szépségipar</MenuItem>
                <MenuItem value={'otherservices'}>Egyéb</MenuItem>
                <MenuItem value={'lawyer'}>Ügyvédi iroda</MenuItem>
                <MenuItem value={'sport'}>Sport</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Cégforma: </span>
              <Select
                id="companytype"
                name="companytype"
                onChange={singleValueHandler}
                value={tempData.companytype}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={'singleprimary'}>KATA</MenuItem>
                <MenuItem value={'kiva'}>EV átalány fő</MenuItem>
                <MenuItem value={'singlesecondary'}>EV átalány mellék</MenuItem>
                <MenuItem value={'vszja'}>EV VSZJA</MenuItem>
                <MenuItem value={'bt'}>Bt</MenuItem>
                <MenuItem value={'kft'}>Kft</MenuItem>
                <MenuItem value={'zrt'}>Zrt</MenuItem>
                <MenuItem value={'kkt'}>Kkt</MenuItem>
                <MenuItem value={'nyrt'}>Nyrt</MenuItem>
                <MenuItem value={'condo'}>Társasház</MenuItem>
                <MenuItem value={'citycouncil'}>Önkormányzat</MenuItem>
                <MenuItem value={'civil'}>Civil szervezet</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Adó forma: </span>
              <Select
                id="taxform"
                name="taxform"
                onChange={singleValueHandler}
                value={tempData.taxform}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={'kiva'}>Kiva</MenuItem>
                <MenuItem value={'tao'}>Tao</MenuItem>
                <MenuItem value={'idk'}>Nem tudja</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Áfa alanyiság: </span>
              <Select
                id="vatFilter"
                name="vatFilter"
                onChange={singleValueHandler}
                value={tempData.vatFilter}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={true}>Igen</MenuItem>
                <MenuItem value={false}>Nem</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Áfa gyakoriság: </span>
              <Select
                id="vatPayment"
                name="vatPayment"
                onChange={singleValueHandler}
                value={tempData.vatPayment}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={'monthly'}>Havi</MenuItem>
                <MenuItem value={'quarterly'}>Negyedéves</MenuItem>
                <MenuItem value={'yearly'}>Éves</MenuItem>
                <MenuItem value={'idk'}>Nem tudja</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Nyelvek: </span>
              <Select
                variant="standard"
                onChange={languageChange}
                multiple
                value={languagesPicked}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: '300px' }}
                sx={{ fontSize: 14 }}
              >
                {languages.map((language) => (
                  <MenuItem key={language} value={language}>
                    <Checkbox checked={languagesPicked.indexOf(language) > -1} />
                    <ListItemText primary={language} />
                  </MenuItem>
                ))}
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Külkereskedelem: </span>
              <Select
                id="foreign_trade"
                name="foreign_trade"
                onChange={singleValueHandler}
                value={tempData.foreign_trade}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={true}>Igen</MenuItem>
                <MenuItem value={false}>Nem</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Külkereskedelem irány: </span>
              <Select
                id="foreigntradeEU"
                name="foreigntradeEU"
                onChange={singleValueHandler}
                value={tempData.foreigntradeEU}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={'inside'}>EU-n belül</MenuItem>
                <MenuItem value={'outside'}>EU-n kívűl</MenuItem>
                <MenuItem value={'both'}>Mindkettő</MenuItem>
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Preferenciák (0-nem fontos, 3-fontos, 5-nagyon fontos):{' '}
              </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="preferences"
                name="preferences"
                onChange={singleValueHandler}
                style={{ width: '300px' }}
                variant="standard"
                value={tempData.preferences}
                sx={{ fontSize: 14 }}
                multiline
                maxRows={4}
              />
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Bank preferenciák: </span>
              <Select
                variant="standard"
                onChange={setBankData}
                multiple
                value={chosenBank}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: '300px' }}
                sx={{ fontSize: 14 }}
              >
                {bankPref.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    <Checkbox checked={chosenBank.indexOf(opt.value) > -1} />
                    <ListItemText primary={opt.label} />
                  </MenuItem>
                ))}
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Számlázó preferenciák: </span>
              <Select
                variant="standard"
                onChange={setInvoData}
                multiple
                value={chosenInvo}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: '300px' }}
                sx={{ fontSize: 14 }}
              >
                {invoPref.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    <Checkbox checked={chosenInvo.indexOf(opt.value) > -1} />
                    <ListItemText primary={opt.label} />
                  </MenuItem>
                ))}
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Könyvelő program preferenciák(ha NEM EV!):{' '}
              </span>
              <Select
                variant="standard"
                onChange={setSoftwareData}
                multiple
                value={chosenSoft}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: '300px' }}
                sx={{ fontSize: 14 }}
              >
                {softPref.map((opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    <Checkbox checked={chosenSoft.indexOf(opt.value) > -1} />
                    <ListItemText primary={opt.label} />
                  </MenuItem>
                ))}
              </Select>
            </p>
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Dolgoznál ezzel az ügyféllel:{' '}
              </span>
              <Select
                id="wouldProviderTakeit"
                name="wouldProviderTakeit"
                onChange={singleValueHandler}
                value={tempData.wouldProviderTakeit}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value={true}>Igen</MenuItem>
                <MenuItem value={false}>Nem</MenuItem>
              </Select>
            </p>

            {/* GENERAL */}

            <br />

            <p>
              <strong>Iparágspecifikus adatok</strong>
            </p>

            {/* SIMPLIFIED PAYROLL */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Vannak EFO-s munkatársak:
              </span>
              <Select
                id="simplifiedpayrollstatus"
                name="simplifiedpayrollstatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    simplifiedpayroll: value,
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    simplifiedpayroll: value,
                  }));
                  setIsChanged(true);
                }}
                value={tempData.simplifiedpayroll || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="true">Igen</MenuItem>
                <MenuItem value="false">Nem</MenuItem>
                <MenuItem value="idk">Nem tudom</MenuItem>
              </Select>
            </p>

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                EFO-s munkatársak száma:
              </span>
              <TextField
                id="simplifiedemployeecount"
                name="simplifiedemployeecount"
                type="number"
                inputProps={{ min: '0' }}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  setTempObj((prevState) => ({
                    ...prevState,
                    simplifiedemployeecount: value,
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    simplifiedemployeecount: value,
                  }));
                  setIsChanged(true);
                }}
                value={tempData.simplifiedemployeecount || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              />
            </p>

            {/* REGISTER SIMPLIFIED PAYROLL */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                EFO-s munkatársak bejelentését az ügyfél végzi:
              </span>
              <Select
                id="registersimplifiedpayrollstatus"
                name="registersimplifiedpayrollstatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        registersimplifiedpayroll: {
                          ...((prevState.industryspecifics || {}).general || {}).registersimplifiedpayroll,
                          status: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        registersimplifiedpayroll: {
                          ...((prevState.industryspecifics || {}).general || {}).registersimplifiedpayroll,
                          status: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).registersimplifiedpayroll?.status || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="false">Igen</MenuItem>
                <MenuItem value="true">Nem</MenuItem>
              </Select>
            </p>

            {/* COMAPNYCAR */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Cégautóval rendelkezik:{' '}
              </span>
              <Select
                id="companycarstatus"
                name="companycarstatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        companycar: {
                          ...((prevState.industryspecifics || {}).general || {}).companycar,
                          status: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        companycar: {
                          ...((prevState.industryspecifics || {}).general || {}).companycar,
                          status: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).companycar?.status || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="true">Igen</MenuItem>
                <MenuItem value="false">Nem</MenuItem>
                <MenuItem value="idk">Nem tudom</MenuItem>
              </Select>
            </p>

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Cégautók száma: </span>
              <TextField
                id="companycarcount"
                name="companycarcount"
                type="number"
                inputProps={{ min: '0' }}
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        companycar: {
                          ...((prevState.industryspecifics || {}).general || {}).companycar,
                          carcount: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        companycar: {
                          ...((prevState.industryspecifics || {}).general || {}).companycar,
                          carcount: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).companycar?.carcount || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              />
            </p>

            {/* CASHREGISTER */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Pénztárgéppel rendelkezik:{' '}
              </span>
              <Select
                id="cashregisterstatus"
                name="cashregisterstatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        cashregister: {
                          ...((prevState.industryspecifics || {}).general || {}).cashregister,
                          status: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        cashregister: {
                          ...((prevState.industryspecifics || {}).general || {}).cashregister,
                          status: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).cashregister?.status || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="true">Igen</MenuItem>
                <MenuItem value="false">Nem</MenuItem>
                <MenuItem value="idk">Nem tudom</MenuItem>
              </Select>
            </p>

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Pénztárgépek száma: </span>
              <TextField
                id="cashregistercount"
                name="cashregistercount"
                type="number"
                inputProps={{ min: '0' }}
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        cashregister: {
                          ...((prevState.industryspecifics || {}).general || {}).cashregister,
                          carcount: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        cashregister: {
                          ...((prevState.industryspecifics || {}).general || {}).cashregister,
                          cashregistercount: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).cashregister?.cashregistercount || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              />
            </p>

            {/* MULTIPLEACCOUNTS */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Több bankszámlával rendelkezik:{' '}
              </span>
              <Select
                id="multipleaccountsstatus"
                name="multipleaccountsstatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        multipleaccounts: {
                          ...((prevState.industryspecifics || {}).general || {}).multipleaccounts,
                          status: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        multipleaccounts: {
                          ...((prevState.industryspecifics || {}).general || {}).multipleaccounts,
                          status: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).multipleaccounts?.status || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="true">Igen</MenuItem>
                <MenuItem value="false">Nem</MenuItem>
              </Select>
            </p>

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Bankszámlák száma: </span>
              <TextField
                id="multipleaccountscount"
                name="multipleaccountscount"
                type="number"
                inputProps={{ min: '0' }}
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        multipleaccounts: {
                          ...((prevState.industryspecifics || {}).general || {}).multipleaccounts,
                          accountcount: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        multipleaccounts: {
                          ...((prevState.industryspecifics || {}).general || {}).multipleaccounts,
                          accountcount: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).multipleaccounts?.accountcount || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              />
            </p>

            {/* CUSTOMERTYPE */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Vevők típusa: </span>
              <Select
                id="customertypeselectedoption"
                name="customertypeselectedoption"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        customertype: {
                          ...((prevState.industryspecifics || {}).general || {}).customertype,
                          selectedoption: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        customertype: {
                          ...((prevState.industryspecifics || {}).general || {}).customertype,
                          selectedoption: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).customertype?.selectedoption || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="private">Kizárólag magánszemélyek</MenuItem>
                <MenuItem value="mainlyprivate">Főként magánszemélyek, de van egy-két vállalkozás is</MenuItem>
                <MenuItem value="mixed">Magánszemélyek és vállalkozások vegyesen</MenuItem>
                <MenuItem value="business">Kizárólag vállalkozások</MenuItem>
                <MenuItem value="idk">Nem tudom</MenuItem>
              </Select>
            </p>

            <br />

            {/* LOAN REVIEW */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>Hitel(ek) száma: </span>
              <TextField
                id="loanreviewcount"
                name="loanreviewcount"
                type="number"
                inputProps={{ min: '0' }}
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        loanreview: {
                          ...((prevState.industryspecifics || {}).general || {}).loanreview,
                          reviewcount: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        loanreview: {
                          ...((prevState.industryspecifics || {}).general || {}).loanreview,
                          reviewcount: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).loanreview?.reviewcount || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
                onWheel={(e) => e.target.blur()}
              />
            </p>

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Hitelfelülvizsgálatok gyakorisága:{' '}
              </span>
              <Select
                id="loanreviewfrequency"
                name="loanreviewfrequency"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        loanreview: {
                          ...((prevState.industryspecifics || {}).general || {}).loanreview,
                          reviewfrequency: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        loanreview: {
                          ...((prevState.industryspecifics || {}).general || {}).loanreview,
                          reviewfrequency: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).loanreview?.reviewfrequency || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="monthly">Havi</MenuItem>
                <MenuItem value="quarterly">Negyedéves</MenuItem>
                <MenuItem value="semiannually">Féléves</MenuItem>
                <MenuItem value="annually">Éves</MenuItem>
                <MenuItem value="idk">Nem tudom</MenuItem>
              </Select>
            </p>

            {/* CASHFLOW VAT */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Pénzforgalmi ÁFÁs vállalkozás:{' '}
              </span>
              <Select
                id="cashflowvatstatus"
                name="cashflowvatstatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        cashflowvat: {
                          ...((prevState.industryspecifics || {}).general || {}).cashflowvat,
                          status: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        cashflowvat: {
                          ...((prevState.industryspecifics || {}).general || {}).cashflowvat,
                          status: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).cashflowvat?.status || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="true">Pénzforgalmi ÁFÁs</MenuItem>
                <MenuItem value="false">Nem pénzforgalmi ÁFÁs</MenuItem>
                <MenuItem value="idk">Nem tudom</MenuItem>
              </Select>
            </p>

            {/* EARLY CLOSURE */}
            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Szükség van évközi/előzetes zárásra:{' '}
              </span>
              <Select
                id="earlyclosurestatus"
                name="earlyclosurestatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        earlyclosure: {
                          ...((prevState.industryspecifics || {}).general || {}).earlyclosure,
                          status: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        earlyclosure: {
                          ...((prevState.industryspecifics || {}).general || {}).earlyclosure,
                          status: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).earlyclosure?.status || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="true">Igen</MenuItem>
                <MenuItem value="false">Nem</MenuItem>
              </Select>
            </p>

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Évközi/előzetes zárási gyakoriság:{' '}
              </span>
              <Select
                id="earlyclosurefrequency"
                name="earlyclosurefrequency"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        earlyclosure: {
                          ...((prevState.industryspecifics || {}).general || {}).earlyclosure,
                          earlyclosurefrequency: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        earlyclosure: {
                          ...((prevState.industryspecifics || {}).general || {}).earlyclosure,
                          earlyclosurefrequency: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).earlyclosure?.earlyclosurefrequency || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="monthly">Havonta</MenuItem>
                <MenuItem value="quarterly">Negyedévente</MenuItem>
                <MenuItem value="semiannually">Félévente</MenuItem>
              </Select>
            </p>

            {/* PREPARING STATEMENTS */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Statisztikák, kimutatások készítését kéri (havi 2 könyvelői óra ráfordítás):
              </span>
              <Select
                id="preparingstatementsstatus"
                name="preparingstatementsstatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        preparingstatements: {
                          ...((prevState.industryspecifics || {}).general || {}).preparingstatements,
                          status: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        preparingstatements: {
                          ...((prevState.industryspecifics || {}).general || {}).preparingstatements,
                          status: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).preparingstatements?.status || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="true">Igen</MenuItem>
                <MenuItem value="false">Nem</MenuItem>
              </Select>
            </p>

            {/* INVOICING */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                A könyvelő számláz az ügyvezető/vállalkozó helyett:
              </span>
              <Select
                id="invoicingstatus"
                name="invoicingstatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        invoicing: {
                          ...((prevState.industryspecifics || {}).general || {}).invoicing,
                          status: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        invoicing: {
                          ...((prevState.industryspecifics || {}).general || {}).invoicing,
                          status: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).invoicing?.status || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="true">Igen</MenuItem>
                <MenuItem value="false">Nem</MenuItem>
              </Select>
            </p>

            {/* TRANSFERING */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                A könyvelő indít el banki utalásokat az ügyvezető/vállalkozó helyett:
              </span>
              <Select
                id="transferingstatus"
                name="transferingstatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        transfering: {
                          ...((prevState.industryspecifics || {}).general || {}).transfering,
                          status: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        transfering: {
                          ...((prevState.industryspecifics || {}).general || {}).transfering,
                          status: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).transfering?.status || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="true">Igen</MenuItem>
                <MenuItem value="false">Nem</MenuItem>
              </Select>
            </p>

            {/* AUDIT COOPERATION */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Könyvvizsgálatra kötelezett a vállalkozás:{' '}
              </span>
              <Select
                id="auditcooperationstatus"
                name="auditcooperationstatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        auditcooperation: {
                          ...((prevState.industryspecifics || {}).general || {}).auditcooperation,
                          status: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        auditcooperation: {
                          ...((prevState.industryspecifics || {}).general || {}).auditcooperation,
                          status: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).auditcooperation?.status || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="true">Igen</MenuItem>
                <MenuItem value="false">Nem</MenuItem>
                <MenuItem value="idk">Nem tudom</MenuItem>
              </Select>
            </p>

            {/* VAT ADJUSTING */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Van olyan bevétele, ami ÁFA körön kívüli:{' '}
              </span>
              <Select
                id="vatadjustingstatus"
                name="vatadjustingstatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        vatadjusting: {
                          ...((prevState.industryspecifics || {}).general || {}).vatadjusting,
                          status: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        vatadjusting: {
                          ...((prevState.industryspecifics || {}).general || {}).vatadjusting,
                          status: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).vatadjusting?.status || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="true">Igen</MenuItem>
                <MenuItem value="false">Nem</MenuItem>
              </Select>
            </p>

            {/* PROJECT SUPPORT */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Van projekt vagy költséghely szintű számviteli nyilvántartása:{' '}
              </span>
              <Select
                id="projectsupportstatus"
                name="projectsupportstatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        projectsupport: {
                          ...((prevState.industryspecifics || {}).general || {}).projectsupport,
                          status: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        projectsupport: {
                          ...((prevState.industryspecifics || {}).general || {}).projectsupport,
                          status: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).projectsupport?.status || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="true">Igen</MenuItem>
                <MenuItem value="false">Nem</MenuItem>
              </Select>
            </p>

            {/* ENVIRONMENTAL FEES */}

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                Környezetvédelmi termékdíjjal kapcsolatos bevallásokat kell készíteni:
              </span>
              <Select
                id="environmentalfeesstatus"
                name="environmentalfeesstatus"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        environmentalfees: {
                          ...((prevState.industryspecifics || {}).general || {}).environmentalfees,
                          status: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        environmentalfees: {
                          ...((prevState.industryspecifics || {}).general || {}).environmentalfees,
                          status: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).environmentalfees?.status || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="true">Igen</MenuItem>
                <MenuItem value="false">Nem</MenuItem>
                <MenuItem value="idk">Nem tudom</MenuItem>
              </Select>
            </p>

            <p>
              <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                A környezetvédelmi termékdíj adminisztrációját ki végzi:{' '}
              </span>
              <Select
                id="environmentalfeeshandlingenvfees"
                name="environmentalfeeshandlingenvfees"
                onChange={(e) => {
                  const value = e.target.value;
                  setTempObj((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        environmentalfees: {
                          ...((prevState.industryspecifics || {}).general || {}).environmentalfees,
                          handlingenvfees: value,
                        },
                      },
                    },
                  }));
                  setTempData((prevState) => ({
                    ...prevState,
                    industryspecifics: {
                      ...prevState.industryspecifics,
                      general: {
                        ...((prevState.industryspecifics || {}).general || {}),
                        environmentalfees: {
                          ...((prevState.industryspecifics || {}).general || {}).environmentalfees,
                          handlingenvfees: value,
                        },
                      },
                    },
                  }));
                  setIsChanged(true);
                }}
                value={((tempData?.industryspecifics || {}).general || {}).environmentalfees?.handlingenvfees || ''}
                style={{ width: '300px' }}
                variant="standard"
                sx={{ fontSize: 14 }}
              >
                <MenuItem value="accountant">A könyvelőtől várom</MenuItem>
                <MenuItem value="selfmanaged">Magam végzem el</MenuItem>
              </Select>
            </p>

            {renderEtradeIndustryContent && (
              <>
                <br />
                <p>
                  <strong>E-Kereskedelem</strong>
                </p>
                {/* ETRADE SELLS */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Amit a webshop értékesít:
                  </span>
                  <Select
                    variant="standard"
                    onChange={handleEtradeSellsChange}
                    multiple
                    value={selectedEtradeSells}
                    renderValue={(selected) =>
                      selected.map((value) => etradeSellsOptions.find((opt) => opt.value === value)?.label).join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {etradeSellsOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedEtradeSells.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}
                    {selectedEtradeSells.includes('other') && (
                      <MenuItem disableGutters>
                        <TextField
                          value={tempData?.industryspecifics?.etrade?.etradesells?.otherinput || ''}
                          onChange={handleEtradeSellsOtherInputChange}
                          variant="standard"
                          placeholder="Mivel foglalkozik az üzlet"
                          fullWidth
                        />
                      </MenuItem>
                    )}
                  </Select>
                </p>
                {/* ETRADE COURIER */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Melyik futárcégekkel dolgozik együtt:
                  </span>
                  <Select
                    multiple
                    variant="standard"
                    value={selectedEtradeCourier}
                    onChange={handleEtradeCourierSelectChange}
                    renderValue={(selected) =>
                      selected.map((value) => etradeCourierOptions.find((opt) => opt.value === value)?.label).join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {etradeCourierOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedEtradeCourier.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </p>
                {/* ETRADE DROPOFF */}
                <p className="SFIndustrySpecSubTitle">
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Melyik csomaglerakatorka szállít:
                  </span>
                  <Select
                    multiple
                    variant="standard"
                    value={selectedEtradeDropoff}
                    onChange={handleEtradeDropoffSelectChange}
                    renderValue={(selected) =>
                      selected.map((value) => etradeDropoffOptions.find((opt) => opt.value === value)?.label).join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {etradeDropoffOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedEtradeDropoff.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </p>
                {/* ETRADE PAYMENT METHODS */}
                <p className="SFIndustrySpecSubTitle">
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Milyen fizetési módokat fogad el:
                  </span>
                  <Select
                    multiple
                    variant="standard"
                    value={selectedEtradePayment}
                    onChange={handleEtradePaymentSelectChange}
                    renderValue={(selected) =>
                      selected.map((value) => etradePaymentOptions.find((opt) => opt.value === value)?.label).join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {etradePaymentOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedEtradePayment.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </p>
                {/* ETRADE MATCHINGINVOICES */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Összepontozás módja:{' '}
                  </span>
                  <Select
                    id="matchinginvoicesmatchingmethod"
                    name="matchinginvoicesmatchingmethod"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          etrade: {
                            ...((prevState.industryspecifics || {}).etrade || {}),
                            matchinginvoices: {
                              ...((prevState.industryspecifics || {}).etrade || {}).matchinginvoices,
                              matchingmethod: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          etrade: {
                            ...((prevState.industryspecifics || {}).etrade || {}),
                            matchinginvoices: {
                              ...((prevState.industryspecifics || {}).etrade || {}).matchinginvoices,
                              matchingmethod: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={((tempData?.industryspecifics || {}).etrade || {}).matchinginvoices?.matchingmethod || ''}
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="false">Én végzem az összepárosítást</MenuItem>
                    <MenuItem value="true">A könyvelő segítségét kérem ebben</MenuItem>
                    <MenuItem value="idk">Nem tudom</MenuItem>
                  </Select>
                </p>
                {/* ETRADE DEADLINECOMPLIANCE */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Vállalja, hogy az összepárosításhoz betartja a határidőket:
                  </span>
                  <Select
                    id="deadlinecompliancestatus"
                    name="deadlinecompliancestatus"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          etrade: {
                            ...((prevState.industryspecifics || {}).etrade || {}),
                            deadlinecompliance: {
                              ...((prevState.industryspecifics || {}).etrade || {}).deadlinecompliance,
                              status: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          etrade: {
                            ...((prevState.industryspecifics || {}).etrade || {}),
                            deadlinecompliance: {
                              ...((prevState.industryspecifics || {}).etrade || {}).deadlinecompliance,
                              status: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={((tempData?.industryspecifics || {}).etrade || {}).deadlinecompliance?.status || ''}
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="true">Igen</MenuItem>
                    <MenuItem value="false">Nem</MenuItem>
                  </Select>
                </p>
                {/* ETRADE ACTUALSTORE */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Van fizikai értékesítési helye:{' '}
                  </span>
                  <Select
                    id="actualstorestatus"
                    name="actualstorestatus"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          etrade: {
                            ...((prevState.industryspecifics || {}).etrade || {}),
                            actualstore: {
                              ...((prevState.industryspecifics || {}).etrade || {}).actualstore,
                              status: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          etrade: {
                            ...((prevState.industryspecifics || {}).etrade || {}),
                            actualstore: {
                              ...((prevState.industryspecifics || {}).etrade || {}).actualstore,
                              status: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={((tempData?.industryspecifics || {}).etrade || {}).actualstore?.status || ''}
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="true">Igen</MenuItem>
                    <MenuItem value="false">Nem</MenuItem>
                  </Select>
                </p>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Fizikai értékesítési helyek száma:{' '}
                  </span>
                  <TextField
                    id="actualstorecount"
                    name="actualstorecount"
                    type="number"
                    inputProps={{ min: '0' }}
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          etrade: {
                            ...((prevState.industryspecifics || {}).etrade || {}),
                            actualstore: {
                              ...((prevState.industryspecifics || {}).etrade || {}).actualstore,
                              actualstorecount: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          etrade: {
                            ...((prevState.industryspecifics || {}).etrade || {}),
                            actualstore: {
                              ...((prevState.industryspecifics || {}).etrade || {}).actualstore,
                              actualstorecount: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={((tempData?.industryspecifics || {}).etrade || {}).actualstore?.actualstorecount || ''}
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  />
                </p>
                {/* ETRADE STOCKHANDLING */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Csak megrendelésre rendel/gyárt terméket vagy árukészletet is tart:
                  </span>
                  <Select
                    id="stockhandlingstatus"
                    name="stockhandlingstatus"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          etrade: {
                            ...((prevState.industryspecifics || {}).etrade || {}),
                            stockhandling: {
                              ...((prevState.industryspecifics || {}).etrade || {}).stockhandling,
                              status: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          etrade: {
                            ...((prevState.industryspecifics || {}).etrade || {}),
                            stockhandling: {
                              ...((prevState.industryspecifics || {}).etrade || {}).stockhandling,
                              status: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={((tempData?.industryspecifics || {}).etrade || {}).stockhandling?.status || ''}
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="false">Csak megrendelésre</MenuItem>
                    <MenuItem value="true">Árukészletet is tartok</MenuItem>
                  </Select>
                </p>
              </>
            )}

            {renderRetailIndustryContent && (
              <>
                <br />
                <p>
                  <strong>Kiskereskedelem</strong>
                </p>
                {/* RETAIL PAYMENT METHODS */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Milyen fizetési módokat fogad el:
                  </span>
                  <Select
                    multiple
                    variant="standard"
                    value={selectedRetailPayment}
                    onChange={handleRetailPaymentSelectChange}
                    renderValue={(selected) =>
                      selected.map((value) => retailPaymentOptions.find((opt) => opt.value === value)?.label).join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {retailPaymentOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedRetailPayment.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </p>
                {/* RETAIL MATCHINGINVOICES */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Összepontozás módja:
                  </span>
                  <Select
                    id="matchinginvoicesretailmatchingmethod"
                    name="matchinginvoicesretailmatchingmethod"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          retail: {
                            ...((prevState.industryspecifics || {}).retail || {}),
                            matchinginvoicesretail: {
                              ...((prevState.industryspecifics || {}).retail || {}).matchinginvoicesretail,
                              matchingmethod: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          retail: {
                            ...((prevState.industryspecifics || {}).retail || {}),
                            matchinginvoicesretail: {
                              ...((prevState.industryspecifics || {}).retail || {}).matchinginvoicesretail,
                              matchingmethod: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={
                      ((tempData?.industryspecifics || {}).retail || {}).matchinginvoicesretail?.matchingmethod || ''
                    }
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="false">Én végzem az összepárosítást</MenuItem>
                    <MenuItem value="true">A könyvelő segítségét kérem ebben</MenuItem>
                    <MenuItem value="idk">Nem tudom</MenuItem>
                  </Select>
                </p>
                {/* RETAIL DEADLINECOMPLIANCE */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Vállalja, hogy az összepárosításhoz betartja a határidőket:
                  </span>
                  <Select
                    id="deadlinecomplianceretailstatus"
                    name="deadlinecomplianceretailstatus"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          retail: {
                            ...((prevState.industryspecifics || {}).retail || {}),
                            deadlinecomplianceretail: {
                              ...((prevState.industryspecifics || {}).retail || {}).deadlinecomplianceretail,
                              status: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          retail: {
                            ...((prevState.industryspecifics || {}).retail || {}),
                            deadlinecomplianceretail: {
                              ...((prevState.industryspecifics || {}).retail || {}).deadlinecomplianceretail,
                              status: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={((tempData?.industryspecifics || {}).retail || {}).deadlinecomplianceretail?.status || ''}
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="true">Igen</MenuItem>
                    <MenuItem value="false">Nem</MenuItem>
                  </Select>
                </p>
              </>
            )}

            {renderWholesaleIndustryContent && (
              <>
                <br />
                <p>
                  <strong>Nagykereskedelem</strong>
                </p>
                {/* WHOLESALE PAYMENT METHODS */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Milyen fizetési módokat fogad el:
                  </span>
                  <Select
                    multiple
                    variant="standard"
                    value={selectedWholesalePayment}
                    onChange={handleWholesalePaymentSelectChange}
                    renderValue={(selected) =>
                      selected
                        .map((value) => wholesalePaymentOptions.find((opt) => opt.value === value)?.label)
                        .join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {wholesalePaymentOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedWholesalePayment.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </p>
                {/* WHOLESALE MATCHINGINVOICES */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Összepontozás módja:{' '}
                  </span>
                  <Select
                    id="matchinginvoiceswholesalematchingmethod"
                    name="matchinginvoiceswholesalematchingmethod"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          wholesale: {
                            ...((prevState.industryspecifics || {}).wholesale || {}),
                            matchinginvoiceswholesale: {
                              ...((prevState.industryspecifics || {}).wholesale || {}).matchinginvoiceswholesale,
                              matchingmethod: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          wholesale: {
                            ...((prevState.industryspecifics || {}).wholesale || {}),
                            matchinginvoiceswholesale: {
                              ...((prevState.industryspecifics || {}).wholesale || {}).matchinginvoiceswholesale,
                              matchingmethod: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={
                      ((tempData?.industryspecifics || {}).wholesale || {}).matchinginvoiceswholesale?.matchingmethod ||
                      ''
                    }
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="false">Én végzem az összepárosítást</MenuItem>
                    <MenuItem value="true">A könyvelő segítségét kérem ebben</MenuItem>
                    <MenuItem value="idk">Nem tudom</MenuItem>
                  </Select>
                </p>
                {/* WHOLESALE DEADLINECOMPLIANCE */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Vállalja, hogy az összepárosításhoz betartja a határidőket:
                  </span>
                  <Select
                    id="deadlinecompliancewholesalestatus"
                    name="deadlinecompliancewholesalestatus"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          wholesale: {
                            ...((prevState.industryspecifics || {}).wholesale || {}),
                            deadlinecompliancewholesale: {
                              ...((prevState.industryspecifics || {}).wholesale || {}).deadlinecompliancewholesale,
                              status: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          wholesale: {
                            ...((prevState.industryspecifics || {}).wholesale || {}),
                            deadlinecompliancewholesale: {
                              ...((prevState.industryspecifics || {}).wholesale || {}).deadlinecompliancewholesale,
                              status: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={
                      ((tempData?.industryspecifics || {}).wholesale || {}).deadlinecompliancewholesale?.status || ''
                    }
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="true">Igen</MenuItem>
                    <MenuItem value="false">Nem</MenuItem>
                  </Select>
                </p>
              </>
            )}

            {renderVehicleIndustryContent && (
              <>
                <br />
                <p>
                  <strong>Gépjárműipar</strong>
                </p>
                {/* VEHICLE INDUSTRY */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Gépjárműipari tevékenység jellege:
                  </span>
                  <Select
                    multiple
                    variant="standard"
                    value={selectedVehicleOptions}
                    onChange={handleVehicleSelectChange}
                    renderValue={(selected) =>
                      selected.map((value) => vehicleOptions.find((opt) => opt.value === value)?.label).join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {vehicleOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedVehicleOptions.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}
                    {selectedVehicleOptions.includes('other') && (
                      <MenuItem disableGutters>
                        <TextField
                          value={tempData?.industryspecifics?.vehicle?.differentialvat?.otherinput || ''}
                          onChange={handleVehicleOtherInputChange}
                          variant="standard"
                          placeholder="Gépjárműipari tevékenység jellege"
                          fullWidth
                        />
                      </MenuItem>
                    )}
                  </Select>
                </p>
              </>
            )}

            {renderConstructionIndustryContent && (
              <>
                <br />
                <p>
                  <strong>Építőipar</strong>
                </p>
                {/* CONSTRUCTION INDUSTRY */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Építőipari tevékenység jellege:
                  </span>
                  <Select
                    multiple
                    variant="standard"
                    value={selectedConstructionOptions}
                    onChange={handleConstructionSelectChange}
                    renderValue={(selected) =>
                      selected.map((value) => constructionOptions.find((opt) => opt.value === value)?.label).join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {constructionOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedConstructionOptions.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}
                    {selectedConstructionOptions.includes('other') && (
                      <MenuItem disableGutters>
                        <TextField
                          value={tempData?.industryspecifics?.construction?.constructiontype?.otherinput || ''}
                          onChange={handleConstructionOtherInputChange}
                          variant="standard"
                          placeholder="Építőipari tevékenység jellege"
                          fullWidth
                        />
                      </MenuItem>
                    )}
                  </Select>
                </p>
              </>
            )}

            {renderAgricultureIndustryContent && (
              <>
                <br />
                <p>
                  <strong>Mező- és erdőgazdálkodás, halászat</strong>
                </p>
                {/* AGRICULTURE INDUSTRY */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Mezőgazdasági tevékenység jellege:
                  </span>
                  <Select
                    multiple
                    variant="standard"
                    value={selectedAgricultureOptions}
                    onChange={handleAgricultureSelectChange}
                    renderValue={(selected) =>
                      selected.map((value) => agricultureOptions.find((opt) => opt.value === value)?.label).join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {agricultureOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedAgricultureOptions.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}
                    <MenuItem disableGutters>
                      <TextField
                        value={tempData?.industryspecifics?.agriculture?.agriculturetype?.otherinput || ''}
                        onChange={handleAgricultureOtherInputChange}
                        variant="standard"
                        placeholder="Mezőgazdasági tevékenység jellege"
                        fullWidth
                      />
                    </MenuItem>
                  </Select>
                </p>
              </>
            )}

            {renderITIndustryContent && (
              <>
                <br />
                <p>
                  <strong>Informatika</strong>
                </p>
                {/* IT INDUSTRY */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    IT tevékenység jellege:
                  </span>
                  <Select
                    multiple
                    variant="standard"
                    value={selectedITOptions}
                    onChange={handleITSelectChange}
                    renderValue={(selected) =>
                      selected.map((value) => ITOptions.find((opt) => opt.value === value)?.label).join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {ITOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedITOptions.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}

                    {selectedITOptions.includes('other') && (
                      <MenuItem disableGutters>
                        <TextField
                          value={tempData?.industryspecifics?.IT?.ittype?.otherinput || ''}
                          onChange={handleITOtherInputChange}
                          variant="standard"
                          placeholder="Informatikai tevékenység jellege"
                          fullWidth
                        />
                      </MenuItem>
                    )}
                  </Select>
                </p>
              </>
            )}

            {renderArtIndustryContent && (
              <>
                <br />
                <p>
                  <strong>Művészet</strong>
                </p>
                {/* ART INDUSTRY */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Művészeti tevékenység jellege:
                  </span>
                  <Select
                    multiple
                    variant="standard"
                    value={selectedArtOptions}
                    onChange={handleArtSelectChange}
                    renderValue={(selected) =>
                      selected.map((value) => artOptions.find((opt) => opt.value === value)?.label).join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {artOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedArtOptions.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}
                    <MenuItem disableGutters>
                      <TextField
                        value={tempData?.industryspecifics?.art?.arttype?.otherinput || ''}
                        onChange={handleArtOtherInputChange}
                        variant="standard"
                        placeholder="Művészeti tevékenység jellege"
                        fullWidth
                      />
                    </MenuItem>
                  </Select>
                </p>
              </>
            )}

            {renderBeautyIndustryContent && (
              <>
                <br />
                <p>
                  <strong>Szépségipar</strong>
                </p>
                {/* BEAUTY INDUSTRY */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Szépségipari termékeket is értékesít:
                  </span>
                  <Select
                    id="beautyproductsalesstatus"
                    name="beautyproductsalesstatus"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          beauty: {
                            ...((prevState.industryspecifics || {}).beauty || {}),
                            beautyproductsales: {
                              ...((prevState.industryspecifics || {}).beauty || {}).beautyproductsales,
                              status: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          beauty: {
                            ...((prevState.industryspecifics || {}).beauty || {}),
                            beautyproductsales: {
                              ...((prevState.industryspecifics || {}).beauty || {}).beautyproductsales,
                              status: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={((tempData?.industryspecifics || {}).beauty || {}).beautyproductsales?.status || ''}
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="yes">Igen</MenuItem>
                    <MenuItem value="no">Nem</MenuItem>
                  </Select>
                </p>
              </>
            )}

            {renderMedicineIndustryContent && (
              <>
                <br />
                <p>
                  <strong>Egészségügy</strong>
                </p>
                {/* MEDICINE TYPE */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Egészségügyi tevékenység jellege:
                  </span>
                  <Select
                    multiple
                    variant="standard"
                    value={selectedMedicineOptions}
                    onChange={handleMedicineSelectChange}
                    renderValue={(selected) =>
                      selected.map((value) => medicineOptions.find((opt) => opt.value === value)?.label).join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {medicineOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedMedicineOptions.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}
                    <MenuItem disableGutters>
                      <TextField
                        value={tempData?.industryspecifics?.medicine?.medicinetype?.otherinput || ''}
                        onChange={handleMedicineOtherInputChange}
                        variant="standard"
                        placeholder="Egészségügyi tevékenység jellege"
                        fullWidth
                      />
                    </MenuItem>
                  </Select>
                </p>
                {/* MEDICINE PAYMENT METHODS */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Milyen fizetési módokat fogad el:
                  </span>
                  <Select
                    multiple
                    variant="standard"
                    value={selectedMedicinePayment}
                    onChange={handleMedicinePaymentSelectChange}
                    renderValue={(selected) =>
                      selected
                        .map((value) => medicinePaymentOptions.find((opt) => opt.value === value)?.label)
                        .join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {medicinePaymentOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedMedicinePayment.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </p>
              </>
            )}

            {renderTravelIndustryContent && (
              <>
                <br />
                <p>
                  <strong>Szállás, vendéglátás</strong>
                </p>
                {/* TRAVEL SEASONAL UNITS */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Vannak szezonálisan üzemelő egységei:
                  </span>
                  <Select
                    id="seasonalunitsstatus"
                    name="seasonalunitsstatus"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          travel: {
                            ...((prevState.industryspecifics || {}).travel || {}),
                            seasonalunits: {
                              ...((prevState.industryspecifics || {}).travel || {}).seasonalunits,
                              status: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          travel: {
                            ...((prevState.industryspecifics || {}).travel || {}),
                            seasonalunits: {
                              ...((prevState.industryspecifics || {}).travel || {}).seasonalunits,
                              status: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={((tempData?.industryspecifics || {}).travel || {}).seasonalunits?.status || ''}
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="yes">Igen</MenuItem>
                    <MenuItem value="no">Nem</MenuItem>
                  </Select>
                </p>
                {/* TRAVEL PAYMENT METHODS */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Milyen fizetési módokat fogad el:
                  </span>
                  <Select
                    multiple
                    variant="standard"
                    value={selectedTravelPayment}
                    onChange={handleTravelPaymentSelectChange}
                    renderValue={(selected) =>
                      selected.map((value) => travelPaymentOptions.find((opt) => opt.value === value)?.label).join(', ')
                    }
                    style={{ width: '300px' }}
                    sx={{ fontSize: 14 }}
                  >
                    {travelPaymentOptions.map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        <Checkbox checked={selectedTravelPayment.includes(opt.value)} />
                        <ListItemText primary={opt.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </p>
                {/* TRAVEL MATCHINGINVOICES */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Összepontozás módja:{' '}
                  </span>
                  <Select
                    id="matchinginvoicestravelmatchingmethod"
                    name="matchinginvoicestravelmatchingmethod"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          travel: {
                            ...((prevState.industryspecifics || {}).travel || {}),
                            matchinginvoicestravel: {
                              ...((prevState.industryspecifics || {}).travel || {}).matchinginvoicestravel,
                              matchingmethod: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          travel: {
                            ...((prevState.industryspecifics || {}).travel || {}),
                            matchinginvoicestravel: {
                              ...((prevState.industryspecifics || {}).travel || {}).matchinginvoicestravel,
                              matchingmethod: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={
                      ((tempData?.industryspecifics || {}).travel || {}).matchinginvoicestravel?.matchingmethod || ''
                    }
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="false">Én végzem az összepárosítást</MenuItem>
                    <MenuItem value="true">A könyvelő segítségét kérem ebben</MenuItem>
                    <MenuItem value="idk">Nem tudom</MenuItem>
                  </Select>
                </p>
                {/* TRAVEL DEADLINECOMPLIANCE */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Vállalja, hogy az összepárosításhoz betartja a határidőket:
                  </span>
                  <Select
                    id="deadlinecompliancetravelstatus"
                    name="deadlinecompliancetravelstatus"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          travel: {
                            ...((prevState.industryspecifics || {}).travel || {}),
                            deadlinecompliancetravel: {
                              ...((prevState.industryspecifics || {}).travel || {}).deadlinecompliancetravel,
                              status: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          travel: {
                            ...((prevState.industryspecifics || {}).travel || {}),
                            deadlinecompliancetravel: {
                              ...((prevState.industryspecifics || {}).travel || {}).deadlinecompliancetravel,
                              status: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={((tempData?.industryspecifics || {}).travel || {}).deadlinecompliancetravel?.status || ''}
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="true">Igen</MenuItem>
                    <MenuItem value="false">Nem</MenuItem>
                  </Select>
                </p>
              </>
            )}

            {renderFinanceIndustryContent && (
              <>
                <br />
                <p>
                  <strong>Pénzügy, biztosítás</strong>
                </p>
                {/* FINANCE INDUSTRY */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Van olyan felügyeleti ellenőrzés, amiben részt kell venni a könyvelőnek:
                  </span>
                  <Select
                    id="complianceauditreviewstatus"
                    name="complianceauditreviewstatus"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          finance: {
                            ...((prevState.industryspecifics || {}).finance || {}),
                            complianceauditreview: {
                              ...((prevState.industryspecifics || {}).finance || {}).complianceauditreview,
                              status: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          finance: {
                            ...((prevState.industryspecifics || {}).finance || {}),
                            complianceauditreview: {
                              ...((prevState.industryspecifics || {}).finance || {}).complianceauditreview,
                              status: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={((tempData?.industryspecifics || {}).finance || {}).complianceauditreview?.status || ''}
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="yes">Igen</MenuItem>
                    <MenuItem value="no">Nem</MenuItem>
                  </Select>
                </p>
              </>
            )}

            {renderCourierIndustryContent && (
              <>
                <br />
                <p>
                  <strong>Futár</strong>
                </p>
                {/* COURIER MATCHINGINVOICES */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Összepontozás módja:{' '}
                  </span>
                  <Select
                    id="matchinginvoicescouriermatchingmethod"
                    name="matchinginvoicescouriermatchingmethod"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          courier: {
                            ...((prevState.industryspecifics || {}).courier || {}),
                            matchinginvoicescourier: {
                              ...((prevState.industryspecifics || {}).courier || {}).matchinginvoicescourier,
                              matchingmethod: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          courier: {
                            ...((prevState.industryspecifics || {}).courier || {}),
                            matchinginvoicescourier: {
                              ...((prevState.industryspecifics || {}).courier || {}).matchinginvoicescourier,
                              matchingmethod: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={
                      ((tempData?.industryspecifics || {}).courier || {}).matchinginvoicescourier?.matchingmethod || ''
                    }
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="false">Én végzem az összepárosítást</MenuItem>
                    <MenuItem value="true">A könyvelő segítségét kérem ebben</MenuItem>
                    <MenuItem value="idk">Nem tudom</MenuItem>
                  </Select>
                </p>
                {/* COURIER DEADLINECOMPLIANCE */}
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '200px' }}>
                    Vállalja, hogy az összepárosításhoz betartja a határidőket:
                  </span>
                  <Select
                    id="deadlinecompliancecourierstatus"
                    name="deadlinecompliancecourierstatus"
                    onChange={(e) => {
                      const value = e.target.value;
                      setTempObj((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          courier: {
                            ...((prevState.industryspecifics || {}).courier || {}),
                            deadlinecompliancecourier: {
                              ...((prevState.industryspecifics || {}).courier || {}).deadlinecompliancecourier,
                              status: value,
                            },
                          },
                        },
                      }));
                      setTempData((prevState) => ({
                        ...prevState,
                        industryspecifics: {
                          ...prevState.industryspecifics,
                          courier: {
                            ...((prevState.industryspecifics || {}).courier || {}),
                            deadlinecompliancecourier: {
                              ...((prevState.industryspecifics || {}).courier || {}).deadlinecompliancecourier,
                              status: value,
                            },
                          },
                        },
                      }));
                      setIsChanged(true);
                    }}
                    value={((tempData?.industryspecifics || {}).courier || {}).deadlinecompliancecourier?.status || ''}
                    style={{ width: '300px' }}
                    variant="standard"
                    sx={{ fontSize: 14 }}
                  >
                    <MenuItem value="true">Igen</MenuItem>
                    <MenuItem value="false">Nem</MenuItem>
                  </Select>
                </p>
              </>
            )}

            <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-evenly' }}>
              <Button
                onClick={() => {
                  updateOffer(tempObj, matchScore);
                  setIsChanged(true);
                }}
              >
                Frissítés
              </Button>
              {isChanged ? (
                <>
                  {' '}
                  <Button
                    style={{ color: 'red', border: '1px solid red' }}
                    onClick={() => {
                      updateRequestData(statusID, tempObj);
                      setIsChanged(false);
                    }}
                  >
                    Mentés
                  </Button>
                </>
              ) : (
                <></>
              )}
              <Button
                onClick={() => {
                  sendMail(tempObj);
                }}
              >
                E-mail küldése
              </Button>
            </div>
          </div>
          <div className="validationChild2">
            {/* <div style={{ width: '250px' }}>
              <p style={{ fontWeight: '700', fontSize: '18px', color: '#2065D1' }}>Ennyi könyvelőt találtunk: </p>
              <p style={{ fontSize: '24px', margin: '0 auto' }}>
                {tempData.potential_providers.length !== 0 ? tempData.potential_providers.length : 0}
              </p>
            </div> */}
            <div className="StatusDetailsLabel">
              <Dropdown
                label="CAF Státusz"
                id="cafstatus"
                list={cafStatusList}
                selValue={tempData.reqstatus.cafstatus || ''}
                onChange={(value) => setTempStatus({ ...tempStatus, cafstatus: value })}
              />
              <Dropdown
                label="ASP Státusz"
                id="aspstatus"
                list={aspStatusList}
                selValue={tempData.reqstatus.aspstatus || ''}
                onChange={(value) => setTempStatus({ ...tempStatus, aspstatus: value })}
              />
              <Dropdown
                label="REG Státusz"
                id="herstatus"
                list={herStatusList}
                selValue={tempData.reqstatus.herstatus || ''}
                onChange={(value) => setTempStatus({ ...tempStatus, herstatus: value })}
              />
              <div style={{ width: '150px', padding: '16px' }}>
                <Button
                  onClick={() => updateStatusStatus(tempData._id, tempStatus)}
                  style={{ float: 'right' }}
                  variant="contained"
                >
                  Státusz mentése
                </Button>
              </div>
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Miért vált könyvelőt?</div>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="whychangingaccountant"
                name="whychangingaccountant"
                onChange={(e) => singleValueHandler(e)}
                style={{ width: '250px' }}
                variant="standard"
                value={tempObj.whychangingaccountant}
                sx={{ fontSize: 14 }}
                multiline
                maxRows={4}
              />
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Honnan hallot rólunk: </div>
              <Select
                id="howdidyoufindus"
                name="howdidyoufindus"
                onChange={singleValueHandler}
                value={tempData.howdidyoufindus}
                style={{ width: '250px' }}
                variant="standard"
              >
                <MenuItem value={'google'}>Google</MenuItem>
                <MenuItem value={'facebook'}>Facebook</MenuItem>
                <MenuItem value={'tiktok'}>Tik-tok</MenuItem>
                <MenuItem value={'friend'}>Ismerős</MenuItem>
                <MenuItem value={'bupa'}>Bupa</MenuItem>
                <MenuItem value={'szamlazzhu'}>Számlázz.hu</MenuItem>
                <MenuItem value={'freelancerblog'}>Freelancerblog</MenuItem>
                <MenuItem value={'glamour'}>Glamour</MenuItem>
                <MenuItem value={'otherweb'}>Más weboldal</MenuItem>
                <MenuItem value={'bookkeepieblog'}>Bookkeepie Blog</MenuItem>
                <MenuItem value={'other'}>Egyéb</MenuItem>
                <MenuItem value={'idk'}>Nem emlékszik</MenuItem>
              </Select>
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Honnan hallott - részletes</div>
              <TextField
                multiline
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="howdidyoufindusinput"
                name="howdidyoufindusinput"
                onChange={(e) => singleValueHandler(e)}
                style={{ width: '250px' }}
                variant="standard"
                value={tempObj.howdidyoufindusinput}
                sx={{ fontSize: 14 }}
                maxRows={4}
              />
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Illetékes ügyintéző: </div>
              <Select
                id="responsibleagent"
                name="responsibleagent"
                onChange={singleValueHandler}
                value={tempData.responsibleagent}
                style={{ width: '250px' }}
                variant="standard"
              >
                <MenuItem value={''}>Nem hozzárendelt</MenuItem>
                <MenuItem value={'Andi'}>Andi</MenuItem>
                <MenuItem value={'Nina'}>Nina</MenuItem>
                <MenuItem value={'Orsi'}>Orsi</MenuItem>
                <MenuItem value={'Zsófi'}>Zsófi</MenuItem>
                <MenuItem value={'Dia'}>Dia</MenuItem>
                <MenuItem value={'Andris'}>Andris</MenuItem>
              </Select>
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Hol keresett még:</div>
              <Select
                variant="standard"
                onChange={(event) => {
                  whereDidSearchChange(event);
                }}
                multiple
                value={placesSearched}
                renderValue={(selected) => selected.join(', ')}
                style={{ width: '250px' }}
              >
                {whereDidSearch.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    <Checkbox checked={placesSearched.indexOf(item.value) > -1} />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Mennyire volt nehéz?</div>
              <div style={{ width: '250px' }}>
                <Box style={{ width: '250px' }}>
                  <Slider
                    aria-label="Temperature"
                    defaultValue={parseInt(tempData.howHardNr) || 1}
                    getAriaValueText={(value) => `${value}`}
                    valueLabelDisplay="auto"
                    step={1}
                    marks={sliderMarks}
                    onChange={(e) => {
                      singleValueHandler(e);
                      //console.log(e.target.value, typeof e.target.value);
                    }}
                    min={1}
                    max={5}
                    id="howHardNr"
                    name="howHardNr"
                  />
                </Box>
              </div>
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Mennyire volt nehéz?</div>
              <TextField
                multiline
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="howHardWasIt"
                name="howHardWasIt"
                onChange={(e) => singleValueHandler(e)}
                style={{ width: '250px' }}
                variant="standard"
                value={tempObj.howHardWasIt}
                sx={{ fontSize: 14 }}
                maxRows={4}
              />
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Min lehetne javitani?</div>
              <TextField
                multiline
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="whatCanWeImprove"
                name="whatCanWeImprove"
                onChange={(e) => singleValueHandler(e)}
                style={{ width: '250px' }}
                variant="standard"
                value={tempObj.whatCanWeImprove}
                sx={{ fontSize: 14 }}
                maxRows={4}
              />
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Miért nem minket választott: </div>
              <Select
                id="whyNotUs"
                name="whyNotUs"
                onChange={singleValueHandler}
                value={tempData.whyNotUs}
                style={{ width: '250px' }}
                variant="standard"
              >
                <MenuItem value={'Ismerőse könyveli végül'}>Ismerőse könyveli végül</MenuItem>
                <MenuItem value={'Külföldi, nem vállalkozhat per pill'}>Külföldi, nem vállalkozhat per pill</MenuItem>
                <MenuItem value={'telefonszám probléma, nem értük el (emailen sem'}>
                  telefonszám probléma, nem értük el
                </MenuItem>
                <MenuItem value={'mégsem vált könyvelőt'}>mégsem vált könyvelőt</MenuItem>
                <MenuItem value={'magának megoldja végül'}>magának megoldja végül</MenuItem>
                <MenuItem value={'nincs könyvelőnk aki az ügyfél által kért programban könyvelne'}>
                  nincs könyvelőnk aki az ügyfél által kért programban könyvelne
                </MenuItem>
                <MenuItem value={'nincs elég közel a könyvelő'}>nincs elég közel a könyvelő</MenuItem>
                <MenuItem value={'eltűnt (nem válaszolt többszöri megkeresésre sem)'}>
                  eltűnt (nem válaszolt többszöri megkeresésre sem)
                </MenuItem>
                <MenuItem value={'csak érdeklődött, egyelőre nem aktuális'}>
                  csak érdeklődött, egyelőre nem aktuális
                </MenuItem>
                <MenuItem value={'nem jó, hogy velünk szerződik és nem a könyvelővel'}>
                  nem jó, hogy velünk szerződik és nem a könyvelővel
                </MenuItem>
                <MenuItem value={'más hamarabb adott ajánlatot'}>más hamarabb adott ajánlatot</MenuItem>
                <MenuItem value={'túl drágák vagyunk'}>túl drágák vagyunk</MenuItem>
                <MenuItem value={'könyvelő nem volt szimpatikus'}>könyvelő nem volt szimpatikus</MenuItem>
                <MenuItem value={'Lezárva - egyéb'}>Lezárva - egyéb</MenuItem>
              </Select>
            </div>
            <div>
              <div style={{ width: '250px', fontWeight: '800' }}>Miért nem minket egyéb?</div>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="whyNotUsText"
                name="whyNotUsText"
                onChange={(e) => singleValueHandler(e)}
                style={{ width: '250px' }}
                variant="standard"
                value={tempObj.whyNotUsText}
                sx={{ fontSize: 14 }}
                maxRows={4}
                multiline
              />
            </div>
          </div>
          <div className="validationChild3">
            <h3>Saját jegyzetek</h3>
            <div>
              <TextField
                multiline
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="messagelog"
                name="messagelog"
                onChange={messageHandler}
                style={{ width: '100%' }}
                maxRows={6}
                label={'Itt jegyzetelhetsz'}
                value={message}
              />
              <Button
                disabled={message.length === 0 ? true : false}
                onClick={() => {
                  updateStatusMessage(statusID, message);
                  setMessage('');
                }}
              >
                Küldés
              </Button>
            </div>
            {tempData.messagelog.toReversed().map((e, idx) => {
              return (
                <p key={idx}>
                  <span>{e.date}</span> <span>{e.message}</span>
                  <hr />
                </p>
              );
            })}
          </div>
          <div className="validationChild4">
            <h3>Telefonos jegyzetek</h3>
            <div>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="phonelog"
                name="phonelog"
                onChange={callHandler}
                style={{ width: '100%' }}
                multiline
                maxRows={6}
                label={'Itt jegyzetelhetsz'}
                value={callNote}
              />
              <Button
                disabled={callNote.length === 0 ? true : false}
                onClick={async () => {
                  await updateCallLog(statusID, callNote);
                  setCallNote('');
                }}
              >
                Küldés
              </Button>
              {tempData.phonelog.toReversed().map((e, idx) => {
                return (
                  <p key={idx}>
                    <span>{e.date}</span> <span>{e.message}</span>
                    <hr />
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: '16px' }}> Loading...</div>
      )}
    </div>
  );
};

export default StatusValidation;

import React, { useEffect, useState, useContext, useCallback } from 'react';
import Dropdown from '../../../../../components/Dropdown';
import { Button } from '@mui/material';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData } from 'src/fetch';

import { cafStatusList, aspStatusList, herStatusList } from '../statusHelper';

const leadTypes = [
  { label: 'VAF', id: 'vaf' },
  { label: 'Regisztrálás', id: 'reg' },
  { label: 'Kereső flow', id: 'asp' },
];

const StatusMain = ({ statusID }) => {
  const [statusData, setStatusData] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const [lead, setLead] = useState('');
  const [tempStatus, setTempStatus] = useState({
    cafstatus: 'n/a',
    aspstatus: 'n/a',
    herstatus: 'n/a'
  });
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;

  const getRequestData = useCallback(async () => {
    try {
      const response = await fetchData(`${baseUrl}/api/aspreq/${statusID}`, 'GET', localStorage.getItem('JWT'));
      let resp = await response.json();
      setRequestData(resp);
      const leadType = resp.leadtype ? resp.leadtype.toLowerCase() : '';
      setLead(leadType);
      if (leadType === 'reg' && resp.reqstatus.herstatus === 'n/a') {
        setTempStatus((prevStatus) => {
          const newStatus = {
            ...prevStatus,
            herstatus: 'Számlát küldeni',
          };
          // console.log('NEWSTATUS:', newStatus);
          return newStatus;
        });
      } else if (leadType === 'vaf' && resp.reqstatus.cafstatus === 'n/a') {
        setTempStatus((prevStatus) => {
          const newStatus = {
            ...prevStatus,
            cafstatus: 'Számlát küldeni',
          };
          // console.log('NEWSTATUS:', newStatus);
          return newStatus;
        });
      } else {
        setTempStatus(resp.reqstatus);
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, [baseUrl, statusID]);
  
  useEffect(() => {
    getRequestData();
    // console.log("REQDATA" ,requestData);
  }, [getRequestData]);

  useEffect(() => {
    // console.log("TEMPSTATUS UPDATED", tempStatus);
  }, [tempStatus]);

  const getStatusData = useCallback(async () => {
    await fetchData(`${baseUrl}/api/aspreq/getstatus/${statusID}`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setStatusData(resp);
        // setTempStatus(resp.reqstatus);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl, statusID]);

  useEffect(() => {
    getStatusData();
  }, [getStatusData]);

  const reloadData = async () => {
    await getStatusData();
  };

  const updateStatusStatus = async (reqID, newValue) => {
    await fetchData(`${baseUrl}/api/aspreq/status/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newValue,
    })
      .then(async (response) => {
        let resp = await response.json();
        reloadData();
        //console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const updateReq = async (reqID, params) => {
    await fetchData(`${baseUrl}/api/aspreq/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      updateParams: params,
    })
      .then(async (response) => {
        let resp = await response.json();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  return (
    <div className="StatusDisplayContainer">
      {statusData ? (
        <div className="StatusDetailsContainer">
          <p style={{ margin: '20px 0' }}>
            <span>
              Keresés ID: <b>{statusData.reqID}</b>{' '}
            </span>
            <span>
              Kereső neve: <b>{statusData.reqname ? statusData.reqname : 'Nem adott meg'}</b>{' '}
            </span>
            <span>
              Kereső e-mail: <b>{statusData.reqemail}</b>{' '}
            </span>{' '}
            <br />
            <span>
              Keresés dátuma: <b>{statusData.rfpdate}</b>{' '}
            </span>
            <span>
              Utolsó módosítás: <b>{statusData.laststatuschange}</b>
            </span>
          </p>

          <div className="StatusDetailsLabel"></div>
          <div className="StatusDetailsLabel">
            <Dropdown
              label="Lead típus"
              id="leadtype"
              list={leadTypes}
              selValue={lead || ''}
              onChange={(value) => {
                setLead(value);
                updateReq(statusID, { leadtype: value });
              }}
            />
            <Dropdown
              label="CAF Státusz"
              id="cafstatus"
              list={cafStatusList}
              // selValue={statusData.reqstatus.cafstatus}
              selValue={tempStatus.cafstatus}
              // onChange={(value) => setTempStatus({ ...tempStatus, cafstatus: value })}
              onChange={async (value) => { await setTempStatus({ ...tempStatus, cafstatus: value }); }}
            />
            <Dropdown
              label="ASP Státusz"
              id="aspstatus"
              list={aspStatusList}
              // selValue={statusData.reqstatus.aspstatus}
              selValue={tempStatus.aspstatus}
              // onChange={(value) => setTempStatus({ ...tempStatus, aspstatus: value })}
              onChange={async (value) => { await setTempStatus({ ...tempStatus, aspstatus: value }); }}
            />
            <Dropdown
              label="REG Státusz"
              id="herstatus"
              list={herStatusList}
              // selValue={statusData.reqstatus.herstatus}
              selValue={tempStatus.herstatus}
              // onChange={(value) => setTempStatus({ ...tempStatus, herstatus: value })}
              onChange={async (value) => { await setTempStatus({ ...tempStatus, herstatus: value }); }}
            />
            <div style={{ width: '150px', padding: '16px' }}>
              <Button
                onClick={async () => await updateStatusStatus(statusData.reqID, tempStatus)}
                style={{ float: 'right' }}
                variant="contained"
              >
                Frissítés
              </Button>
            </div>
          </div>
          <div>
            {statusData.statuslog &&
              statusData.statuslog.map((item, index) => {
                return (
                  <div className="StatusColumn" key={index}>
                    <span style={{ width: '25%' }}>
                      <b>Dátum:</b> {item.date}
                    </span>
                    <span style={{ width: '25%' }}>
                      <b>CAF:</b> {item.statuschange.cafstatus}
                    </span>
                    <span style={{ width: '25%' }}>
                      <b>ASP:</b> {item.statuschange.aspstatus}
                    </span>
                    <span style={{ width: '25%' }}>
                      <b>REG:</b> {item.statuschange.herstatus}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default StatusMain;

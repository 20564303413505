export const fetchData = (url, method, jwt, data) => {
  let fetchParams = {
    method: method,
    headers: {
      'Content-Type': 'application/json',

      Authorization: `Bearer ${jwt}`,
    },
  };
  data && (fetchParams.body = JSON.stringify(data));
  return fetch(url, fetchParams);
};
export const fetchData2 = async (url, method, key, body) => {
  let fetchParams = {
    method: method,
    body: JSON.stringify(body),
    headers: {
      Authorization: key,
      'Content-Type': 'application/json',
    },
  };
  return fetch(url, fetchParams);
};

import React, { useEffect, useState, useContext } from 'react';
import MuiSwitch from 'src/components/Switch';
import { liabilityInsInputs, servicesInputs, extraInfoInputs } from '../profileHelper';
import { ProviderContext } from '../ProviderProfile';

const Services = () => {
  const [data, setData] = useState(null);
  const { providerData, updateData } = useContext(ProviderContext);

  useEffect(() => {
    if (providerData) {
      setData({
        provider_services: providerData.provider_services,
        provider_liability_insurance: providerData.provider_liability_insurance,
        provider_info: providerData.provider_info,
      });
    }
  }, [providerData]);

  const handleCheck = async (id, value) => {
    setData({ ...data, [id]: value });
    await updateData('provider', { [id]: value });
  };

  return (
    <div className="TabContent">
      {data ? (
        <div className="TabContentFlexRow">
          <div className="Padding12">
            <p className="LabelBoldM8">Szolgáltatások</p>
            {servicesInputs.map((item, i) => {
              return (
                <div className="Width350" key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.modelKey}.${item.id}`}
                    value={data[item.modelKey][item.id]}
                    onChange={handleCheck}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <p className="LabelBoldM8">Felelősségbiztosítás</p>
            {liabilityInsInputs.map((item, i) => {
              return (
                <div className="Width350" key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.modelKey}.${item.id}`}
                    value={data[item.modelKey][item.id]}
                    onChange={handleCheck}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <p className="LabelBoldM8">Extra Info</p>
            {extraInfoInputs.map((item, i) => {
              return (
                <div className="Width350" key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.modelKey}.${item.id}`}
                    value={data[item.modelKey][item.id]}
                    onChange={handleCheck}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default Services;

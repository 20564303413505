import React, { useEffect, useState, useContext, useCallback } from 'react';
import RadioInputMultiChoice from 'src/components/RadioInputMultiChoice';
import { fetchData } from 'src/fetch';
import { ProviderContext } from '../ProviderProfile';
import { EnvContext } from 'src/context/EnvContext';
import TextInput from 'src/components/TextInput';
import { NumberInput } from 'src/components/NumberInput';
import MuiSwitch from 'src/components/Switch';
import Button from '@mui/material/Button';

const radioInputs = [
  {
    label: 'Előfizetés típusa',
    id: 'yearly',
    modelKey: 'package',
    options: [
      { label: 'Éves', value: true },
      { label: 'Féléves', value: false },
    ],
  },
  {
    label: 'Csomag típusa',
    id: 'packageType',
    modelKey: 'package',
    options: [
      { label: 'Alap', value: 'basic' },
      { label: 'Haladó', value: 'advanced' },
      { label: 'Premium', value: 'premium' },
    ],
  },
  {
    label: 'Csomag mérete',
    id: 'packageSize',
    modelKey: 'package',
    options: [
      { label: 'Egyéni csomag', value: 'entrepreneur' },
      { label: 'Kis irodai csomag', value: 'small_office' },
      { label: 'Irodai csomag', value: 'office' },
      { label: 'Vállalati csomag', value: 'corporate' },
    ],
  },
];

const switchInputs = [
  { label: 'Aktív előfizetés', modelKey: 'subscription', id: 'activeSubscription' },
  { label: 'Fizetett (nincs tartozás)', modelKey: 'subscription', id: 'paid' },
];

const textInputs = [
  { label: 'Partnerkód', modelKey: 'subscription', id: 'partnerCode' },
  { label: 'Előfizetés státusza', modelKey: 'subscription', id: 'subscriptionStatus' },
];

const numberInputs = [
  { label: 'Fizetve összesen', modelKey: 'subscription', id: 'totalpaid' },
  { label: 'Tartozás', modelKey: 'subscription', id: 'debtamount' },
];

const periodInputs = [
  { label: 'Periódus kezdete', id: 'startDate', type: 'date' },
  { label: 'Periódus vége', id: 'endDate', type: 'date' },
  { label: 'Fizetett összeg', id: 'amount', type: 'text' },
  { label: 'Megjegyzés', id: 'comment', type: 'text' },
];

const Subscription = () => {
  const [data, setData] = useState('');
  const [providerID, setProviderID] = useState('');
  const [newPaymentPeriod, setNewPaymenPeriod] = useState({
    startDate: '',
    endDate: '',
    amount: '',
    comment: '',
    paid: true,
  });
  const { providerData } = useContext(ProviderContext);

  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;

  const formatDate = (date) => {
    date = new Date(date);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  };

  const getSubscription = useCallback(
    async (providerID) => {
      await fetchData(`${baseUrl}/api/subscription/${providerID}`, 'GET', localStorage.getItem('JWT'))
        .then(async (response) => {
          let resp = await response.json();
          setData(resp.subscription[0]);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    },
    [baseUrl]
  );

  useEffect(() => {
    if (providerData) {
      setProviderID(providerData.providerID);
      getSubscription(providerData.providerID);
    }
  }, [providerData, getSubscription]);

  const updateSubscription = async (data) => {
    await fetchData(`${baseUrl}/api/subscription/${providerID}`, 'PATCH', localStorage.getItem('JWT'), data)
      .then(async (response) => {
        let resp = await response.json();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const handleChange = async (id, value) => {
    let ids = id.split('.');
    let updatedData = { ...data, [ids[0]]: { ...data[ids[0]], [ids[1]]: value } };
    setData(updatedData);
    await updateSubscription(updatedData);
  };

  const handleChangePeriod = async (id, value) => {
    setNewPaymenPeriod({ ...newPaymentPeriod, [id]: value });
  };

  const addPaymentPeriod = async () => {
    let temp = data.periods ? data.periods : [];
    temp.unshift(newPaymentPeriod);
    let updatedData = { ...data, periods: temp };
    setData(updatedData);
    await updateSubscription(updatedData);
  };

  const deletePaymentPeriod = async (i) => {
    let temp = data.periods;
    temp.splice(i, 1);
    let updatedData = { ...data, periods: temp };
    setData(updatedData);
    await updateSubscription(updatedData);
  };

  return (
    <div className="TabContent">
      <div className="Padding12">
        <p style={{ fontSize: '14px', color: 'red' }}>
          Az app még nem dolgozik ezekkel az adatokkal, és nincsenek is szinkronizálva a szolgáltató adatlapjával,
          minden adat amit az app jelenleg használ az előző tabokon módosítható, erre a fülre a billinghez lesz szükség.
        </p>
      </div>
      {data ? (
        <div className="TabContentFlexRow">
          <div className="Padding12"></div>
          <div className="Padding12">
            {switchInputs.map((item, i) => {
              return (
                <div className="Width350" key={i}>
                  <MuiSwitch
                    label={item.label}
                    id={`${item.modelKey}.${item.id}`}
                    value={data[item.modelKey][item.id]}
                    onChange={handleChange}
                  />
                </div>
              );
            })}
            <div className="InputPairs">
              {numberInputs.map((item, i) => {
                return (
                  <div key={i}>
                    <NumberInput
                      label={item.label}
                      id={`${item.modelKey}.${item.id}`}
                      valueProp={data[item.modelKey][item.id]}
                      onChange={handleChange}
                      width={'82%'}
                    />
                  </div>
                );
              })}
            </div>
            {textInputs.map((item, i) => {
              return (
                <div className="TextInput" key={i}>
                  <TextInput
                    label={item.label}
                    id={`${item.modelKey}.${item.id}`}
                    valueProp={data[item.modelKey][item.id]}
                    onChange={handleChange}
                  />
                </div>
              );
            })}
            {radioInputs.map((item, i) => {
              return (
                <div style={{ width: '350px' }} key={i}>
                  <RadioInputMultiChoice
                    onChange={handleChange}
                    id={`${item.modelKey}.${item.id}`}
                    valueProp={data[item.modelKey][item.id]}
                    label={item.label}
                    options={item.options}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <b>Fizetési periódus hozzáadása</b>
            <div className="Width350">
              <MuiSwitch label="Fizetve" id="paid" value={newPaymentPeriod.paid} onChange={handleChangePeriod} />
            </div>
            {periodInputs.map((item, i) => {
              if (item.id === 'amount') {
                return (
                  <div key={i}>
                    <NumberInput
                      label={item.label}
                      id={item.id}
                      valueProp={newPaymentPeriod[item.id]}
                      onChange={handleChangePeriod}
                      width={'100%'}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="TextInput" key={i}>
                    <TextInput
                      label={item.label}
                      id={item.id}
                      valueProp={newPaymentPeriod[item.id]}
                      onChange={handleChangePeriod}
                      type={item.type}
                      shrinkLabel
                    />
                  </div>
                );
              }
            })}
            <div className="ButtonContainer">
              <Button onClick={() => addPaymentPeriod()} variant="text">
                Hozzáadás
              </Button>
            </div>
          </div>
          <div className="Padding12">
            <b>Fizetési periódusok</b>
            {data.periods?.length > 0 ? (
              <>
                {data.periods.map((period, i) => {
                  return (
                    <div key={i}>
                      <div className="Width350">
                        <MuiSwitch disabled label="Fizetve" id="paid" value={period.paid} />
                      </div>
                      {periodInputs.map((item, i) => {
                        return (
                          <div className="TextInput" key={i}>
                            <TextInput
                              label={item.label}
                              id={`${item.modelKey}.${item.id}`}
                              valueProp={
                                item.type === 'date' && period[item.id] ? formatDate(period[item.id]) : period[item.id]
                              }
                              type={item.type}
                              shrinkLabel
                              disabled
                            />
                          </div>
                        );
                      })}
                      <div className="ButtonContainer">
                        <Button onClick={() => deletePaymentPeriod(i)} variant="text">
                          Törlés
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <p className="Width350">Ehhez a szolgáltatóhoz, még nincsenek hozzáadva a fizetési periódusok</p>
            )}
          </div>
        </div>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default Subscription;

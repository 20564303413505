import React, { useEffect, useState, useContext, useCallback } from 'react';
import TextInput from 'src/components/TextInput';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData } from 'src/fetch';
import { ProviderContext } from '../ProviderProfile';
import ReviewPaper from './components/ReviewPaper';
import Button from '@mui/material/Button';

const reviewInputs = [
  { id: 'date', type: 'date' },
  { label: 'Értékelő neve', id: 'reviewer', type: 'text' },
  { label: 'Értékelés', id: 'review', type: 'text', multiline: true },
  { label: 'Rugalmasság', id: 'flexibility', type: 'number' },
  { label: 'Professzionalizmus', id: 'professionalism', type: 'number' },
  { label: 'Tapasztalat', id: 'industryexperience', type: 'number' },
  { label: 'Pontosság', id: 'precision', type: 'number' },
  { label: 'Ár / érték árány', id: 'pricevalue', type: 'number' },
];

const Reviews = () => {
  const formatDate = (date) => {
    date = new Date(date);
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  };

  const [providerID, setProviderID] = useState('');
  const [newReview, setNewReview] = useState({
    reviewer: '',
    date: formatDate(new Date()),
    review: '',
    flexibility: 5,
    professionalism: 5,
    industryexperience: 5,
    precision: 5,
    pricevalue: 5,
  });
  const [reviews, setReviews] = useState([]);
  const { providerData } = useContext(ProviderContext);
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;

  const getReviews = useCallback(
    async (id) => {
      await fetchData(`${baseUrl}/api/review/id`, 'POST', localStorage.getItem('JWT'), { providerID: id })
        .then(async (response) => {
          let resp = await response.json();
          setReviews(resp.review);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    },
    [baseUrl]
  );

  useEffect(() => {
    if (providerData) {
      setProviderID(providerData.providerID);
      getReviews(providerData.providerID);
    }
  }, [providerData, getReviews]);

  const uploadReview = async (id) => {
    await fetchData(`${baseUrl}/api/review`, 'POST', localStorage.getItem('JWT'), { ...newReview, providerID: id })
      .then(async () => {
        getReviews(providerData.providerID);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const handleChange = async (id, value) => {
    setNewReview({ ...newReview, [id]: value });
  };

  return (
    <div className="TabContent">
      {providerID ? (
        <div className="TabContentFlexRow SpaceAround">
          <div className="Padding12">
            {reviewInputs.map((item, i) => {
              return (
                <div className="TextInput" key={i}>
                  <TextInput
                    label={item.label}
                    id={item.id}
                    valueProp={newReview[item.id]}
                    onChange={handleChange}
                    type={item.type}
                    multiline={item.multiline}
                  />
                </div>
              );
            })}
            <div className="ButtonContainer">
              <Button onClick={() => uploadReview(providerID)} variant="text">
                Feltöltés
              </Button>
            </div>
          </div>
          <div style={{ width: '500px' }} className="Padding12">
            {reviews.map((item, i) => {
              return (
                <div key={i}>
                  <ReviewPaper review={item} getReviews={getReviews} providerID={providerID} />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default Reviews;

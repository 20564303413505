import React, { useEffect, useContext, useCallback, useState } from 'react';
// material
import { Stack, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData } from 'src/fetch';
import ReferralsTable from 'src/sections/@dashboard/referrals/ReferralsTable';
import '../sections/@dashboard/referrals/referrals.scss';

export default function Referrals() {
  const [referrals, setReferrals] = useState([]);
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;

  const getReferrals = useCallback(async () => {
    await fetchData(`${baseUrl}/api/referral/all`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        console.log(resp, 'resp');
        setReferrals(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl]);

  useEffect(() => {
    getReferrals();
  }, [getReferrals]);

  return (
    <Page title="Users">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Referrals
          </Typography>
        </Stack>
        <ReferralsTable referrals={referrals} />
      </Container>
    </Page>
  );
}

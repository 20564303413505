import React, { useContext, useCallback, useState } from 'react';
// material
import { Stack, Container, Typography, Button, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
// components
import Page from '../components/Page';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData } from 'src/fetch';
import StatusTable from 'src/sections/@dashboard/status/StatusTable';
import '../sections/@dashboard/status/status.scss';

export default function Statuses() {
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;

  const getAllStatuses = useCallback(async () => {
    // await fetchData(`${baseUrl}/api/admin/get-row-data-req?schemaInput=teszt`, 'GET', localStorage.getItem('JWT'))
    await fetchData(`${baseUrl}/api/admin/get-row-data-req`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setStatuses(resp);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl]);
  const getOpenStatuses = useCallback(async () => {
    await fetchData(`${baseUrl}/api/admin/get-active-contract`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setStatuses(resp);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl]);
  const getLostStatuses = useCallback(async () => {
    await fetchData(`${baseUrl}/api/admin/get-lost-contract`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setStatuses(resp);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl]);
  const getWonStatuses = useCallback(async () => {
    await fetchData(`${baseUrl}/api/admin/get-won-contract`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setStatuses(resp);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl]);

  const getSavedStatuses = useCallback(async () => {
    await fetchData(`${baseUrl}/api/admin/get-saved-req`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setStatuses(resp);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl]);

  return (
    <Page title="Status">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Requests
            <br />
            <Button
              variant="outlined"
              onClick={(e) => {
                setLoading(true);
                getAllStatuses();
              }}
              style={{ marginRight: '2rem' }}
            >
              Mindegyik
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => {
                setLoading(true);
                getOpenStatuses();
              }}
              style={{ marginRight: '2rem' }}
            >
              Aktív
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => {
                setLoading(true);
                getWonStatuses();
              }}
              style={{ marginRight: '2rem' }}
            >
              Megnyert
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => {
                setLoading(true);
                getLostStatuses();
              }}
              style={{ marginRight: '2rem' }}
            >
              Elvesztett
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => {
                setLoading(true);
                getSavedStatuses();
              }}
              style={{ marginRight: '2rem' }}
            >
              Mentett, nem megbízás
            </Button>
            {/* <Button
              variant="outlined"
              onClick={(e) => {
                setLoading(true);
                get2022Archive();
              }}
              style={{ marginRight: '2rem' }}
            >
              2022 Archive
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => {
                setLoading(true);
                get20231Archive();
              }}
              style={{ marginRight: '2rem' }}
            >
              2023-1 Archive
            </Button>
            <Button
              variant="outlined"
              onClick={(e) => {
                setLoading(true);
                get20232Archive();
              }}
              style={{ marginRight: '2rem' }}
            >
              2023-2 Archive
            </Button> */}
          </Typography>
        </Stack>
        {loading ? (
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <StatusTable statuses={statuses} />
        )}
      </Container>
    </Page>
  );
}

import React, { useEffect, useState, useContext, useCallback } from 'react';
import Dropdown from '../../../../components/Dropdown';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData } from 'src/fetch';

const referralFields = [
  { label: '#', id: '#', width: '10px' },
  /*   { label: 'Ajánló kódja', id: 'refCode', width: '150px' },
  { label: 'Ajánló email címe', id: 'refererEmail', width: '150px' }, */
  { label: 'Ajánlott email címe', id: 'email', referee: true, width: '170px' },
  { label: 'Státusz', id: 'status', referee: true, width: '100px' },
  { label: 'Szerződés száma', id: 'contractID', referee: true, width: '100px' },
  { label: 'Utolsó státusz dátuma', id: 'statusDate', referee: true, width: '120px' },
  { label: 'Bónusz', id: 'bonus', referee: true, width: '100px' },
  { label: 'Bónusz típusa', id: 'charity', referee: true, width: '150px' },
];

const ReferralDisplay = ({ referralID }) => {
  const [referralData, setReferralData] = useState(null);

  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;

  function formatDate(date = new Date()) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  const getReferralData = useCallback(async () => {
    await fetchData(`${baseUrl}/api/referral/get/${referralID}`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setReferralData(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl, referralID]);

  useEffect(() => {
    getReferralData();
  }, [getReferralData]);

  const updateReferralStatus = async (refID, refereeID, newValue, fieldid) => {
    await fetchData(`${baseUrl}/api/referral/update/${refID}`, 'PATCH', localStorage.getItem('JWT'), {
      refereeID: refereeID,
      updateParams: [{ key: fieldid, value: newValue }],
    })
      .then(async (response) => {
        let resp = await response.json();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  /*   const reloadData = async () => {
    await getReferralData();
  }; */

  return (
    <div className="ReferralDisplayContainer">
      {referralData ? (
        <div className="ReferralDetailsContainer">
          <p>
            Ajánló e-mail címe: <b>{referralData.refererEmail}</b>
          </p>
          <p style={{ marginBottom: '24px' }}>
            Ajánló kódja: <b>{referralData.refCode}</b>
          </p>
          <div className="ReferralDetailsLabel">
            {referralFields.map((item, index) => {
              return (
                <div className="ReferralColumn Label" style={{ width: item.width }} key={index}>
                  <p> {item.label}</p>
                </div>
              );
            })}
          </div>
          <div className="ReferralDetailsContentContainer">
            {referralData.referees.map((referee, index) => {
              let content = [];
              referralFields.forEach((field, index2) => {
                if (field.label !== '#') {
                  if (field.referee) {
                    if (field.id === 'status') {
                      content.push(
                        <div className="ReferralColumn" style={{ width: field.width }} key={index2}>
                          <Dropdown
                            label="Státusz"
                            list={[
                              { label: 'Kiajánlva', id: 'sent' },
                              { label: 'Folyamatban', id: 'inprogress' },
                              { label: 'Megegyezett', id: 'contractsigned' },
                              { label: 'Jóváírva', id: 'bonuspaidcharity' },
                              { label: 'Számlából levonva', id: 'bonuspaidself' },
                              { label: 'Lezárva (sikertelen)', id: 'rejected' },
                            ]}
                            selValue={referee[field.id]}
                            onChange={(value) => updateReferralStatus(referralData._id, referee._id, value, 'status')}
                          />
                        </div>
                      );
                    } else if (field.id === 'bonus') {
                      content.push(
                        <div className="ReferralColumn" style={{ width: field.width }} key={'bonus' + index2}>
                          <Dropdown
                            label="Bónusz"
                            list={[
                              { label: '3000', id: '3000' },
                              { label: '8000', id: '8000' },
                            ]}
                            selValue={referee[field.id]}
                            onChange={(value) => updateReferralStatus(referralData._id, referee._id, value, 'bonus')}
                          />
                        </div>
                      );
                    } else {
                      let cellContent = referee[field.id];

                      if (field.id === 'statusDate') {
                        cellContent = formatDate(referee[field.id]);
                      } else if (field.id === 'charity') {
                        cellContent = referee[field.id] ? 'Adomány' : 'Saját részre';
                      }
                      content.push(
                        <div className="ReferralColumn" style={{ width: field.width }} key={index2}>
                          <p>{cellContent}</p>
                        </div>
                      );
                    }
                  } else if (!field.referee) {
                    content.push(
                      <div className="ReferralColumn" style={{ width: field.width }} key={index2}>
                        <p>{referralData[field.id]}</p>
                      </div>
                    );
                  }
                } else if (field.label === '#') {
                  content.push(
                    <div className="ReferralColumn" style={{ width: field.width }} key={index2}>
                      <p>{index + 1}</p>
                    </div>
                  );
                }
              });
              return (
                <div key={index} className="ReferralDetailsContent">
                  {content}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default ReferralDisplay;

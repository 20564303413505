import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import './components.scss';

const TextInput = ({ id, valueProp, label, onChange, multiline, type = 'text', shrinkLabel, disabled }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(valueProp ? valueProp : '');
  }, [valueProp]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleBlur = (event) => {
    onChange(event.target.id, event.target.value);
  };

  return (
    <div key={id}>
      <TextField
        fullWidth
        id={id}
        label={label}
        value={value}
        onChange={(event) => handleChange(event)}
        onBlur={(event) => handleBlur(event)}
        variant="standard"
        multiline={multiline}
        type={type}
        InputLabelProps={{
          shrink: shrinkLabel,
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default TextInput;

import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Button } from '@mui/material';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData, fetchData2 } from 'src/fetch';
import { TextField, MenuItem, ListItemText, Select, Checkbox } from '@mui/material';

const Documents = ({ statusID }) => {
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;
  const [requestData, setRequestData] = useState();
  const [provList, setProvList] = useState([]);
  const [custPrice, setCustPrice] = useState({ accounting: '', payrol: '', taxcon: '' });
  const [provPrice, setProvPrice] = useState({ accounting: '', payrol: '', taxcon: '' });
  const [documents, setDocuments] = useState({
    custcontract: '',
    provcontract: '',
    misc1: '',
    misc2: '',
    misc3: '',
    misc4: '',
    misc5: '',
    misc6: '',
  });
  const [tempObj, setTempObj] = useState({
    contractdate: '',
    legalperson: '',
    legaladdress: '',
    monthlyinvoice: '',
    provprice: {
      accounting: '',
      payrol: '',
      taxcon: '',
    },
    custprice: {
      accounting: '',
      payrol: '',
      taxcon: '',
    },
    documents: {
      custcontract: '',
      provcontract: '',
      misc1: '',
      misc2: '',
      misc3: '',
      misc4: '',
      misc5: '',
      misc6: '',
    },
    statuslog: [],
    responsibleagent: '',
    chosenprovider: '',
    chosenlawyer: '',
    howdidyoufindus: '',
    messagelog: [],
    chosendate: '',
    startingdate: 1690900379,
    accounting: false,
    taxconsulting: false,
    taxconsultingsimple: false,
    taxconsultingcomplex: false,
    legalentityfounding: false,
    payroll: false,
    audit: false,
    liquidation: false,
    languagefilter: true,
    languages: {
      hungarian: true,
      english: false,
      german: false,
      french: false,
      chinese: false,
      russian: false,
      romanian: false,
      spanish: false,
    },
    noofemployees: 0,
    yearlyrevenue: 0,
    incominginvoices: 0,
    outgoinginvoices: 0,
    industry: '',
    companytype: '',
    companyctcname: '',
    zip_code: '',
    foreign_trade: false,
    foreigntradeEU: '',
    newlyfounded: false,
    vatFilter: false,
    vatPayment: '',
    taxform: '',
    email: '',
    gdpr: true,
    marketing: true,
    reqMessage: '',
    companyPhone: '',
    onlyBrowsing: false,
    isDiscounted: false,
    bankingpref: {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      opt8: false,
      other: false,
      bankidk: true,
    },
    invoicingpref: {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      other: false,
      invoicingidk: true,
    },
    softwarepref: {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      opt8: false,
      opt9: false,
      opt10: false,
      opt11: false,
      opt12: false,
      opt13: false,
      opt14: false,
      opt15: false,
      opt16: false,
      opt17: false,
      opt18: false,
      opt19: false,
      opt20: false,
      other: false,
    },
    proposedSinglePrice: '',
    proposedContPrice: '',
    multiplecompany: 'singlecompany',
    whyNotUsText: '',
    companylegalname: '',
  });

  const getRequestData = useCallback(async () => {
    await fetchData(`${baseUrl}/api/aspreq/${statusID}`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setRequestData(resp);
        setTempObj(resp);
        setDocuments(resp.documents);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl, statusID]);

  //console.log(tempObj, 'TEMPObj');

  useEffect(() => {
    getRequestData();
  }, [getRequestData]);

  const updateRequestData = useCallback(
    async (statusID, updateParams) => {
      await fetchData2(`${baseUrl}/api/aspreq/${statusID}`, 'PATCH', 'B3OeO1K7-KcEcE8PdI4Eb', {
        requestId: statusID,
        updateParams: updateParams,
      })
        .then(async (response) => {
          console.log('AXIOS RESPONSE: ', response);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    },
    [baseUrl, statusID]
  );
  //console.log(tempObj, 'TEMPOBJECT', tempObj, 'TEMPObj');

  return (
    <div>
      {tempObj ? (
        <div className="StatusDetailsContainer">
          <div>
            <p className="marginTop16" style={{ fontSize: '24px' }}>
              <strong>Dokumentumok</strong>
            </p>
            <p className="marginTop16">
              <span className={'spanTitle width200'}>Ügyfél szerződés: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="custcontract"
                name="custcontract"
                value={documents.custcontract}
                style={{ width: '600px' }}
                variant="standard"
                onChange={(event) => {
                  setDocuments({ ...documents, custcontract: event.target.value });
                  console.log(documents, 'DOKS');
                }}
                onBlur={() => setTempObj({ ...tempObj, documents: documents })}
              ></TextField>
              <Button
                size={'small'}
                onClick={() => {
                  window.open(documents.custcontract);
                }}
              >
                Megnyit
              </Button>
            </p>
            <p className="marginTop16">
              <span className={'spanTitle width200'}>Szolgáltató szerződés: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="provcontract"
                name="provcontract"
                value={documents.provcontract}
                style={{ width: '600px' }}
                variant="standard"
                onChange={(event) => {
                  setDocuments({ ...documents, provcontract: event.target.value });
                  console.log(documents, 'DOKS');
                }}
                onBlur={() => setTempObj({ ...tempObj, documents: documents })}
              ></TextField>
              <Button
                size={'small'}
                onClick={() => {
                  window.open(documents.provcontract);
                }}
              >
                Megnyit
              </Button>
            </p>
            <p className="marginTop16">
              <span className={'spanTitle width200'}>Dokument 1: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="misc1"
                name="misc1"
                value={documents.misc1}
                style={{ width: '600px' }}
                variant="standard"
                onChange={(event) => {
                  setDocuments({ ...documents, misc1: event.target.value });
                  console.log(documents, 'DOKS');
                }}
                onBlur={() => setTempObj({ ...tempObj, documents: documents })}
              ></TextField>
              <Button
                size={'small'}
                onClick={() => {
                  window.open(documents.misc1);
                }}
              >
                Megnyit
              </Button>
            </p>
            <p className="marginTop16">
              <span className={'spanTitle width200'}>Dokument 2: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="misc2"
                name="misc2"
                value={documents.misc2}
                style={{ width: '600px' }}
                variant="standard"
                onChange={(event) => {
                  setDocuments({ ...documents, misc2: event.target.value });
                  console.log(documents, 'DOKS');
                }}
                onBlur={() => setTempObj({ ...tempObj, documents: documents })}
              ></TextField>
              <Button
                size={'small'}
                onClick={() => {
                  window.open(documents.misc2);
                }}
              >
                Megnyit
              </Button>
            </p>
            <p className="marginTop16">
              <span className={'spanTitle width200'}>Dokument 3: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="misc3"
                name="misc3"
                value={documents.misc3}
                style={{ width: '600px' }}
                variant="standard"
                onChange={(event) => {
                  setDocuments({ ...documents, misc3: event.target.value });
                  console.log(documents, 'DOKS');
                }}
                onBlur={() => setTempObj({ ...tempObj, documents: documents })}
              ></TextField>
              <Button
                size={'small'}
                onClick={() => {
                  window.open(documents.misc3);
                }}
              >
                Megnyit
              </Button>
            </p>
            <p className="marginTop16">
              <span className={'spanTitle width200'}>Dokument 4: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="misc4"
                name="misc4"
                value={documents.misc4}
                style={{ width: '600px' }}
                variant="standard"
                onChange={(event) => {
                  setDocuments({ ...documents, misc4: event.target.value });
                  console.log(documents, 'DOKS');
                }}
                onBlur={() => setTempObj({ ...tempObj, documents: documents })}
              ></TextField>
              <Button
                size={'small'}
                onClick={() => {
                  window.open(documents.misc4);
                }}
              >
                Megnyit
              </Button>
            </p>
            <p className="marginTop16">
              <span className={'spanTitle width200'}>Dokument 5: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="misc5"
                name="misc5"
                value={documents.misc5}
                style={{ width: '600px' }}
                variant="standard"
                onChange={(event) => {
                  setDocuments({ ...documents, misc5: event.target.value });
                  console.log(documents, 'DOKS');
                }}
                onBlur={() => setTempObj({ ...tempObj, documents: documents })}
              ></TextField>
              <Button
                size={'small'}
                onClick={() => {
                  window.open(documents.misc5);
                }}
              >
                Megnyit
              </Button>
            </p>
            <p className="marginTop16">
              <span className={'spanTitle width200'}>Dokument 6: </span>
              <TextField
                inputProps={{ style: { fontSize: 14, padding: 2 } }}
                id="misc6"
                name="misc6"
                value={documents.misc6}
                style={{ width: '600px' }}
                variant="standard"
                onChange={(event) => {
                  setDocuments({ ...documents, misc6: event.target.value });
                }}
                onBlur={() => setTempObj({ ...tempObj, documents: documents })}
              ></TextField>
              <Button
                size={'small'}
                onClick={() => {
                  window.open(documents.misc6);
                }}
              >
                Megnyit
              </Button>
            </p>
          </div>
          <div className="marginTop16">
            <Button
              size={'large'}
              onClick={() => {
                updateRequestData(statusID, tempObj);
              }}
            >
              Adatok mentése
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: '16px' }}> Loading...</div>
      )}
    </div>
  );
};

export default Documents;

import React, { useState, useEffect, useContext } from 'react';
import { fetchData } from 'src/fetch';
import { ProviderContext } from '../../ProviderProfile';
import { EnvContext } from 'src/context/EnvContext';
import DefaultProfileImage from 'src/images/default-profile-image.svg';
import DefaultUploadImage from 'src/images/default-upload-image.svg';

const ImageUploader = () => {
  const [profileImage, setProfileImage] = useState('');
  const [providerID, setProviderID] = useState('');
  const [randomNr, setRandomNr] = useState(null);
  const { providerData, reloadData } = useContext(ProviderContext);
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;

  useEffect(() => {
    if (providerData) {
      setProfileImage(providerData.provider_media ? providerData.provider_media.profileImage : DefaultProfileImage);
      setProviderID(providerData.providerID);
      setRandomNr(Math.floor(Math.random() * 1000000000));
    }
  }, [providerData]);

  const uploadInput = React.useRef(null);

  const handleClick = () => {
    uploadInput.current.click();
  };

  const handleUpload = (event) => {
    var file = event.target.files[0];
    const reader = new FileReader();
    /* eslint-disable no-unused-vars */
    var url = reader.readAsDataURL(file);
    /* eslint-disable no-unused-vars */
    reader.onloadend = async function (e) {
      setProfileImage(reader.result);
      await fetchData(`${baseUrl}/api/provider/picture`, 'PATCH', localStorage.getItem('JWT'), {
        provider_media: {
          profileImage: `https://knet-profile-images.s3.eu-west-1.amazonaws.com/profile_${providerID}?${randomNr}`,
        },
        image: reader.result,
        providerID: providerID,
      })
        .then(async () => {
          await reloadData();
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    };
  };
  return (
    <div className="ImageUploadContainer">
      <div className="ProfileImage">
        <img
          style={{ width: '200px', height: '200px', margin: '10px' }}
          src={profileImage.length > 0 ? profileImage : DefaultProfileImage}
          alt=""
        />
        <img
          style={{
            width: '40px',
            height: '40px',
          }}
          onClick={handleClick}
          className="UploadIcon"
          src={DefaultUploadImage}
          alt=""
        />
      </div>
      <input
        accept="image/*"
        id="contained-button-file"
        multiple
        type="file"
        onChange={(event) => handleUpload(event)}
        ref={uploadInput}
        style={{ display: 'none' }}
      />
      <p>Kép feltöltése</p>
    </div>
  );
};

export default ImageUploader;

import React, { useEffect, useState, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { ProviderContext } from '../../ProviderProfile';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const displayLanguages = (language) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>{language.name}</div>
    </div>
  );
};

function getStyles(name, languages, theme) {
  return {
    fontWeight: languages.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const languagesText = [
  { name: 'Angol', id: 'english' },
  { name: 'Német', id: 'german' },
  { name: 'Francia', id: 'french' },
  { name: 'Kínai', id: 'chinese' },
  { name: 'Orosz', id: 'russian' },
  { name: 'Román', id: 'romanian' },
  { name: 'Spanyol', id: 'spanish' },
];

export default function LanguageSelector({ ...props }) {
  const theme = useTheme();

  const [providerLanguages, setProviderLanguages] = useState({});
  const [languages, setLanguages] = useState([]);
  const { providerData, updateData, setProviderData } = useContext(ProviderContext);

  useEffect(() => {
    if (providerData) {
      setProviderLanguages(providerData.provider_languages);
    }
  }, [providerData]);

  useEffect(() => {
    let selectedLanguages = [];
    for (let i in providerLanguages) {
      if (providerLanguages[i] === true) {
        languagesText.forEach((item) => {
          if (item.id === i) {
            selectedLanguages.push(item.name);
          }
        });
      }
    }
    setLanguages(selectedLanguages);
  }, [providerLanguages]);

  const handleChange = async (event) => {
    console.log(event);
    let temp = {
      hungarian: providerLanguages.hungarian,
      english: providerLanguages.english,
      german: providerLanguages.german,
      french: providerLanguages.french,
      chinese: providerLanguages.chinese,
      russian: providerLanguages.russian,
      romanian: providerLanguages.romanian,
      spanish: providerLanguages.spanish,
    };
    languagesText.forEach((item) => {
      if (event.target.value.find((value) => value === item.name)) {
        temp[item.id] = true;
      } else {
        temp[item.id] = false;
      }
    });
    setProviderData({ ...providerData, provider_languages: temp });
    await updateData('provider', { provider_languages: temp });
    const {
      target: { value },
    } = event;
    setLanguages(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div>
      <FormControl style={{ margin: '12px 0' }} sx={{ m: 1, width: 300 }}>
        <Select
          disabled={props.disabled}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={languages}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {languagesText.map((item) => (
            <MenuItem key={item.id} value={item.name} style={getStyles(item.name, languages, theme)} id={item.id}>
              {displayLanguages(item)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

import React, { useContext } from 'react';
import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import { fetchData } from 'src/fetch';
import { EnvContext } from 'src/context/EnvContext';

const ReviewPaper = ({ review, getReviews, providerID }) => {
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;

  const deleteReview = async (id) => {
    await fetchData(`${baseUrl}/api/review/${id}`, 'DELETE', localStorage.getItem('JWT'))
      .then(async () => {
        await getReviews(providerID);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };
  const getReviewAvg = () => {
    let avg = 0;
    avg += review.professionalism;
    avg += review.flexibility;
    avg += review.pricevalue;
    avg += review.industryexperience;
    avg += review.precision;
    avg = avg / 5;
    return avg;
  };
  return (
    <div className="ReviewPaper">
      <div className="ReviewText">
        <div className="ReviewHeadingContainer">
          <p style={{ marginRight: '32px', fontWeight: '600' }}>
            {review.reviewer}{' '}
            {window.innerWidth > 600 ? null : (
              <span
                style={{
                  float: 'right',
                  fontSize: '16px',
                  fontWeight: 'normal',
                }}
              >
                {review.date}
              </span>
            )}
          </p>
          <Rating name="half-rating-read" defaultValue={getReviewAvg()} precision={0.1} readOnly size="small" />
          {window.innerWidth < 600 ? null : <p style={{ marginLeft: 'auto', fontSize: '16px' }}>{review.date}</p>}
        </div>
        <p>{review.review}</p>
        <div className="ButtonContainer">
          <Button onClick={() => deleteReview(review._id)} variant="text">
            Törlés
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReviewPaper;

import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import './components.scss';

const RangeInput = ({ id, valueProp, label, onChange, max }) => {
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');

  useEffect(() => {
    setMinValue(valueProp[0]);
    setMaxValue(valueProp[1]);
  }, [valueProp]);

  const handleChange = (event, index) => {
    if (index === 0) {
      setMinValue(event.target.value);
    } else setMaxValue(event.target.value);
  };

  const handleBlur = (event, index) => {
    if (index === 0) {
      setMinValue(event.target.value);
    } else setMaxValue(event.target.value);
    onChange(event.target.id, [parseInt(minValue), parseInt(maxValue)], max);
  };

  return (
    <div className="RangeInputContainer" key={id}>
      <p>{label}</p>
      <div className="RangeInputFlexContainer" key={id}>
        <div className="RangeInputField">
          <TextField
            fullWidth
            id={id}
            label="Min"
            value={minValue}
            onChange={(event) => handleChange(event, 0)}
            onBlur={(event) => handleBlur(event, 0)}
            variant="standard"
            type="number"
          />
        </div>
        <div className="RangeInputField">
          <TextField
            fullWidth
            id={id}
            label={`Max: ${max}`}
            value={maxValue}
            onChange={(event) => handleChange(event, 1)}
            onBlur={(event) => handleBlur(event, 1)}
            variant="standard"
            type="number"
          />
        </div>
      </div>
    </div>
  );
};

export default RangeInput;

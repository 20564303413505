export const columns = [
  {
    name: 'ReqID',
    label: 'Request ID',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'date',
    label: 'Request Date',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'chosendate',
    label: 'Chosen Date',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'name',
    label: 'Requesting Name',
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: 'email',
    label: 'Requesting E-mail',
    options: {
      filter: false,
      sort: true,
    },
  },
  // {
  //   name: 'phone',
  //   label: 'Requesting Phone',
  //   options: {
  //     filter: false,
  //     sort: true,
  //   },
  // },
  {
    name: 'cafstatus',
    label: 'CAF Status',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'aspstatus',
    label: 'ASP Status',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'herstatus',
    label: 'HERO Status',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'BupaID',
    label: 'Bupa Req?',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'responsibleagent',
    label: 'Responsible agent?',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'chosenprovider',
    label: 'Selected provider?',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'interactduedate',
    label: 'Due date',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'messageAboutSP',
    label: 'Message',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'leadtype',
    label: 'Lead',
    options: {
      filter: true,
      sort: true,
    },
  },
];

import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function Dropdown({ label, list, selValue, onChange, onClose }) {
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    setValue(selValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      {label ? <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel> : null}
      <Select
        /*         labelId="demo-simple-select-standard-label" */
        id="demo-simple-select-standard"
        value={value}
        label={label}
        onChange={handleChange}
        onClose={onClose}
      >
        {list.map((item, i) => {
          return (
            <MenuItem key={`${i}-${item.id}`} value={item.id}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:6bb44dc9-231e-4ed1-8f5d-8a52edab170b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ehtRWyYqT",
    "aws_user_pools_web_client_id": "4jipinf5h34lcaekdnj03jhggg",
    "oauth": {}
};


export default awsmobile;

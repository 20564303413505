import React, { useEffect, useState, useContext, useCallback } from 'react';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData } from 'src/fetch';
import { Button, TextField } from '@mui/material';
import { senderMailList } from '../statusHelper';
import Dropdown from 'src/components/Dropdown';
import Tiptap from 'src/components/Tiptap';
import { cafStatusList, aspStatusList, herStatusList } from '../statusHelper';
import Dropdown2 from '../../../../../components/Dropdown';
import StatusValidation, { services } from './validation';

const Emails = ({ statusID }) => {
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;
  const [tempData, setTempData] = useState();
  const [tempStatus, setTempStatus] = useState();
  const [servicesPicked, setServicesPicked] = useState([]);
  const [languagesPicked, setLanguagesPicked] = useState([]);
  const [chosenEmail, setChosenEmail] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [emailData, setEmailData] = useState({
    emailString: ``,
    senderMail: '',
    recipientList: '',
    optionaltext1: '',
    optionaltext2: '',
    addressinput: '',
    contactDate: '',
    providerName: '',
    providerNick: '',
    providerMail: '',
    providerPhone: '',
    customerName: '',
    customerPhone: '',
    customerMail: '',
    multiLink: '',
    ownPhoneNr: '',
    newPrice: '',
    oldPrice: '',
    invoiceDate: '',
    invoiceAmount: '',
    invoiceNrInput: '',
    monthOther: '',
    monthInput: '',
    signature: '',
    signatureName: '',
    signatureTitle: '',
    signaturePhone: '',
    signatureMail: '',
    subject: '',
    selectedTemplate: '',
    templateShort: '',
    calendlyLink: '',
    contractLink: '',
  });
  const [providerData, setProviderData] = useState();
  // const changeHandler = useCallback((event) => {
  //   setEmailData((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  // });

  //console.log(providerData, 'providerData');

  useEffect(() => {
    const savedEmailData = localStorage.getItem('emailTempData');
    if (savedEmailData && !emailData.emailString) {
      setEmailData(JSON.parse(savedEmailData));
    }
  }, []);

  useEffect(() => {
    if (emailData) {
      const updatedEmailData = { ...emailData, signature: '' }
      localStorage.setItem('emailTempData', JSON.stringify(updatedEmailData));
    }
  }, [emailData]);

  const updateStatusStatus = async (reqID, newValue) => {
    await fetchData(`${baseUrl}/api/aspreq/status/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newValue,
    })
      .then(async (response) => {
        let resp = await response.json();

        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const resetEmail = useCallback(() => {
    setChosenEmail('');
    setEmailData({
      emailString: '',
      chosenEmail: '',
      senderMail: emailData.senderMail,
      recipientList: '',
      optionaltext1: '',
      optionaltext2: '',
      addressinput: '',
      contactDate: '',
      providerName: '',
      providerNick: '',
      providerMail: '',
      providerPhone: '',
      customerName: '',
      customerPhone: '',
      customerMail: '',
      multiLink: '',
      ownPhoneNr: '',
      newPrice: '',
      oldPrice: '',
      invoiceDate: '',
      invoiceAmount: '',
      invoiceNrInput: '',
      monthOther: '',
      monthInput: '',
      signature: emailData.signature,
      signatureName: emailData.signatureName,
      signatureTitle: emailData.signatureTitle,
      signaturePhone: emailData.signaturePhone,
      signatureMail: emailData.signatureMail,
      subject: '',
      selectedTemplate: '',
      templateShort: '',
      calendlyLink: '',
      contractLink: '',
    });
  }, [emailData.senderMail, emailData.signature, emailData.signatureName, emailData.signatureTitle, emailData.signaturePhone, emailData.signatureMail]);

  useEffect(() => {
    if (emailData.subject && emailData.senderMail && emailData.recipientList && emailData.emailString) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [emailData.subject, emailData.senderMail, emailData.recipientList, emailData.emailString]);

  const getRequestData = useCallback(async () => {
    try {
      //console.log('IN getRequestData');
      const response = await fetchData(`${baseUrl}/api/aspreq/${statusID}`, 'GET', localStorage.getItem('JWT'));
      const resp = await response.json();
      setTempStatus(resp.reqstatus);
      setTempData(resp);
      setServicesPicked(resp.services);
      let newLangs = [];

      if (resp.languages) {
        for (const [key, value] of Object.entries(resp.languages)) {
          if (value === true) {
            newLangs.push(key);
          }
        }
      }
      setLanguagesPicked(newLangs);
      //console.log(resp.chosenprovider, 'resp.chosenprovider');

      if (resp.chosenprovider) {
        getProviderData(resp.chosenprovider);
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, [baseUrl, statusID]);

  const reloadData = async () => {
    await getRequestData();
  };

  const getProviderData = useCallback(
    async (name) => {
      await fetchData(`${baseUrl}/api/provider/providerbyname/`, 'POST', localStorage.getItem('JWT'), {
        name: name,
      })
        .then(async (response) => {
          let resp = await response.json();
          setProviderData(resp.provider[0]);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    },
    [baseUrl, statusID]
  );

  useEffect(() => {
    getRequestData();
  }, [getRequestData]);

  useEffect(() => {
    tempData && providerData && preFillData();
  }, [providerData, tempData]);

  const preFillData = useCallback(() => {
    tempData &&
      providerData &&
      setEmailData((prevState) => ({
        ...prevState,
        customerName: tempData.companyctcname,
      }));
    tempData &&
      providerData &&
      setEmailData((prevState) => ({
        ...prevState,
        providerPhone: tempData.companyPhone,
      }));
    tempData &&
      providerData &&
      setEmailData((prevState) => ({
        ...prevState,
        providerName: providerData.provider_info.companyname,
      }));
    tempData &&
      providerData &&
      setEmailData((prevState) => ({
        ...prevState,
        providerMail: providerData.provider_info.email,
      }));
    tempData &&
      providerData &&
      setEmailData((prevState) => ({
        ...prevState,
        providerPhone: providerData.provider_info.phone || '',
      }));
  }, []);

  const sendEmail = useCallback(async () => {
    console.log(emailData, 'emailData');
    await fetchData(`${baseUrl}/api/admin/send-mail`, 'POST', localStorage.getItem('JWT'), emailData)
      .then(async (response) => {
        //let resp = await response.json();
        console.log(response);
        localStorage.removeItem('emailTempData');
        resetEmail();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl, emailData, resetEmail]);

  const updateMailLog = async (reqID, emailData) => {
    await fetchData(`${baseUrl}/api/aspreq/maillog/${reqID}`, 'PATCH', localStorage.getItem('JWT'), emailData)
      .then(async (response) => {
        let resp = await response.json();
        reloadData();
        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  //console.log(emailData, 'emailData');

  return (
    <>
      {tempData ? (
        <>
          <div className="StatusDetailsLabel">
            <Dropdown2
              label="CAF Státusz"
              id="cafstatus"
              list={cafStatusList}
              selValue={tempData.reqstatus.cafstatus || ''}
              onChange={(value) => setTempStatus({ ...tempStatus, cafstatus: value })}
            />
            <Dropdown2
              label="ASP Státusz"
              id="aspstatus"
              list={aspStatusList}
              selValue={tempData.reqstatus.aspstatus || ''}
              onChange={(value) => setTempStatus({ ...tempStatus, aspstatus: value })}
            />
            <Dropdown2
              label="REG Státusz"
              id="herstatus"
              list={herStatusList}
              selValue={tempData.reqstatus.herstatus || ''}
              onChange={(value) => setTempStatus({ ...tempStatus, herstatus: value })}
            />
            <div style={{ width: '150px', padding: '16px' }}>
              <Button
                onClick={() => updateStatusStatus(tempData._id, tempStatus)}
                style={{ float: 'right' }}
                variant="contained"
              >
                Státusz mentése
              </Button>
            </div>
          </div>
          <div className="emailParent" style={{ marginTop: '24px' }}>
            <div className="emailChild1">
              <div>
                <h3>Ajánlatkérő adatai</h3>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>Ajánlatkérő neve: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="companyctcname"
                    name="companyctcname"
                    style={{ width: '200px' }}
                    variant="standard"
                    value={tempData.companyctcname}
                  />
                </p>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>
                    Ajánlatkérő e-mail:{' '}
                  </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="email"
                    name="email"
                    style={{ width: '200px' }}
                    variant="standard"
                    value={tempData.email}
                  />
                </p>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>
                    Ajánlatkérő telefon:{' '}
                  </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="companyPhone"
                    name="companyPhone"
                    style={{ width: '200px' }}
                    variant="standard"
                    value={tempData.companyPhone}
                  />
                </p>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>Irányítószám: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="zip_code"
                    name="zip_code"
                    style={{ width: '200px' }}
                    variant="standard"
                    value={tempData.zip_code}
                  />
                </p>

                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>Egyszeri ár: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    style={{ width: '200px' }}
                    variant="standard"
                    value={tempData.originalSinglePrice}
                  />
                </p>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>Folyamatos ár: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    style={{ width: '200px' }}
                    variant="standard"
                    value={tempData.proposedContPrice}
                  />
                </p>

                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>Szolgáltatás:</span>
                  <TextField
                    variant="standard"
                    // value={servicesPicked}
                    value={servicesPicked.map(service => services.find(s => s.value === service)?.label).join(', ')}
                    style={{ width: '200px' }}
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  />
                </p>

                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>Iparág: </span>
                  <TextField
                    id="industry"
                    name="industry"
                    value={tempData.industry}
                    style={{ width: '200px' }}
                    variant="standard"
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  />
                </p>
                <p>
                  <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>Cégforma: </span>
                  <TextField
                    id="companytype"
                    name="companytype"
                    value={tempData.companytype}
                    style={{ width: '200px' }}
                    variant="standard"
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  />
                </p>
              </div>
              {providerData && (
                <div style={{ marginTop: '24px' }}>
                  <h3>Szolgáltató adatai</h3>
                  <p>
                    <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>
                      Szolgáltató neve:{' '}
                    </span>
                    <TextField
                      inputProps={{ style: { fontSize: 14, padding: 2 } }}
                      id="companyctcname"
                      name="companyctcname"
                      style={{ width: '200px' }}
                      variant="standard"
                      value={providerData.provider_info.companyname}
                    />
                  </p>
                  <p>
                    <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>
                      Szolgáltató e-mail:{' '}
                    </span>
                    <TextField
                      inputProps={{ style: { fontSize: 14, padding: 2 } }}
                      id="email"
                      name="email"
                      style={{ width: '200px' }}
                      variant="standard"
                      value={providerData.provider_info.email}
                    />
                  </p>
                  <p>
                    <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>Naptár link: </span>
                    <TextField
                      inputProps={{ style: { fontSize: 14, padding: 2 } }}
                      id="bookinglink"
                      name="bookinglink"
                      style={{ width: '200px' }}
                      variant="standard"
                      value={providerData.provider_info.bookinglink}
                    />
                  </p>
                  <p>
                    <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>
                      Szolgáltató telefon:{' '}
                    </span>
                    <TextField
                      inputProps={{ style: { fontSize: 14, padding: 2 } }}
                      id="companyPhone"
                      name="companyPhone"
                      style={{ width: '200px' }}
                      variant="standard"
                      value={providerData.provider_info.phone}
                    />
                  </p>
                  <p>
                    <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>Irányítószám: </span>
                    <TextField
                      inputProps={{ style: { fontSize: 14, padding: 2 } }}
                      id="zip_code"
                      name="zip_code"
                      style={{ width: '200px' }}
                      variant="standard"
                      value={providerData.provider_info.zip}
                    />
                  </p>

                  <p>
                    <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>Város: </span>
                    <TextField
                      inputProps={{ style: { fontSize: 14, padding: 2 } }}
                      style={{ width: '200px' }}
                      variant="standard"
                      id="city"
                      name="city"
                      value={providerData.provider_info.city}
                    />
                  </p>
                  <p>
                    <span style={{ display: 'inline-block', fontSize: '14px', width: '170px' }}>
                      Iparági tapasztalat:{' '}
                    </span>
                    <TextField
                      inputProps={{ style: { fontSize: 14, padding: 2 } }}
                      style={{ width: '200px' }}
                      variant="standard"
                      value={providerData.provider_industry_experience[tempData.industry]}
                    />
                  </p>
                </div>
              )}

              {tempData.emaillog && (
                <div style={{ marginTop: '24px' }}>
                  <h3>Emailek elkuldve:</h3>
                  {tempData.emaillog.toReversed().map((e, idx) => {
                    return (
                      <div key={idx}>
                        <p>
                          <span>{e.date}</span> <span>{e.sender}</span> <span>{e.sentMail}</span>
                        </p>
                        <hr style={{ margin: '6px 0' }} />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="emailChild2">
              <div style={{ width: '300px' }}>
                <p style={{ color: 'red' }}>Válassz kimenő e-mail fiókot!</p>
                <Dropdown
                  label="Küldő email"
                  id="senderMail"
                  list={senderMailList}
                  onChange={(value) => {
                    // setEmailData({ ...emailData, senderMail: value });
                    setEmailData((prevState) => ({ ...prevState, senderMail: value }));
                  }}
                  // selValue={emailData.signature || ''}
                  selValue={emailData.senderMail || ''}
                  style={{ width: '300px' }}
                  variant="standard"
                ></Dropdown>
              </div>
              <div style={{ width: '300px' }}>
                <p style={{ color: 'red' }}>Címzett e-mail! </p>
                <TextField
                  inputProps={{ style: { fontSize: 16, padding: 2, marginTop: 26 } }}
                  id="recipientList"
                  name="recipientList"
                  onChange={(event) => {
                    setEmailData({ ...emailData, recipientList: event.target.value });
                  }}
                  style={{ width: '300px' }}
                  variant="standard"
                  value={emailData.recipientList}
                />
              </div>
              <div style={{ width: '300px' }}>
                <p style={{ color: 'red' }}>E-mail tárgya</p>
                <TextField
                  inputProps={{ style: { fontSize: 16, padding: 2, marginTop: 26 } }}
                  id="subject"
                  name="subject"
                  onChange={(event) => {
                    setEmailData({ ...emailData, subject: event.target.value });
                  }}
                  style={{ width: '300px' }}
                  variant="standard"
                  value={emailData.subject}
                />
              </div>
            </div>

            <div className="emailChild3">
              <div className="editorContainer">
                <Tiptap
                  emailData={emailData}
                  setEmailData={setEmailData}
                  tempData={tempData}
                  providerData={providerData}
                />
              </div>
              {emailData && (
                <Button
                  disabled={isDisabled}
                  onClick={() => {
                    sendEmail();
                    updateMailLog(statusID, emailData);
                    setIsDisabled(true);
                    //signatureSet(emailData.signature);
                    resetEmail();
                  }}
                  style={{ marginTop: '24px' }}
                  variant="contained"
                  size="large"
                >
                  Küldés
                </Button>
              )}
            </div>
          </div>
        </>
      ) : (
        <div style={{ marginTop: '16px' }}>Loading...</div>
      )}
    </>
  );
};

export default Emails;

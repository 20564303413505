import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { useEffect } from 'react';
import './components.scss';

const GreenSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: 'grey !important',
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#28B446 !important',
    '&:hover': {
      backgroundColor: alpha('#28B446', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#28B446',
  },
}));

const MuiSwitch = ({ id, value, label, onChange, checkedLabel = 'Yes', unCheckedLabel = 'No', disabled }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(value ? value : false);
  }, [value]);

  const handleCheck = (event) => {
    setChecked(event.target.checked);
    onChange(event.target.id, event.target.checked);
  };

  return (
    <div className="SwitchContainer" key={id}>
      <p>{label}</p>
      <GreenSwitch
        checked={checked}
        onChange={(event) => {
          handleCheck(event);
        }}
        id={id}
        disabled={disabled}
      />
      {checked ? (
        <span style={{ color: '#28b446' }}>{checkedLabel}</span>
      ) : (
        <span style={{ color: 'grey' }}>{unCheckedLabel}</span>
      )}
    </div>
  );
};

export default MuiSwitch;

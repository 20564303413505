import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    <>
      <Box sx={{ width: 40, height: 40, ...sx }}>
        <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_210:313)">
            <path
              d="M22.9995 23.6871C22.0731 24.5665 21.0317 25.3161 19.9038 25.9155L24.0226 30.9786C24.166 31.1526 24.3709 31.2647 24.5947 31.2914C24.8186 31.3182 25.0441 31.2576 25.2244 31.1223L26.845 29.8959C26.9369 29.825 27.0138 29.7365 27.0712 29.6356C27.1287 29.5347 27.1655 29.4234 27.1795 29.3082C27.1935 29.1929 27.1845 29.076 27.1529 28.9643C27.1213 28.8526 27.0679 28.7482 26.9957 28.6573L22.9995 23.6871Z"
              fill="#2D46B9"
            />
            <path
              d="M19.5254 9.01459L15.0159 9.04788L10.7324 12.981V4.98514C9.31615 5.38349 8.02468 6.13594 6.97974 7.17156V18.8254C7.99031 19.9427 9.28844 20.7609 10.7324 21.1906V17.4326L12.0341 16.1555L15.9742 20.9978C17.1192 20.5608 18.1523 19.8737 18.998 18.9866L14.7934 13.7729L19.5254 9.01459Z"
              fill="#2D46B9"
            />
            <path
              d="M13.0501 0.0797124C10.4692 0.0804053 7.94639 0.846374 5.80076 2.28077C3.65513 3.71516 1.983 5.75356 0.995804 8.13823C0.00860423 10.5229 -0.249333 13.1467 0.254607 15.678C0.758546 18.2093 2.00173 20.5342 3.82697 22.359C5.65221 24.1837 7.97754 25.4263 10.5089 25.9296C13.0403 26.4328 15.6641 26.1742 18.0485 25.1863C20.4329 24.1985 22.4708 22.5258 23.9047 20.3798C25.3385 18.2338 26.1038 15.7108 26.1038 13.1299C26.1038 11.4158 25.7661 9.71854 25.1101 8.13499C24.454 6.55144 23.4924 5.11265 22.2802 3.90078C21.068 2.68891 19.629 1.72772 18.0453 1.07209C16.4615 0.416465 14.7642 0.0792522 13.0501 0.0797124V0.0797124ZM13.0501 22.8549C11.1252 22.8559 9.24322 22.286 7.64228 21.2172C6.04134 20.1485 4.79337 18.6289 4.05626 16.8507C3.31915 15.0725 3.12602 13.1156 3.50129 11.2276C3.87657 9.33967 4.80339 7.60542 6.16451 6.2443C7.52563 4.88319 9.25988 3.95636 11.1479 3.58109C13.0358 3.20581 14.9927 3.39894 16.7709 4.13606C18.5491 4.87317 20.0687 6.12113 21.1374 7.72207C22.2062 9.32301 22.7761 11.205 22.7751 13.1299C22.7742 15.7088 21.7493 18.1819 19.9257 20.0055C18.1021 21.8291 15.629 22.854 13.0501 22.8549V22.8549Z"
              fill="#2D46B9"
            />
          </g>
          <defs>
            <clipPath id="clip0_210:313">
              <rect width="27.3171" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Box>
    </>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

export const signatureList = [
  {
    label: 'Andi',
    id: 'Andi',
  },
  {
    label: 'Nina',
    id: 'Nina',
  },
  {
    label: 'Zsófi',
    id: 'Zsófi',
  },
  {
    label: 'Orsi',
    id: 'Orsi',
  },
  {
    label: 'Dia',
    id: 'Dia',
  },
  {
    label: 'Andris',
    id: 'Andris',
  },
  {
    label: 'Info',
    id: 'Info',
  },
];

export const templateList = [
  { label: 'Adatbekérő visszaküldése', id: 'Admin - Customer - requestDataBack' },
  { label: 'Adótanácsadás szolgáltatás MEHET', id: 'Admin - Provider - taxConCanHappen' },
  { label: 'ASP első számla szerződés után', id: 'Admin - Customer - firstinvoiceaftercontract' },
  { label: 'ASP Follow-up ügyféllel NV', id: 'Admin - Customer - satisfactionaftertalkwithsp' },
  { label: 'ASP NV utáni email', id: 'Admin - Customer - aspcouldntreach' },
  { label: 'ASP szerződés adatbekérő form', id: 'Admin - Customer - contractdatarequestform' },
  { label: 'ASP ügyfél felmondta - szerződés lezárás', id: 'Admin - Customer - aspendcontract' },
  { label: 'Az Ön könyvelője', id: 'Admin - Customer - yourprovider' },
  { label: 'Bemutatkozó anyag', id: 'Admin - Provider - introductiontoprovider' },
  { label: 'BK REG folyamat -Bt', id: 'Admin - Customer - bkregbt' },
  { label: 'BK REG folyamat -EV', id: 'Admin - Customer - bkregev' },
  { label: 'BK REG folyamat -eKFT', id: 'Admin - Customer - bkregekft' },
  { label: 'BK REG folyamat -tKFT', id: 'Admin - Customer - bkregtkft' },
  { label: 'Bookkeepie - Ajánlatkérések', id: 'Admin - Provider - sendingrfpdetails' },
  { label: 'BUPA érdeklődés - EV', id: 'Admin - Customer - BupaIntro' },
  { label: 'BUPA érdeklődés - KFT', id: 'Admin - Customer - bupakftinfo' },
  { label: 'CAF - NV után email', id: 'Admin - Customer - cafcouldntreach' },
  { label: 'CAF - ÜF-t pingelni', id: 'Admin - Customer - customerPing' },
  { label: 'CAF után könyvelőtől visszajelzés kérés', id: 'Admin - Provider - requestUpdate' },
  { label: 'Cégforma véglegesítés', id: 'Admin - Provider - caffinalize' },
  { label: 'E-ker - adatbekérő', id: 'Admin - Customer - eTradeFormRequest' },
  { label: 'Értékelés kérése', id: 'Admin - Customer - requestforreview' },
  { label: 'Értékelés támpontok', id: 'Admin - Customer - reviewguide' },
  { label: 'Egyedi árajánlat', id: 'Admin - Customer - individiualoffer' },
  { label: 'Egyedi ár OK, keresse az ügyfelet', id: 'Admin - Provider - priceOk' },
  { label: 'Indulhat az EV', id: 'Admin - Provider - readytostartev' },
  { label: 'Kifizetetlen CAF számla', id: 'Admin - Customer - cafunpaid' },
  { label: 'Könyvelési számla fizetése', id: 'Admin - Customer - accountingunpaid' },
  { label: 'Könyvelő kereső link', id: 'Admin - Customer - searchflowlink' },
  { label: 'Könyvelő felé email összekötés EV indító ügyféllel', id: 'Admin - Provider - connectcusttosp' },
  { label: 'Könyvelői szerződés', id: 'Admin - Provider - sendingContract' },
  { label: 'Könyvelőnek visszajelzés - ügyfél OK', id: 'Admin - Provider - proposalaccepted' },
  { label: 'Könyvelőnek visszajelzés - ügyfél szerződés készül', id: 'Admin - Provider - contractinprogress' },
  { label: 'Lejárt szerződés hosszabbítás értesítés NV után', id: 'Admin - Customer - contractexpired' },
  { label: 'Lezáró email', id: 'Admin - Customer - noCtcClosing' },
  { label: 'Megkezdett regi/webes nézelődés', id: 'Admin - Customer - browsing' },
  { label: 'Megrendelő könyvelőnek', id: 'Admin - Provider - goodToGo' },
  { label: 'Regisztrációs link', id: 'Admin - Provider - reglink' },
  { label: 'REG EV alapítás időpontegyeztetés', id: 'Admin - Customer - matchmaking keresőnek' },
  { label: 'REG EV alapítás könyvelőnek konzultáció kérése', id: 'Admin - Provider - matchmaking könyvelőnek' },
  { label: 'REG Start után NV', id: 'Admin - Customer - regnoreply' },
  { label: 'REG számla kiment ügyfélre vár', id: 'Admin - Customer - regunpaid' },
  { label: 'REG visszajelzés kérés', id: 'Admin - Customer - regrequestDataBack' },
  { label: 'Szerződés aláíratás', id: 'Admin - Customer - contractsign' },
  { label: 'Szerződés visszaküldése', id: 'Admin - Customer - requestContractBack' },
  { label: 'Számlázási adatok', id: 'Admin - Customer - accountrequestform' },
  { label: 'Szolgáltatás felfüggesztése - könyvelő', id: 'Admin - Provider - servicesuspension' },
  { label: 'Szolgáltatás felfüggesztése - ügyfél', id: 'Admin - Customer - servicesuspension' },
  { label: 'Szolgáltatás felmondása - könyvelő', id: 'Admin - Provider - servicetermination' },
  { label: 'Szolgáltatás felmondása - ügyfél', id: 'Admin - Customer - servicetermination' },
  { label: 'Szolgáltatás újraindítása - könyvelő', id: 'Admin - Provider - servicerestart' },
  { label: 'Szolgáltatás újraindítása - ügyfél', id: 'Admin - Customer - servicerestart' },
  { label: 'Több céges ajánlatkérés', id: 'Admin - Customer - multiplecompanyreq' },
  { label: 'Új ügyfél könyvelőknek', id: 'Admin - Provider - newcustomer' },
  { label: 'Új ügyfél kiosztás', id: 'Admin - Provider - newcafcustomer' },
  { label: 'Ügyfél elérés CAF nélkül, beszéltek?', id: 'Admin - Provider - wascontactdone' },
  { label: 'Ügyfél értékelés sablon', id: 'Admin - Provider - reviewtemplate' },
  { label: 'Ügyfél felé email EV indításhoz ő a könyvelője', id: 'Admin - Customer - yourprovidertostartev' },
  { label: 'Ügyfél visszajelzés kérés/2. levél', id: 'Admin - Customer - responserequest2' },
  { label: 'Üres e-mail', id: 'Admin - Customer - emptyMail' },
  { label: 'VAF kérés után customer email', id: 'Admin - Customer - aftervafreq' },
  { label: 'VAF után keresőnek info', id: 'Admin - Customer - vafdoneinfos' },
  { label: 'Verbal yes update', id: 'Admin - Provider - verbalconfirmation' },
  { label: 'Visszajelzés - igényli még a szolgáltatást?', id: 'Admin - Customer - isstillinterested' },
  { label: 'Visszajelzés adótanácsadás után/CAF', id: 'Admin - Customer - cafdoneisaccountatnneeded' },
  { label: 'Visszajelzés kérése az adatbekérő kapcsán/EV indítás', id: 'Admin - Customer - evformrequest' },
  { label: 'Visszajelzés megköszönése', id: 'Admin - Customer - acknowledgement' },
];

export const senderMailList = [
  { label: 'Andi', id: 'andrea@bookkeepie.com' },
  { label: 'Nina', id: 'nina@bookkeepie.com' },
  { label: 'Zsófi', id: 'zsofi@bookkeepie.com' },
  { label: 'Orsi', id: 'orsi@bookkeepie.com' },
  { label: 'Info', id: 'info-hu@bookkeepie.com' },
  { label: 'Andris', id: 'andras@bookkeepie.com' },
  { label: 'Dia', id: 'dia@bookkeepie.com' },
];

export const subjectSetter = (template) => {
  let subject = '';
  switch (template) {
    case 'Admin - Customer - bkregev':
      subject = 'Egyéni vállalkozás indítása | Bookkeepie';
      break;
    case 'Admin - Customer - bkregekft':
      subject = 'Egyszemélyes Kft alapítása | Bookkeepie';
      break;
    case 'Admin - Customer - bkregtkft':
      subject = 'Többszemélyes Kft alapítása | Bookkeepie';
      break;
    case 'Admin - Customer - bkregbt':
      subject = 'Bt alapítása | Bookkeepie';
      break;
    case 'Admin - Customer - BupaIntro':
      subject = 'Kedvezményes vállalkozásindítás - BUPA -Bokkeepie könyvelési szolgáltatás';
      break;
    case 'Admin - Customer - emptyMail':
      subject = '';
      break;
    case 'Admin - Customer - noCtcClosing':
      subject = 'Bookkeepie keresés törlése';
      break;
    case 'Admin - Customer - eTradeFormRequest':
      subject = 'Egyedi e-keres árajánlat adatbekérő - Boookeepie könyvelési szolgáltatás';
      break;
    case 'Admin - Customer - bupakftinfo':
      subject = 'Kedvezményes cégalapítás - MBH bank tájékoztató';
      break;
    case 'Admin - Customer - requestDataBack':
      subject = 'Emlékeztető - adatbekérő visszaküldése';
      break;
    case 'Admin - Customer - regrequestDataBack':
      subject = 'Aktuális még a vállalkozás indítás? | Bookkeepie';
      break;
    case 'Admin - Customer - requestContractBack':
      subject = 'Emlékeztető - könyvelői szerződés aláírás - Bookeepie';
      break;
    case 'Admin - Provider - taxConCanHappen':
      subject = '';
      break;
    case 'Admin - Customer - vafdoneinfos':
      subject = 'Adótanácsadás szolgáltatás eredménye';
      break;
    case 'Admin - Provider - requestUpdate':
      subject = 'Ügyfél elérés kapcsán visszajelzés | Bookkeepie';
      break;
    case 'Admin - Customer - customerPing':
      subject = 'Emlékeztető! - Az Ön könyvelője';
      break;
    case 'Admin - Customer - regunpaid':
      subject = 'Vállalkozás indítás számla - Bookeepie';
      break;
    case 'Admin - Provider - contractinprogress':
      subject = 'Ügyféllel kapcsolatos visszajelzés | Bookkeepie';
      break;
    case 'Admin - Provider - readytostartev':
      subject = 'Kérlek egyeztesssetek időpontot EV indításra | Bookkeepie';
      break;
    case 'Admin - Provider - goodToGo':
      subject = 'Megrendelő könyvelési szolgáltatásra - Bookkeepie ügyfél';
      break;
    case 'Admin - Provider - sendingContract':
      subject = 'Ügyféllel kapcsolatos visszajelzés | Bookkeepie';
      break;
    case 'Admin - Customer - individiualoffer':
      subject = 'Webáruház könyvelés egyedi árajánlat - Bookkeepie';
      break;
    case 'Admin - Customer - yourprovider':
      subject = 'Az Ön könyvelője';
      break;
    case 'Admin - Customer - yourprovidertostartev':
      subject = 'Egyéni vállalkozás indítása - következő lépés | Bookkeepie';
      break;
    case 'Admin - Provider - introductiontoprovider':
      subject = '';
      break;
    case 'Admin - Provider - sendingrfpdetails':
      subject = '';
      break;
    case 'Admin - Provider - caffinalize':
      subject = '';
      break;
    case 'Admin - Provider - priceOk':
      subject = 'Ügyféllel kapcsolatos visszajelzés | Bookkeepie';
      break;
    case 'Admin - Provider - connectcusttosp':
      subject = 'Új EV indító ügyfél - kérlek egyeztessetek időpontot | Bookkeepie';
      break;
    case 'Admin - Provider - proposalaccepted':
      subject = 'Ügyfél ajánlattal kapcsolatos visszajelzés | Bookkeepie';
      break;
    case 'Admin - Customer - requestforreview':
      subject = '';
      break;
    case 'Admin - Customer - reviewguide':
      subject = '';
      break;
    case 'Admin - Customer - accountingunpaid':
      subject = 'Kifizetetlen számla - könyvelési szolgáltatás- Bookkeepie';
      break;
    case 'Admin - Customer - cafunpaid':
      subject = 'Számla tartozás - Adótanácsadás konzultáció - visszajelzés kérése';
      break;
    case 'Admin - Customer - searchflowlink':
      subject = 'Bookkeepie könyvelő kereső';
      break;
    case 'Admin - Customer - contractexpired':
      subject = '';
      break;
    case 'Admin - Customer - browsing':
      subject = '';
      break;
    case 'Admin - Customer - aspcouldntreach':
      subject = 'Időpontfoglalás - Könyvelő keresés - Bookkeepie könyvelési szolgáltatás';
      break;
    case 'Admin - Customer - cafcouldntreach':
      subject = 'FONTOS! Könyvelő keresés (Bookkeepie)';
      break;
    case 'Admin - Customer - regnoreply':
      subject = 'FONTOS! Vállalkozás indítás (Bookkeepie)';
      break;
    case 'Admin - Provider - reglink':
      subject = '';
      break;
    case 'Admin - Customer - accountrequestform':
      subject = '';
      break;
    case 'Admin - Customer - contractdatarequestform':
      subject = 'Bookkeepie - Könyvelési ajánlat és adatlap a megállapodáshoz';
      break;
    case 'Admin - Customer - firstinvoiceaftercontract':
      subject = 'Bookkeepie - Szerződés aláírás utáni számlázás egyeztetése';
      break;
    case 'Admin - Customer - contractsign':
      subject = 'Bookeepie könyvelési szerződés aláírás';
      break;
    case 'Admin - Customer - multiplecompanyreq':
      subject = '';
      break;
    case 'Admin - Customer - satisfactionaftertalkwithsp':
      subject = 'Könyvelési szolgáltatás - Elégedett volt? - visszajelzés kérése';
      break;
    case 'Admin - Provider - wascontactdone':
      subject = 'Ügyfél elérés kapcsán visszajelzés | Bookkeepie';
      break;
    case 'Admin - Provider - reviewtemplate':
      subject = '';
      break;
    case 'Admin - Customer - responserequest2':
      subject = 'Visszajelzés kérése - konzultáció könyvelőnkkel';
      break;
    case 'Admin - Provider - newcafcustomer':
      subject = 'Új ügyfél adatai | Bookkeepie';
      break;
    case 'Admin - Provider - newcustomer':
      subject = '';
      break;
    case 'Admin - Provider - verbalconfirmation':
      subject = '';
      break;
    case 'Admin - Customer - isstillinterested':
      subject = 'Visszajelzés kérése - Bookkeepie könyvelési szolgáltatás';
      break;
    case 'Admin - Customer - cafdoneisaccountatnneeded':
      subject = 'Visszajelzés kérése - Bookkeepie könyvelési szolgáltatás';
      break;
    case 'Admin - Customer - evformrequest':
      subject = 'Visszajelzés ügyfél elérés kapcsán | Bookkeepie';
      break;
    case 'Admin - Customer - acknowledgement':
      subject = 'Köszönjük visszajelzését!';
      break;
    case 'Admin - Customer - aspendcontract':
      subject = 'Bookkeepie szerződés megszűnése';
      break;
    case 'Admin - Customer - matchmaking keresőnek':
      subject = 'Vállalkozás alapítás Bookkeepie-vel - időpontegyeztetés';
      break;
    case 'Admin - Provider - matchmaking könyvelőnek':
      subject = 'Egyéni vállalkozás alapítás';
      break;
    case 'Admin - Customer - servicesuspension':
      subject = 'Könyvelési szolgáltatása felfüggesztésre került | Bookkeepie';
      break;
    case 'Admin - Provider - servicesuspension':
      subject = 'Könyvelési szolgáltatás felfüggesztése | Bookkeepie';
      break;
    case 'Admin - Customer - servicerestart':
      subject = 'Könyvelési szolgáltatás folytatódhat | Bookkeepie';
      break;
    case 'Admin - Provider - servicerestart':
      subject = 'Könyvelési szolgáltatás folytatódhat | Bookkeepie';
      break;
    case 'Admin - Customer - servicetermination':
      subject = 'Könyvelési szerződés felmondása | Bookkeepie';
      break;
    case 'Admin - Provider - servicetermination':
      subject = 'Ügyfél könyvelési szerződés felmondás | Bookkeepie';
      break;
    default:
      subject = '';
  }
  return subject;
};

export const cafStatusList = [
  { label: 'Start', id: 'Start' },
  { label: 'Később keresni', id: 'Később keresni' },
  { label: 'Igény validálás', id: 'Igény validálás' },
  { label: 'Számla kiment, ügyfélre vár', id: 'Számla kiment, ügyfélre vár' },
  { label: 'Könyvelőt választani', id: 'Könyvelőt választani' },
  { label: 'Könyvelő kiválasztva', id: 'Könyvelő kiválasztva' },
  { label: 'Follow up könyvelővel', id: 'Follow up könyvelővel' },
  { label: 'Follow up ügyféllel', id: 'Follow up ügyféllel' },
  { label: 'Sikeresen lezárva', id: 'Sikeresen lezárva' },
  { label: 'Ügyfél eltűnt', id: 'Ügyfél eltűnt' },
  { label: 'n/a', id: 'n/a' },
];
export const aspStatusList = [
  { label: 'Start', id: 'Start' },
  { label: 'Később keresni', id: 'Később keresni' },
  { label: 'Igény validálása', id: 'Igény validálása' },

  { label: 'Könyvelőt választani', id: 'Könyvelőt választani' },
  { label: 'Könyvelő kiválasztva', id: 'Könyvelő kiválasztva' },
  { label: 'Egyedi árat kértünk, follow-up könyvelővel', id: 'Egyedi árat kértünk, follow-up könyvelővel' },
  { label: 'REG várakozás', id: 'REG várakozás' },
  { label: 'Follow up könyvelővel', id: 'Follow up könyvelővel' },
  { label: 'Follow up ügyféllel', id: 'Follow up ügyféllel' },
  { label: 'Formot visszaküldetni', id: 'Formot visszaküldetni' },
  { label: 'Szerződést aláíratni', id: 'Szerződést aláíratni' },
  { label: 'Számlát küldeni', id: 'Számlát küldeni' },
  { label: 'Számla kiment, ügyfélre vár', id: 'Számla kiment, ügyfélre vár' },
  { label: 'Könyvelőnek megrendelőt küldeni', id: 'Könyvelőnek megrendelőt küldeni' },
  { label: 'Ügyfél örömködés', id: 'Ügyfél örömködés' },
  { label: 'Ügyfél eltűnt', id: 'Ügyfél eltűnt' },
  { label: 'Ügyfél eltűnt, könyvelőnek jelezni', id: 'Ügyfél eltűnt, könyvelőnek jelezni' },
  { label: 'Szerződés szünetel', id: 'Szerződés szünetel' },
  { label: 'Ügyfél lemondta a szolgáltatást', id: 'Ügyfél lemondta a szolgáltatást' },
  { label: 'BK felmondta a szerződést', id: 'BK felmondta a szerződést' },
  { label: 'Sikeresen lezárva', id: 'Sikeresen lezárva' },
  { label: 'n/a', id: 'n/a' },
];
export const herStatusList = [
  { label: 'Start', id: 'Start' },
  { label: 'Igény validálása', id: 'Igény validálása' },
  { label: 'Számla kiment, ügyfélre vár', id: 'Számla kiment, ügyfélre vár' },
  { label: 'Könyvelőt választani', id: 'Könyvelőt választani' },
  { label: 'Könyvelő kiválasztva', id: 'Könyvelő kiválasztva' },
  { label: 'Szerződés folyamatban', id: 'Szerződés folyamatban' },
  { label: 'Formot visszaküldetni', id: 'Formot visszaküldetni' },
  { label: 'EV indítás fizetve, de későbbre kéri', id: 'EV indítás fizetve, de későbbre kéri' },
  { label: 'Dokumentumokat generálni', id: 'Dokumentumokat generálni' },
  { label: 'Dokumentumok  ügyfélnek', id: 'Dokumentumok  ügyfélnek' },
  { label: 'Dokumentumok  üf-nek OK', id: 'Dokumentumok  üf-nek OK' },
  { label: 'EV indulhat', id: 'EV indulhat' },
  { label: 'Alapítás kész', id: 'Alapítás kész' },
  { label: 'Follow up ügyféllel', id: 'Follow up ügyféllel' },
  { label: 'Follow-up könyvelővel', id: 'Follow-up könyvelővel' },
  { label: 'Ügyfél eltűnt', id: 'Ügyfél eltűnt' },
  { label: 'Sikeresen lezárva', id: 'Sikeresen lezárva' },
  { label: 'n/a', id: 'n/a' },
];

import React, { useEffect, useState, useContext } from 'react';
import MUIDataTable from 'mui-datatables';
import { columns } from './couponsTableConfig';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData } from 'src/fetch';

const CouponsTable = ({ coupons }) => {
  const [tableData, setTableData] = useState([]);
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;

  useEffect(() => {
    let temp = [];
    if (coupons) {
      console.log(coupons, typeof coupons, 'KUPONZ');
      coupons.forEach((coupon) => {
        temp.push({
          couponCode: coupon.couponCode,
          discount: coupon.singleDeduction === true ? coupon.discount : `${coupon.discount}%`,
          discountLarge: coupon.discountLarge ? coupon.discountLarge : '',
          singleDeduction: coupon.singleDeduction === true ? 'Yes' : 'No',
          flow: coupon.flow,
          companyType: coupon.companyType,
          referral: coupon.referral ? 'Yes' : 'No',
          couponID: coupon._id,
        });
      });
    }
    setTableData(temp);
  }, [coupons]);

  const deleteCoupons = async (couponsToDelete) => {
    await fetchData(`${baseUrl}/api/coupons/delete`, 'DELETE', localStorage.getItem('JWT'), {
      ids: couponsToDelete,
    })
      .then(async (response) => {})
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const options = {
    filterType: 'checkbox',
    rowHover: true,
    selectableRows: 'multiple',
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      let temp = [];
      if (rowsSelected.length > 0) {
        rowsSelected.forEach((index) => {
          temp.push(tableData[index].couponID);
        });
      }
      setSelectedCoupons(temp);
    },
    onRowsDelete: () => {
      deleteCoupons(selectedCoupons);
    },
  };

  return (
    <div>
      <MUIDataTable data={tableData} columns={columns} options={options} />
    </div>
  );
};

export default CouponsTable;

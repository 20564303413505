export const profileTabs = [
  { label: 'Fiókbeállítások', id: 'account' },
  { label: 'Csomagbeállítások', id: 'package' },
  { label: 'Profil', id: 'profile' },
  { label: 'Szolgáltatás', id: 'services' },
  { label: 'Szűrők', id: 'restrictions' },
  { label: 'Speciális Szűrők', id: 'specrestrictions' },
  { label: 'Bank és számlázó', id: 'bankinvosoft' },
  { label: 'Árazás', id: 'pricing' },
  { label: 'Ismeretek', id: 'experience' },
  { label: 'Értékelések', id: 'reviews' },
  { label: 'Minősítések', id: 'qualifications' },
  { label: 'Előfizetés', id: 'subscription' },
];

export const accountSwitchInputs = [
  { label: 'Aktivált fiók', id: 'confirmed' },
  { label: 'Aktív előfizetés', id: 'provider_subscription.activeSubscription' },
  { label: 'Befejezett regisztráció', id: 'finishedRegistration' },
];

export const accountTextInputs = [
  { label: 'Account email', id: 'email' },
  { label: 'Subscription status', id: 'provider_subscription.subscriptionStatus' },
];

export const packageInputs = [
  {
    label: 'Előfizetés típusa',
    id: 'yearly',
    modelKey: 'provider_package',
    options: [
      { label: 'Éves', value: true },
      { label: 'Féléves', value: false },
    ],
  },
  {
    label: 'Csomag típusa',
    id: 'packageType',
    modelKey: 'provider_package',
    options: [
      { label: 'Belépő', value: 'basic' },
      { label: 'Marketing', value: 'advanced' },
      { label: 'Ügyfélgarancia', value: 'premium' },
    ],
  },
  {
    label: 'Csomag mérete',
    id: 'packageSize',
    modelKey: 'provider_package',
    options: [
      { label: 'Egyéni csomag', value: 'entrepreneur' },
      { label: 'Kis irodai csomag', value: 'small_office' },
      { label: 'Irodai csomag', value: 'office' },
      { label: 'Vállalati csomag', value: 'corporate' },
    ],
  },
];

export const contactInputs = [
  { label: 'Email', id: 'email', modelKey: 'provider_info' },
  { label: 'Cégnév', id: 'companyname', modelKey: 'provider_info' },
  { label: 'Adószám', id: 'taxnumber', modelKey: 'provider_info' },
  { label: 'Létszám', id: 'noofemployees', modelKey: 'provider_info' },
  { label: 'Telefonszám', id: 'phone', modelKey: 'provider_info' },
  { label: 'Irányítószám', id: 'zip', modelKey: 'provider_info' },
  { label: 'Város', id: 'city', modelKey: 'provider_info' },
  { label: 'Utca, házszám', id: 'address', modelKey: 'provider_info' },
];

export const secondaryAddressInputs = [
  { label: 'Irányítószám (másodlagos)', id: 'contactzip', modelKey: 'provider_contact' },
  { label: 'Város (másodlagos)', id: 'contactcity', modelKey: 'provider_contact' },
  { label: 'Utca, házszám (másodlagos)', id: 'contactaddress', modelKey: 'provider_contact' },
];

export var languagesInputs = [
  { label: 'Angol', id: 'english', modelKey: 'provider_languages' },
  { label: 'Német', id: 'german', modelKey: 'provider_languages' },
  { label: 'Francia', id: 'french', modelKey: 'provider_languages' },
  { label: 'Kínai', id: 'chinese', modelKey: 'provider_languages' },
  { label: 'Orosz', id: 'russian', modelKey: 'provider_languages' },
  { label: 'Román', id: 'romanian', modelKey: 'provider_languages' },
  { label: 'Spanyol', id: 'spanish', modelKey: 'provider_languages' },
];

export const servicesInputs = [
  { label: 'Könyvelés', id: 'accounting', modelKey: 'provider_services' },
  { label: 'Bérszámfejtés', id: 'payroll', modelKey: 'provider_services' },
  { label: 'EFO Bérszámfejtés', id: 'simplifiedpayroll', modelKey: 'provider_services' },
  { label: 'Adótanácsadás', id: 'taxconsulting', modelKey: 'provider_services' },
  {
    label: 'Adótanácsadás (új cégeknek)',
    id: 'taxconsultingsimple',
    modelKey: 'provider_services',
  },
  { label: 'Adótanácsadás (már működő cégeknek)', id: 'taxconsultingcomplex', modelKey: 'provider_services' },
  { label: 'Könyvvizsgálat', id: 'audit', modelKey: 'provider_services' },
  { label: 'Végelszámolás', id: 'liquidation', modelKey: 'provider_services' },
];

export const liabilityInsInputs = [
  { label: 'Könyvelés', id: 'accounting', modelKey: 'provider_liability_insurance' },
  { label: 'Bérszámfejtés', id: 'payroll', modelKey: 'provider_liability_insurance' },
  { label: 'Adótanácsadás', id: 'taxconsulting', modelKey: 'provider_liability_insurance' },
  { label: 'Könyvvizsgálat', id: 'audit', modelKey: 'provider_liability_insurance' },
  { label: 'Végelszámolás', id: 'liquidation', modelKey: 'provider_liability_insurance' },
];
export const extraInfoInputs = [
  { label: 'Direkt megkeresés', id: 'directcontact', modelKey: 'provider_info' },
  { label: 'Ad kedvezményt', id: 'discounts', modelKey: 'provider_info' },
];

export const bankingInput = [
  {
    label: 'OTP',
    id: 'opt1',
    // value: 'opt1',
    modelKey: 'provider_banking_filter',
  },
  {
    label: 'Erste',
    id: 'opt3',
    // value: 'opt3',
    modelKey: 'provider_banking_filter',
  },
  {
    label: 'MBH',
    id: 'opt2',
    // value: 'opt2',
    modelKey: 'provider_banking_filter',
  },
  {
    label: 'Raiffeisen',
    id: 'opt4',
    // value: 'opt4',
    modelKey: 'provider_banking_filter',
  },
  {
    label: 'UniCredit',
    id: 'opt5',
    // value: 'opt5',
    modelKey: 'provider_banking_filter',
  },
  {
    label: 'K&H',
    id: 'opt6',
    // value: 'opt6',
    modelKey: 'provider_banking_filter',
  },
  {
    label: 'Magnet',
    id: 'opt7',
    // value: 'opt7',
    modelKey: 'provider_banking_filter',
  },
  {
    label: 'CIB',
    id: 'opt8',
    // value: 'opt8',
    modelKey: 'provider_banking_filter',
  },
  {
    label: 'Egyéb',
    id: 'other',
    // value: 'other',
    modelKey: 'provider_banking_filter',
  },
];

export const invoicingInput = [
  {
    label: 'Számlázz.hu',
    id: 'opt1',
    // value: 'opt1',
    modelKey: 'provider_invoicing_filter',
  },
  {
    label: 'Billingo',
    id: 'opt2',
    // value: 'opt2',
    modelKey: 'provider_invoicing_filter',
  },
  {
    label: 'Billcity',
    id: 'opt3',
    // value: 'opt3',
    modelKey: 'provider_invoicing_filter',
  },
  {
    label: 'Billsoft',
    id: 'opt7',
    // value: 'opt7',
    modelKey: 'provider_invoicing_filter',
  },
  {
    label: 'SAP',
    id: 'opt4',
    // value: 'opt4',
    modelKey: 'provider_invoicing_filter',
  },
  {
    label: 'NAV Online Számla',
    id: 'opt6',
    // value: 'opt6',
    modelKey: 'provider_invoicing_filter',
  },
  {
    label: 'BUPA',
    id: 'opt5',
    // value: 'opt5',
    modelKey: 'provider_invoicing_filter',
  },
  {
    label: 'Egyéb',
    id: 'other',
    // value: 'other',
    modelKey: 'provider_invoicing_filter',
  },
];

export const softwareInput = [
  // { label: 'Apollo', id: 'opt1', value: 'opt1', modelKey: 'provider_software_filter' },
  // { label: 'Cobra', id: 'opt2', value: 'opt2', modelKey: 'provider_software_filter' },
  // { label: 'ComboSoft', id: 'opt3', value: 'opt3', modelKey: 'provider_software_filter' },
  // { label: 'Forint-Soft', id: 'opt4', value: 'opt4', modelKey: 'provider_software_filter' },
  // { label: 'Hessyn', id: 'opt5', value: 'opt5', modelKey: 'provider_software_filter' },
  // { label: 'IMA', id: 'opt6', value: 'opt6', modelKey: 'provider_software_filter' },
  // { label: 'Infotéka', id: 'opt7', value: 'opt7', modelKey: 'provider_software_filter' },
  // { label: 'Kulcs-Szoft', id: 'opt8', value: 'opt8', modelKey: 'provider_software_filter' },
  // { label: 'Libra', id: 'opt9', value: 'opt9', modelKey: 'provider_software_filter' },
  {
    label: 'Makrodigit',
    id: 'opt10',
    // value: 'opt10',
    modelKey: 'provider_software_filter',
  },
  {
    label: 'Maxi-Tax',
    id: 'opt11',
    // value: 'opt11',
    modelKey: 'provider_software_filter',
  },
  {
    label: 'Nagy Machinátor',
    id: 'opt12',
    // value: 'opt12',
    modelKey: 'provider_software_filter',
  },
  {
    label: 'Novitax',
    id: 'opt13',
    // value: 'opt13',
    modelKey: 'provider_software_filter',
  },
  {
    label: 'QualitySoft',
    id: 'opt14',
    // value: 'opt14',
    modelKey: 'provider_software_filter',
  },
  {
    label: 'Relax',
    id: 'opt15',
    // value: 'opt15',
    modelKey: 'provider_software_filter',
  },
  {
    label: 'RLB',
    id: 'opt16',
    // value: 'opt16',
    modelKey: 'provider_software_filter',
  },
  {
    label: 'SAP',
    id: 'opt17',
    // value: 'opt17',
    modelKey: 'provider_software_filter',
  },
  {
    label: 'Smartbooks',
    id: 'opt18',
    // value: 'opt18',
    modelKey: 'provider_software_filter',
  },
  {
    label: 'TenSoft',
    id: 'opt19',
    // value: 'opt19',
    modelKey: 'provider_software_filter',
  },
  {
    label: 'Webkönyvelő',
    id: 'opt20',
    // value: 'opt20',
    modelKey: 'provider_software_filter',
  },
  // { label: 'Egyéb', id: 'other', value: 'other', modelKey: 'provider_software_filter' },
];

export const providerCompanyIndustryList = [
  { label: 'Építőipar', id: 'construction', modelKey: 'provider_industry_restrictions' },
  { label: 'Gépjármű Ipar', id: 'vehicle', modelKey: 'provider_industry_restrictions' },
  { label: 'Gyártás és Feldolgozó Ipar', id: 'manufacturing', modelKey: 'provider_industry_restrictions' },
  { label: 'Informatika', id: 'IT', modelKey: 'provider_industry_restrictions' },
  { label: 'Ingatlankereskedelem', id: 'realestate', modelKey: 'provider_industry_restrictions' },
  //{ label: 'Kis-es nagykereskedelem', id: 'trade', modelKey: 'provider_industry_restrictions' },
  { label: 'Kiskereskedelem', id: 'retail', modelKey: 'provider_industry_restrictions' },
  { label: 'Nagykereskedelem', id: 'wholesale', modelKey: 'provider_industry_restrictions' },
  { label: 'Mező-es erdőgazdálkodás, halászat', id: 'agriculture', modelKey: 'provider_industry_restrictions' },
  { label: 'Oktatás, Kutatás', id: 'education', modelKey: 'provider_industry_restrictions' },
  { label: 'Művészet', id: 'art', modelKey: 'provider_industry_restrictions' },
  { label: 'Szépségipar', id: 'beauty', modelKey: 'provider_industry_restrictions' },
  { label: 'Egészségügy', id: 'medicine', modelKey: 'provider_industry_restrictions' },
  { label: 'Pénzügy, biztosítás', id: 'finance', modelKey: 'provider_industry_restrictions' },
  { label: 'Szállás, Vendéglátás', id: 'travel', modelKey: 'provider_industry_restrictions' },
  { label: 'Szállítás, raktározás', id: 'logistics', modelKey: 'provider_industry_restrictions' },
  { label: 'Futár', id: 'courier', modelKey: 'provider_industry_restrictions' },
  { label: 'Egyéb', id: 'otherservices', modelKey: 'provider_industry_restrictions' },
  { label: 'E-kereskedelem', id: 'etrade', modelKey: 'provider_industry_restrictions' },
  { label: 'Start up', id: 'startup', modelKey: 'provider_industry_restrictions' },
  { label: 'Ügyvédi iroda', id: 'lawyer', modelKey: 'provider_industry_restrictions' },
  { label: 'Sport', id: 'sport', modelKey: 'provider_industry_restrictions' },
  //{ label: i18n.t('industrylabels.cantdecide'), id: 'cantdecide' },
];

export const providerCompanyTypeList = [
  { label: '"Új" KATA-s (2022 szeptember után is)', id: 'singleprimary', modelKey: 'provider_business_filter' },
  {
    label: 'Egyéni vállalkozó, átalányadós, mellékállású',
    id: 'singlesecondary',
    modelKey: 'provider_business_filter',
  },
  { label: 'Egyéni vállalkozó, átalányadós, főállású', id: 'kiva', modelKey: 'provider_business_filter' },
  { label: 'Egyéni vállalkozó, VSZJA', id: 'vszja', modelKey: 'provider_business_filter' },
  { label: 'Kft', id: 'kft', modelKey: 'provider_business_filter' },
  { label: 'Kkt', id: 'kkt', modelKey: 'provider_business_filter' },
  { label: 'Bt', id: 'bt', modelKey: 'provider_business_filter' },
  { label: 'Zrt', id: 'zrt', modelKey: 'provider_business_filter' },
  { label: 'Nyrt', id: 'nyrt', modelKey: 'provider_business_filter' },
  { label: 'Társasház', id: 'condo', modelKey: 'provider_business_filter' },
  { label: 'Civil szervezet, egyesület, alapítvány, egyház', id: 'civil', modelKey: 'provider_business_filter' },
  { label: 'Önkormányzat, költségvetési szerv', id: 'citycouncil', modelKey: 'provider_business_filter' },
];

export var providerSpecialRestrictions = [
  { label: 'Külkereskedelem (EU-n belül)', id: 'insideEU', modelKey: 'provider_special_restrictions' },
  { label: 'Külkereskedelem (EU-n kívül)', id: 'outsideEU', modelKey: 'provider_special_restrictions' },
  {
    label: 'Újonnan alapított vállalatok',
    id: 'newlyfounded',
    modelKey: 'provider_special_restrictions',
  },
];

export const companySizeRestrictions = [
  {
    label: 'Alkalmazottak száma',
    id: 'noofemployees',
    modelKey: 'provider_restrictions',
    max: 250,
  },
  {
    label: 'Éves árbevétel, millió forint',
    id: 'yearlyrevenue',
    modelKey: 'provider_restrictions',
    max: 2000,
  },
  {
    label: 'Bejövő számlák, havonta',
    id: 'incominginvoices',
    modelKey: 'provider_restrictions',
    max: 20000,
  },
  {
    label: 'Kimenő számlák, havonta',
    id: 'outgoinginvoices',
    modelKey: 'provider_restrictions',
    max: 20000,
  },
];

export const providerAccBasePricing = [
  {
    label: '"Új" KATA-s (2022 szeptember után is)',
    id: 'singleprimaryprice',
    modelKey: 'provider_acc_monthly_price',
  },
  {
    label: 'Egyéni vállalkozó, átalányadós, mellékállású',
    id: 'singlesecondaryprice',
    modelKey: 'provider_acc_monthly_price',
  },
  { label: 'Egyéni vállalkozó, átalányadós, főállású', id: 'kivaprice', modelKey: 'provider_acc_monthly_price' },
  { label: 'Egyéni vállalkozó, VSZJA', id: 'vszjaprice', modelKey: 'provider_acc_monthly_price' },
  { label: 'Kft', id: 'kftprice', modelKey: 'provider_acc_monthly_price' },
  { label: 'Kkt', id: 'kktprice', modelKey: 'provider_acc_monthly_price' },
  { label: 'Bt', id: 'btprice', modelKey: 'provider_acc_monthly_price' },
  { label: 'Zrt', id: 'zrtprice', modelKey: 'provider_acc_monthly_price' },
  { label: 'Nyrt', id: 'nyrtprice', modelKey: 'provider_acc_monthly_price' },
  { label: 'Társasház', id: 'condoprice', modelKey: 'provider_acc_monthly_price' },
  {
    label: 'Civil szervezet, egyesület, alapítvány, egyház',
    id: 'civilprice',
    modelKey: 'provider_acc_monthly_price',
  },
  { label: 'Önkormányzat, költségvetési szerv', id: 'citycouncilprice', modelKey: 'provider_acc_monthly_price' },
];

export const providerAccExtraFee = [
  {
    label: 'Bejövő számlák szerinti felár',
    id: 'incominginvoiceextra',
    modelKey: 'provider_acc_monthly_price',
  },
  {
    label: 'Kimenő számlák szerinti felár',
    id: 'outgoinginvoiceextra',
    modelKey: 'provider_acc_monthly_price',
  },
  {
    label: 'Külkereskedelem %',
    id: 'foreigntradeextra',
    modelKey: 'provider_acc_monthly_price',
  },
];
export var WebshopFeeList = [
  { label: 'Több futárcég esetén (Ft/db/hó)', modelKey: 'provider_acc_monthly_price', id: 'multiplecourier' },
  { label: 'Több lerakat esetén (Ft/db/hó)', modelKey: 'provider_acc_monthly_price', id: 'multipledropoff' },
  { label: 'Felár átutalásos fizetések esetén', modelKey: 'provider_acc_monthly_price', id: 'webshoptransferpayment' },
  { label: 'Felár bankkártyás fizetések esetén', modelKey: 'provider_acc_monthly_price', id: 'webshopcardpayment' },
  // { label: 'Felár Szép kártyás fizetések esetén', modelKey: 'provider_acc_monthly_price', id:  'webshopbenefitpayment' },
];
export var TourismFeeList = [
  { label: 'Felár átutalásos fizetések esetén', modelKey: 'provider_acc_monthly_price', id: 'tourismtransferpayment' },
  { label: 'Felár bankkártyás fizetések esetén', modelKey: 'provider_acc_monthly_price', id: 'tourismcardpayment' },
  { label: 'Felár Szép kártyás fizetések esetén', modelKey: 'provider_acc_monthly_price', id: 'tourismbenefitpayment' },
];

export var severalLocationsExtra = [
  {
    label: 'Több telephely havi könyvelési díj felára',
    modelKey: 'provider_acc_monthly_price',
    id: 'severallocations',
  },
];

export var TradeFeeList = [
  { label: 'Felár átutalásos fizetések esetén', modelKey: 'provider_acc_monthly_price', id: 'tradetransferpayment' },
  { label: 'Felár bankkártyás fizetések esetén', modelKey: 'provider_acc_monthly_price', id: 'tradecardpayment' },
];

export var MedicineFeeList = [
  { label: 'Felár bankkártyás fizetések esetén', modelKey: 'provider_acc_monthly_price', id: 'medicinecardpayment' },
  {
    label: 'Felár Szép kártyás fizetések esetén',
    modelKey: 'provider_acc_monthly_price',
    id: 'medicinebenefitpayment',
  },
];

export var providerSpecialPreferencesList = [
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'EFO munkavállalók bejelentése (Ft/fő/hó)',
    id: 'registersimplifiedpayroll',
  },
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Cégautó havi könyvelési felára (Ft/autó/hó)',
    id: 'companycar',
  },
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Pénztárgép havi könyvelési felára (Ft/pénztárgép/hó)',
    id: 'cashregister',
  },
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Több bankszámla felára (Ft/bankszámla/hó )',
    id: 'multipleaccounts',
  },
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Pénzforgalmi ÁFÁs vállalkozás könyvelési felára? (%/hó)',
    id: 'cashflowvat',
  },
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Hitelfelülvizsgálat könyvelői díja (Ft/felülvizsgálat)',
    id: 'loanreview',
  },
  { modelKey: 'provider_special_industry_restrictions', label: 'Évközi zárás extra díja (%)', id: 'earlyclosure' },
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Statisztikák, kimutatások készítése (Ft/max 2 óra/hó)',
    id: 'preparingstatements',
  },
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Milyen áron számlázol ügyvezető helyett? (Ft/számla/hó)',
    id: 'invoicing',
  },
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Banki utalásokat az ügyvezető helyett (Ft/számla/hó)',
    id: 'transfering',
  },
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Felár a könyvvizsgálóval való együttműködésért (%/hó)',
    id: 'auditcooperation',
  },
  { modelKey: 'provider_special_industry_restrictions', label: 'Felár ÁFA arányosításért (%/hó)', id: 'vatadjusting' },
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Projektszámvitel alkalmazása (Ft/hó)',
    id: 'projectsupport',
  },
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Környezetvédelmi termékdíj adminisztrációja (Ft/hó)',
    id: 'environmentalfees',
  },
];

export var providerEtradePreferencesList = [
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Kibocsátott számlák és a bejövő fizetések összepárosítása (Ft/hó)',
    id: 'matchinginvoices',
  },
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Felár fizikai értékesítési helyenként (Ft/értékesítési hely/hó)',
    id: 'actualstore',
  },
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Felár készletnyilvántartásért (Ft/hó)',
    id: 'stockhandling',
  },
];
export var providerVehiclePreferencesList = [
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Különbözeti adózás felára (%/hó)',
    id: 'differentialvat',
  },
];

export var providerRetailPreferencesList = [
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Kibocsátott számlák és a bejövő fizetések összepárosítása (Ft/hó)',
    id: 'matchinginvoicesretail',
  },
];

export var providerWholesalePreferencesList = [
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Kibocsátott számlák és a bejövő fizetések összepárosítása (Ft/hó)',
    id: 'matchinginvoiceswholesale',
  },
];

export var providerTravelPreferencesList = [
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Kibocsátott számlák és a bejövő fizetések összepárosítása (Ft/hó)',
    id: 'matchinginvoicestravel',
  },
];

export var providerCourierPreferencesList = [
  {
    modelKey: 'provider_special_industry_restrictions',
    label: 'Kibocsátott számlák és a bejövő fizetések összepárosítása (Ft/hó)',
    id: 'matchinginvoicescourier',
  },
];

export const providerAccExtraFeeRevenue = [
  {
    label: '0-5 millió',
    id: 'revfeecat1',
    modelKey: 'provider_acc_monthly_price',
  },
  {
    label: '5-10 millió',
    id: 'revfeecat2',
    modelKey: 'provider_acc_monthly_price',
  },
  {
    label: '10-50 millió',
    id: 'revfeecat3',
    modelKey: 'provider_acc_monthly_price',
  },
  {
    label: '50-200 millió',
    id: 'revfeecat4',
    modelKey: 'provider_acc_monthly_price',
  },
  {
    label: '200-500 millió',
    id: 'revfeecat5',
    modelKey: 'provider_acc_monthly_price',
  },
  {
    label: '500-2000 millió',
    id: 'revfeecat6',
    modelKey: 'provider_acc_monthly_price',
  },
  {
    label: '2000+ millió',
    id: 'revfeecat7',
    modelKey: 'provider_acc_monthly_price',
  },
];

export const providerAccExtraFeeVat = [
  {
    label: 'Havi',
    id: 'vatmonthly',
    modelKey: 'provider_acc_monthly_price',
  },
  {
    label: 'Negyedéves',
    id: 'vatquarterly',
    modelKey: 'provider_acc_monthly_price',
  },
  {
    label: 'Éves',
    id: 'vatyearly',
    modelKey: 'provider_acc_monthly_price',
  },
];

export const providerPayrollPricing = [
  {
    label: 'Ft / fő / hó',
    id: 'percapitamonthlyprice',
    modelKey: 'provider_payroll_price',
  },
  {
    label: 'EFO bérszámfejtés Ft / fő / hó',
    id: 'simplifiedpayrollprice',
    modelKey: 'provider_payroll_price',
  },
];

export const providerTaxConsultingPricing = [
  {
    label: 'Átalanydíj könyvelés mellé / hó',
    id: 'perHourPrice',
    modelKey: 'provider_tax_consulting_price',
  },
];

export const providerAuditPricing = [
  {
    label: '300 millió forint alatt',
    id: 'revUnder300m',
    modelKey: 'provider_audit_price',
  },
  {
    label: '300 - 2000 millió',
    id: 'rev3002B',
    modelKey: 'provider_audit_price',
  },
  {
    label: '2 - 5 milliárd',
    id: 'rev25b',
    modelKey: 'provider_audit_price',
  },
  {
    label: '5 milliárd fölött',
    id: 'revOver5b',
    modelKey: 'provider_audit_price',
  },
];

export const providerLiquidationPricing = [
  {
    label: 'Egyszerűsített végelszámolás',
    id: 'liquidationprice',
    modelKey: 'provider_liquidation_price',
  },
];

export const providerExperienceList = [
  { label: 'Építőipar', id: 'construction', modelKey: 'provider_industry_experience' },
  { label: 'Gépjármű Ipar', id: 'vehicle', modelKey: 'provider_industry_experience' },
  { label: 'Gyártás és Feldolgozó Ipar', id: 'manufacturing', modelKey: 'provider_industry_experience' },
  { label: 'Informatika', id: 'IT', modelKey: 'provider_industry_experience' },
  { label: 'Ingatlankereskedelem', id: 'realestate', modelKey: 'provider_industry_experience' },
  //{ label: 'Kis-es nagykereskedelem', id: 'trade', modelKey: 'provider_industry_experience' },
  { label: 'Kiskereskedelem', id: 'retail', modelKey: 'provider_industry_experience' },
  { label: 'Nagykereskedelem', id: 'wholesale', modelKey: 'provider_industry_experience' },
  { label: 'Mező-es erdőgazdálkodás, halászat', id: 'agriculture', modelKey: 'provider_industry_experience' },
  { label: 'Oktatás, Kutatás', id: 'education', modelKey: 'provider_industry_experience' },
  { label: 'Művészet', id: 'art', modelKey: 'provider_industry_experience' },
  { label: 'Szépségipar', id: 'beauty', modelKey: 'provider_industry_experience' },
  { label: 'Egészségügy', id: 'medicine', modelKey: 'provider_industry_experience' },
  { label: 'Pénzügy, biztosítás', id: 'finance', modelKey: 'provider_industry_experience' },
  { label: 'Szállás, Vendéglátás', id: 'travel', modelKey: 'provider_industry_experience' },
  { label: 'Szállítás, raktározás', id: 'logistics', modelKey: 'provider_industry_experience' },
  { label: 'Futár', id: 'courier', modelKey: 'provider_industry_experience' },
  { label: 'Egyéb', id: 'otherservices', modelKey: 'provider_industry_experience' },
  { label: 'E-kereskedelem', id: 'etrade', modelKey: 'provider_industry_experience' },
  { label: 'Start up', id: 'startup', modelKey: 'provider_industry_experience' },
  { label: 'Ügyvédi iroda', id: 'lawyer', modelKey: 'provider_industry_experience' },
  { label: 'Sport', id: 'sport', modelKey: 'provider_industry_experience' },
  // { label: i18n.t('industrylabels.cantdecide'), id: 'cantdecide' },
];

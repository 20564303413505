import React, { useEffect, useState, useContext } from 'react';
import RadioInputMultiChoice from 'src/components/RadioInputMultiChoice';
import { packageInputs } from '../profileHelper';
import { ProviderContext } from '../ProviderProfile';

const Package = () => {
  const [data, setData] = useState('');
  const { providerData, updateData } = useContext(ProviderContext);

  useEffect(() => {
    if (providerData) {
      setData({
        provider_package: providerData.provider_package,
      });
    }
  }, [providerData]);
  const handleChange = async (id, value) => {
    await updateData('provider', { [id]: value });
  };

  return (
    <div className="TabContent">
      {data ? (
        <div className="TabContentFlexRow">
          <div className="Padding12">
            {packageInputs.map((item, i) => {
              return (
                <div key={i}>
                  <RadioInputMultiChoice
                    onChange={handleChange}
                    id={`${item.modelKey}.${item.id}`}
                    valueProp={data[item.modelKey][item.id]}
                    label={item.label}
                    options={item.options}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default Package;

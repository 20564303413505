export const columns = [
  {
    name: 'refID',
    label: 'Referral ID',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'refererEmail',
    label: 'Referer email',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'refCode',
    label: 'Referral Code',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'reqID',
    label: 'Request ID',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'dateCreated',
    label: 'Date of referral',
    options: {
      filter: true,
      sort: true,
    },
  },
];

export const columns = [
  {
    name: 'couponCode',
    label: 'Coupon Code',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'discount',
    label: 'Discount',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'discountLarge',
    label: 'Discount L',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'singleDeduction',
    label: 'Single Deduction',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'flow',
    label: 'Flow',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'companyType',
    label: 'Company Type',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'referral',
    label: 'Referral',
    options: {
      filter: true,
      sort: true,
    },
  },
];

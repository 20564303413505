import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Dialog from '@mui/material/Dialog';
import { columns } from './providersTableConfig';
import ProviderProfile from './providerProfile/ProviderProfile';

const ProvidersTable = ({ providers }) => {
  const [tableData, setTableData] = useState([]);
  const [selectedProvID, setSelectedProvID] = useState('');
  const [dialogState, setDialogState] = React.useState(false);

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  useEffect(() => {
    let temp = [];
    if (providers) {
      providers.forEach((provider) => {
        temp.push({
          profileImage: provider.providerData.provider_media
            ? provider.providerData.provider_media.profileImage
            : undefined,
          companyName: provider.providerData.provider_info.companyname,
          providerID: provider.providerData.providerID,
          accountEmail: provider.userData.email,
          registered: formatDate(provider.providerData.dateCreated),
          activeSubscription: provider.providerData.provider_subscription.activeSubscription,
          activatedAccount: provider.userData.confirmed,
          completedRegistration:
            provider.userData.finishedRegistration && provider.providerData.finishedRegistration ? true : false,
        });
      });
    }
    setTableData(temp);
  }, [providers]);

  const handleClose = () => {
    setDialogState(false);
  };

  const options = {
    filterType: 'checkbox',
    rowHover: true,
    selectableRows: false,
    onRowClick: (rowData, rowMeta) => {
      //if columns get rearranged change the index here -- unfortunately rowData is flattened
      setSelectedProvID(rowData[2]);
      setDialogState(true);
    },
  };
  return (
    <div>
      <MUIDataTable data={tableData} columns={columns} options={options} />
      <Dialog fullWidth={true} maxWidth={'lg'} open={dialogState} onClose={handleClose}>
        <ProviderProfile providerID={selectedProvID} />
      </Dialog>
    </div>
  );
};

export default ProvidersTable;

import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Button } from '@mui/material';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData, fetchData2 } from 'src/fetch';
import { TextField, MenuItem, Select } from '@mui/material';
import { TextareaAutosize } from '@mui/material';
import { cafStatusList, aspStatusList, herStatusList } from '../statusHelper';
import Dropdown from '../../../../../components/Dropdown';

const Contract = ({ statusID }) => {
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;
  const [requestData, setRequestData] = useState();
  const [message, setMessage] = useState('');
  const [custPrice, setCustPrice] = useState({ accounting: '', payrol: '', taxcon: '' });
  const [provPrice, setProvPrice] = useState({ accounting: '', payrol: '', taxcon: '' });
  const [tempStatus, setTempStatus] = useState();
  // const [isChanged, setIsChanged] = useState(false);

  // const bankPref = ['opt1', 'opt2', 'opt3', 'opt4', 'opt5', 'opt6', 'opt7', 'opt8', 'other', 'bankidk'];
  // const invoPref = ['opt1', 'opt2', 'opt3', 'opt4', 'opt5', 'opt6', 'opt7', 'other', 'invoicingidk'];
  // const softPref = [
  //   'opt1',
  //   'opt2',
  //   'opt3',
  //   'opt4',
  //   'opt5',
  //   'opt6',
  //   'opt7',
  //   'opt8',
  //   'opt9',
  //   'opt10',
  //   'opt11',
  //   'opt12',
  //   'opt13',
  //   'opt14',
  //   'opt15',
  //   'opt16',
  //   'opt17',
  //   'opt18',
  //   'opt19',
  //   'opt20',
  //   'other',
  // ];

  // const services = [
  //   'legalentityfounding',
  //   'taxconsultingsimple',
  //   'taxconsultingcomplex',
  //   'accounting',
  //   'payroll',
  //   'taxconsulting',
  // ];

  const [tempObj, setTempObj] = useState({
    chosendate: '',
    startingdate: 0,
    accounting: false,
    taxconsulting: false,
    taxconsultingsimple: false,
    taxconsultingcomplex: false,
    legalentityfounding: false,
    payroll: false,
    audit: false,
    liquidation: false,
    languagefilter: false,
    languages: {
      english: false,
      german: false,
      spanish: false,
      french: false,
      romanian: false,
      chinese: false,
      russian: false,
    },
    noofemployees: 0,
    yearlyrevenue: 0,
    incominginvoices: 0,
    outgoinginvoices: 0,
    industry: '',
    companytype: '',
    companyctcname: '',
    zip_code: '',
    foreign_trade: false,
    foreigntradeEU: '',
    newlyfounded: false,
    vatFilter: '',
    vatPayment: '',
    taxform: 'idk',
    email: '',
    gdpr: false,
    marketing: false,
    reqMessage: '',
    companyPhone: '',
    onlyBrowsing: false,
    isDiscounted: false,
    discountCode: '',
    bankingpref: {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      opt8: false,
      other: false,
      bankidk: false,
    },
    invoicingpref: {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      other: false,
      invoicingidk: false,
    },
    softwarepref: {
      opt1: false,
      opt2: false,
      opt3: false,
      opt4: false,
      opt5: false,
      opt6: false,
      opt7: false,
      opt8: false,
      opt9: false,
      opt10: false,
      opt11: false,
      opt12: false,
      opt13: false,
      opt14: false,
      opt15: false,
      opt16: false,
      opt17: false,
      opt18: false,
      opt19: false,
      opt20: false,
      other: false,
    },
    multiplecompany: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
    utm_term: '',
    gclid: '',
    dclid: '',
    industryinput: '',
    proposedSinglePrice: '',
    proposedContPrice: '',
    originalSinglePrice: '',
    originalContPrice: '',
    howdidyoufindusinput: '',
    whychangingaccountant: '',
    isfakecherry: false,
    interestedInBupa: false,
    whereElseDidSearch: [],
    whatCanWeImprove: '',
    howHardWasIt: '',
    howHardNr: 0,
    whyNotUs: '',
    whyNotUsText: '',
    companylegalname: '',
    contractdate: '',
    legalperson: '',
    legaladdress: '',
    monthlyinvoice: '',
    provprice: {
      accounting: '',
      payrol: '',
      taxcon: '',
    },
    custprice: {
      accounting: '',
      payrol: '',
      taxcon: '',
    },
    documents: {
      custcontract: '',
      provcontract: '',
      misc1: '',
      misc2: '',
      misc3: '',
      misc4: '',
      misc5: '',
      misc6: '',
    },
    statuslog: [],
    responsibleagent: '',
    bklegalentity: '',
    chosenprovider: '',
    chosenlawyer: '',
    howdidyoufindus: '',
    messagelog: [],
    custservmessage: '',
    selectedProvGrp: '',
  });
  const [servicesPicked, setServicesPicked] = useState([]);
  const [languagesPicked, setLanguagesPicked] = useState([]);
  const [chosenBank, setChosenBank] = useState([]);
  const [chosenInvo, setChosenInvo] = useState([]);
  const [chosenSoft, setChosenSoft] = useState([]);

  const updateStatusStatus = async (reqID, newValue) => {
    await fetchData(`${baseUrl}/api/aspreq/status/${reqID}`, 'PATCH', localStorage.getItem('JWT'), {
      newValue,
    })
      .then(async (response) => {
        let resp = await response.json();

        console.log(resp);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const getRequestData = useCallback(async () => {
    console.log('setting reqData');
    await fetchData(`${baseUrl}/api/aspreq/${statusID}`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        console.log('response recieved', resp);
        setTempStatus(resp.reqstatus);
        setRequestData(resp);
        setTempObj(resp);
        setServicesPicked(resp.services);

        if (resp.languages !== null || resp.languages !== undefined) {
          let newLangs = [];
          for (const [key, value] of Object.entries(resp.languages)) {
            if (value === true) {
              newLangs.push(key);
            }
          }
          setLanguagesPicked(newLangs);
        }
        if ((resp.bankingpref !== null || resp.bankingpref !== undefined) && resp.bankingpref) {
          let newBanks = [];
          for (const [key, value] of Object.entries(resp.bankingpref)) {
            if (value === true) {
              newBanks.push(key);
            }
          }
          setChosenBank(newBanks);
        }
        if ((resp.invoicingpref !== null || resp.invoicingpref !== undefined) && resp.invoicingpref) {
          let newInvo = [];
          for (const [key, value] of Object.entries(resp.invoicingpref)) {
            if (value === true) {
              newInvo.push(key);
            }
          }
          setChosenInvo(newInvo);
        }
        if ((resp.softwarepref !== null || resp.softwarepref !== undefined) && resp.softwarepref) {
          let newSoft = [];
          for (const [key, value] of Object.entries(resp.softwarepref)) {
            if (value === true) {
              newSoft.push(key);
            }
          }
          setChosenSoft(newSoft);
          setCustPrice(resp.custprice);
          setProvPrice(resp.provprice);
          setMessage(resp.custservmessage);
        }
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl, statusID]);
  console.log(requestData, 'REQDATA NED OF SETTER');
  console.log(tempObj, 'REMPOBJ NED OF SETTER');

  const legalEntitySetter = (vatStatus, industry) => {
    console.log('setting entity');
    let entity = '';

    if (vatStatus.toString() === 'true') {
      setTempObj((prevState) => ({ ...prevState, bklegalentity: 'Bookkeepie.com ALFA Kft.' }));
    } else if (vatStatus.toString() === 'false' || vatStatus.toString() === 'idk') {
      switch (industry) {
        case 'agriculture':
        case 'construction':
        case 'engineer':
        case 'realestate':
        case 'lawyer':
        case 'logistics':
        case 'manufacturing':
        case 'vehicle':
        case 'courier':
        case 'art':
        case 'travel':
        case 'education':
        case 'other':
        case 'otherservices':
        case 'beauty':
        case 'startup':
        case 'finance':
          entity = 'Bookkeepie.com Infrastructure and Industry Kft';
          break;
        case 'medicine':
        case 'IT':
          entity = 'Bookkeepie.com Health and Tech Kft.';
          break;
        //  case 'trade':
        case 'retail':
        case 'wholesale':
        case 'etrade':
        case 'sport':
          entity = 'Bookkeepie.com Commerce Kft.';
          break;
      }
      setTempObj((prevState) => ({ ...prevState, bklegalentity: entity }));
    }
  };

  console.log(tempObj, 'TEMPObj');

  useEffect(() => {
    getRequestData();
  }, [getRequestData]);

  useEffect(() => {
    requestData && requestData.bklegalentity === '' && legalEntitySetter(requestData.vatFilter, requestData.industry);
  }, [requestData]);

  const updateRequestData = useCallback(
    async (statusID, updateParams) => {
      await fetchData2(`${baseUrl}/api/aspreq/${statusID}`, 'PATCH', 'B3OeO1K7-KcEcE8PdI4Eb', {
        requestId: statusID,
        updateParams: updateParams,
      })
        .then(async (response) => {
          console.log('AXIOS RESPONSE: ', response);
        })
        .catch((err) => {
          console.log(err);
          alert(err);
        });
    },
    [baseUrl, statusID]
  );

  const singleValueHandler = useCallback((event) => {
    setTempObj((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  });

  //console.log(tempObj, 'TEMPOBJECT', tempObj, 'TEMPObj');

  return (
    <div>
      {requestData ? (
        <>
          <div className="StatusDetailsLabel">
            <Dropdown
              label="CAF Státusz"
              id="cafstatus"
              list={cafStatusList}
              selValue={requestData.reqstatus.cafstatus || ''}
              onChange={(value) => setTempStatus({ ...tempStatus, cafstatus: value })}
            />
            <Dropdown
              label="ASP Státusz"
              id="aspstatus"
              list={aspStatusList}
              selValue={requestData.reqstatus.aspstatus || ''}
              onChange={(value) => setTempStatus({ ...tempStatus, aspstatus: value })}
            />
            <Dropdown
              label="REG Státusz"
              id="herstatus"
              list={herStatusList}
              selValue={requestData.reqstatus.herstatus || ''}
              onChange={(value) => setTempStatus({ ...tempStatus, herstatus: value })}
            />
            <div style={{ width: '150px', padding: '16px' }}>
              <Button
                onClick={() => updateStatusStatus(requestData._id, tempStatus)}
                style={{ float: 'right' }}
                variant="contained"
              >
                Státusz mentése
              </Button>
            </div>
          </div>
          <div className="contractGrid">
            <div className="contractChild1">
              <p>
                <span className="spanTitle">Ajánlatkérő neve: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="companyctcname"
                  name="companyctcname"
                  style={{ width: '300px' }}
                  variant="standard"
                  value={tempObj.companyctcname}
                  sx={{ fontSize: 14 }}
                />
              </p>
              <p>
                <span className="spanTitle">Ajánlatkérő e-mail: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="email"
                  name="email"
                  style={{ width: '300px' }}
                  variant="standard"
                  value={tempObj.email}
                  sx={{ fontSize: 14 }}
                />
              </p>
              <p>
                <span className="spanTitle">Ajánlatkérő telefon: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="companyPhone"
                  name="companyPhone"
                  style={{ width: '300px' }}
                  variant="standard"
                  value={tempObj.companyPhone}
                  sx={{ fontSize: 14 }}
                />
              </p>
              <p>
                <span className="spanTitle">Irányítószám: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="zip_code"
                  name="zip_code"
                  style={{ width: '300px' }}
                  variant="standard"
                  value={tempObj.zip_code}
                  sx={{ fontSize: 14 }}
                />
              </p>
              <p>
                <span className="spanTitle">Mikortól kéri: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="chosendate"
                  name="chosendate"
                  value={tempObj.chosendate}
                  style={{ width: '300px' }}
                  variant="standard"
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Megbízás: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="isCommission"
                  name="isCommission"
                  value={tempObj.isCommission}
                  style={{ width: '300px' }}
                  variant="standard"
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Egyszeri ár: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  sx={{ fontSize: 14 }}
                  style={{ width: '300px' }}
                  variant="standard"
                  value={tempObj.proposedSinglePrice}
                />
              </p>
              <p>
                <span className="spanTitle">Folyamatos ár: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  sx={{ fontSize: 14 }}
                  style={{ width: '300px' }}
                  variant="standard"
                  value={tempObj.originalContPrice}
                />
              </p>
              <p>
                <span className="spanTitle">Folyamatos ár(kedvezményes): </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  sx={{ fontSize: 14 }}
                  style={{ width: '300px' }}
                  variant="standard"
                  value={tempObj.proposedContPrice}
                />
              </p>
              <p>
                <span className="spanTitle">Üzenet: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  style={{ width: '300px' }}
                  variant="standard"
                  value={tempObj.reqMessage}
                  sx={{ fontSize: 14 }}
                />
              </p>
              <p>
                <span className="spanTitle">Új alapítású: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="newlyfounded"
                  name="newlyfounded"
                  value={tempObj.newlyfounded}
                  style={{ width: '300px' }}
                  variant="standard"
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Szolgáltatás:</span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  variant="standard"
                  value={servicesPicked}
                  style={{ width: '300px' }}
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Alkalmazottak: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="noofemployees"
                  name="noofemployees"
                  style={{ width: '300px' }}
                  variant="standard"
                  value={tempObj.noofemployees}
                  sx={{ fontSize: 14 }}
                />
              </p>
              <p>
                <span className="spanTitle">Éves árbevétel: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="yearlyrevenue"
                  name="yearlyrevenue"
                  value={tempObj.yearlyrevenue}
                  style={{ width: '300px' }}
                  variant="standard"
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Bejövő számlák: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="incominginvoices"
                  name="incominginvoices"
                  value={tempObj.incominginvoices}
                  style={{ width: '300px' }}
                  variant="standard"
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">kimenő számlák: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="outgoinginvoices"
                  name="outgoinginvoices"
                  value={tempObj.outgoinginvoices}
                  style={{ width: '300px' }}
                  variant="standard"
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Iparág: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="industry"
                  name="industry"
                  value={tempObj.industry}
                  style={{ width: '300px' }}
                  variant="standard"
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Cégforma: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="companytype"
                  name="companytype"
                  value={tempObj.companytype}
                  style={{ width: '300px' }}
                  variant="standard"
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Adó forma: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="taxform"
                  name="taxform"
                  value={tempObj.taxform}
                  style={{ width: '300px' }}
                  variant="standard"
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Áfa alanyiság: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="vatFilter"
                  name="vatFilter"
                  value={tempObj.vatFilter}
                  style={{ width: '300px' }}
                  variant="standard"
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Áfa gyakoriság: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="vatPayment"
                  name="vatPayment"
                  value={tempObj.vatPayment}
                  style={{ width: '300px' }}
                  variant="standard"
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Nyelvek: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  variant="standard"
                  multiple
                  value={languagesPicked}
                  renderValue={(selected) => selected.join(', ')}
                  style={{ width: '300px' }}
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p className="marginTop16">
                <span className="spanTitle">Külkereskedelem: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="foreign_trade"
                  name="foreign_trade"
                  value={tempObj.foreign_trade}
                  style={{ width: '300px' }}
                  variant="standard"
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Külkereskedelem irány: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="foreigntradeEU"
                  name="foreigntradeEU"
                  value={tempObj.foreigntradeEU}
                  style={{ width: '300px' }}
                  variant="standard"
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Preferenciák: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  style={{ width: '300px' }}
                  variant="standard"
                  value={tempObj.preferences}
                />
              </p>
              <p>
                <span className="spanTitle">Bank preferenciák: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  variant="standard"
                  multiple
                  value={chosenBank}
                  style={{ width: '300px' }}
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
              <p>
                <span className="spanTitle">Számlázó preferenciák: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  variant="standard"
                  multiple
                  value={chosenInvo}
                  style={{ width: '300px' }}
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>

              <p>
                <span className="spanTitle">Könyvelő program preferenciák(ha NEM EV!): </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  variant="standard"
                  value={chosenSoft}
                  style={{ width: '300px' }}
                  sx={{ fontSize: 14 }}
                ></TextField>
              </p>
            </div>
            <div className="contractChild2">
              <p className="marginTop16">
                <span className={'spanTitle width300'}>Illetékes ügyintéző: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="responsibleagent"
                  name="responsibleagent"
                  value={tempObj.responsibleagent}
                  style={{ width: '200px' }}
                  variant="standard"
                ></TextField>
              </p>

              <p className="marginTop16">
                <span className={'spanTitle width300'}>Illetékes ügyvéd: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="chosenlawyer"
                  name="chosenlawyer"
                  value={tempObj.chosenlawyer}
                  style={{ width: '200px' }}
                  variant="standard"
                ></TextField>
              </p>

              <p className="marginTop16">
                <span className={'spanTitle width300'}>Illetékes szolgáltató: </span>
                <TextField
                  inputProps={{ style: { fontSize: 14, padding: 2 } }}
                  id="chosenprovider"
                  name="chosenprovider"
                  value={tempObj.chosenprovider}
                  style={{ width: '200px' }}
                  variant="standard"
                ></TextField>
              </p>
              <div className={'spanTitle width300'}>Bookkeepie entitás: </div>
              <Select
                id="bklegalentity"
                name="bklegalentity"
                onChange={singleValueHandler}
                value={tempObj.bklegalentity}
                style={{ width: '200px' }}
                variant="standard"
              >
                <MenuItem value={'Bookkeepie.com Services Kft.'}>Bk.com Services Kft.</MenuItem>
                <MenuItem value={'Bookkeepie.com Infrastructure and Industry Kft'}>
                  Bk.com Infrastructure and Industry Kft
                </MenuItem>
                <MenuItem value={'Bookkeepie.com Health and Tech Kft.'}>Bk.com Health and Tech Kft.</MenuItem>
                <MenuItem value={'Bookkeepie.com Commerce Kft.'}>Bk.com Commerce Kft.</MenuItem>
                <MenuItem value={'Bookkeepie.com ALFA Kft.'}>Bk.com ALFA Kft.</MenuItem>
              </Select>
              <div>
                <p className="marginTop16">
                  <span className={'spanTitle width300'}>Könyvelői havi ár (könyvelés): </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="provaccounting"
                    name="provaccounting"
                    value={provPrice.accounting}
                    style={{ width: '200px' }}
                    variant="standard"
                    onChange={(event) => {
                      setProvPrice({ ...provPrice, accounting: event.target.value });
                    }}
                    onBlur={() => setTempObj({ ...tempObj, provprice: provPrice })}
                  ></TextField>
                </p>
                <p className="marginTop16">
                  <span className={'spanTitle width300'}>Könyvelői havi ár (per fő, bérszámfejtés): </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="provpayrol"
                    name="provpayrol"
                    value={provPrice.payrol}
                    style={{ width: '200px' }}
                    variant="standard"
                    onChange={(event) => {
                      setProvPrice({ ...provPrice, payrol: event.target.value });
                    }}
                    onBlur={() => setTempObj({ ...tempObj, provprice: provPrice })}
                  ></TextField>
                </p>
                <p className="marginTop16">
                  <span className={'spanTitle width300'}>Könyvelő egyszeri ár: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="provtaxcon"
                    name="provtaxcon"
                    value={provPrice.taxcon}
                    style={{ width: '200px' }}
                    variant="standard"
                    onChange={(event) => {
                      setProvPrice({ ...provPrice, taxcon: event.target.value });
                    }}
                    onBlur={() => setTempObj({ ...tempObj, provprice: provPrice })}
                  ></TextField>
                </p>
                <p className="marginTop16">
                  <span className={'spanTitle width300'}>Ügyfél havi ár (könyvelés): </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="custaccounting"
                    name="custaccounting"
                    value={custPrice.accounting}
                    style={{ width: '200px' }}
                    variant="standard"
                    onChange={(event) => {
                      setCustPrice({ ...custPrice, accounting: event.target.value });
                    }}
                    onBlur={() => setTempObj({ ...tempObj, custprice: custPrice })}
                  ></TextField>
                </p>
                <p className="marginTop16">
                  <span className={'spanTitle width300'}>Ügyfél havi ár (per fő, bérszámfejtés): </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="custpayrol"
                    name="custpayrol"
                    value={custPrice.payrol}
                    style={{ width: '200px' }}
                    variant="standard"
                    onChange={(event) => {
                      setCustPrice({ ...custPrice, payrol: event.target.value });
                    }}
                    onBlur={() => setTempObj({ ...tempObj, custprice: custPrice })}
                  ></TextField>
                </p>
                <p className="marginTop16">
                  <span className={'spanTitle width300'}>Ügyfél egyszeri ár: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="custtaxcon"
                    name="custtaxcon"
                    value={custPrice.taxcon}
                    style={{ width: '200px' }}
                    variant="standard"
                    onChange={(event) => {
                      setCustPrice({ ...custPrice, taxcon: event.target.value });
                    }}
                    onBlur={() => setTempObj({ ...tempObj, custprice: custPrice })}
                  ></TextField>
                </p>
                <p className="marginTop16">
                  <span className={'spanTitle width300'}>Szerződés dátuma: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="contractdate"
                    name="contractdate"
                    value={tempObj.contractdate}
                    style={{ width: '200px' }}
                    variant="standard"
                    onChange={singleValueHandler}
                  ></TextField>
                </p>
                <p className="marginTop16">
                  <span className={'spanTitle width300'}>Ügyfél vállalkozás neve: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="companylegalname"
                    name="companylegalname"
                    value={tempObj.companylegalname}
                    style={{ width: '200px' }}
                    variant="standard"
                    onChange={singleValueHandler}
                  ></TextField>
                </p>
                <p className="marginTop16">
                  <span className={'spanTitle width300'}>Képviseletre jogosult személy: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="legalperson"
                    name="legalperson"
                    value={tempObj.legalperson}
                    style={{ width: '200px' }}
                    variant="standard"
                    onChange={singleValueHandler}
                  ></TextField>
                </p>
                <p className="marginTop16">
                  <span className={'spanTitle width300'}>Ügyfél címe: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="legaladdress"
                    name="legaladdress"
                    value={tempObj.legaladdress}
                    style={{ width: '200px' }}
                    variant="standard"
                    onChange={singleValueHandler}
                  ></TextField>
                </p>
                <p className="marginTop16">
                  <span className={'spanTitle width300'}>Készüljön róla havi számla?: </span>
                  <Select
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="chosenprovider"
                    name="chosenprovider"
                    value={tempObj.monthlyinvoice}
                    style={{ width: '200px' }}
                    variant="standard"
                    onChange={singleValueHandler}
                  >
                    <MenuItem value={true}>Igen</MenuItem>
                    <MenuItem value={false}>Nem</MenuItem>
                  </Select>
                </p>
                <p className="marginTop16">
                  <span className={'spanTitle width300'}>Adószám: </span>
                  <TextField
                    inputProps={{ style: { fontSize: 14, padding: 2 } }}
                    id="companytaxnumber"
                    name="companytaxnumber"
                    value={tempObj.companytaxnumber}
                    style={{ width: '200px' }}
                    variant="standard"
                    onChange={singleValueHandler}
                  ></TextField>
                </p>
              </div>

              <div className="marginTop16">
                <Button
                  size={'large'}
                  onClick={() => {
                    updateRequestData(statusID, tempObj);
                  }}
                >
                  Adatok mentése
                </Button>
              </div>
            </div>
            <div className="contractChild3">
              <p>Amazonoknak Üzenet</p>
              <TextareaAutosize
                style={{ width: '80%' }}
                value={message}
                onChange={(event) => {
                  setMessage(event.target.value);
                }}
                maxRows={6}
                minRows={4}
              />
              <Button
                style={{ width: '80%' }}
                size="large"
                onClick={() => {
                  updateRequestData(statusID, { custservmessage: message });
                }}
              >
                Üzenet mentése
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div style={{ marginTop: '16px' }}> Loading...</div>
      )}
    </div>
  );
};

export default Contract;

import React, { useEffect, useState, useContext } from 'react';
import { providerExperienceList } from '../profileHelper';
import { ProviderContext } from '../ProviderProfile';
import RadioInputScale from 'src/components/RadioInputScale';
import LanguageSelector from './components/LanguageSelector';

const Experience = () => {
  const [data, setData] = useState(null);
  const { providerData, updateData } = useContext(ProviderContext);

  useEffect(() => {
    if (providerData) {
      setData({
        provider_industry_experience: providerData.provider_industry_experience,
      });
    }
  }, [providerData]);

  const handleChange = async (id, value) => {
    await updateData('provider', { [id]: value });
  };

  return (
    <div className="TabContent">
      {data ? (
        <div className="TabContentFlexRow">
          <div className="Padding12">
            <b>Iparági tapasztalat</b>
            {providerExperienceList.map((item, i) => {
              return (
                <div key={i}>
                  <RadioInputScale
                    onChange={handleChange}
                    id={`${item.modelKey}.${item.id}`}
                    valueProp={data[item.modelKey][item.id]}
                    label={item.label}
                    buttonsCount={5}
                  />
                </div>
              );
            })}
          </div>
          <div className="Padding12">
            <b>Nyelvtudás</b>
            <LanguageSelector />
          </div>
        </div>
      ) : (
        <>Loading</>
      )}
    </div>
  );
};

export default Experience;

import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export default function RadioInputMultiChoice({ label, valueProp, onChange, id, options }) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(id, event.target.value);
  };

  return (
    <FormControl>
      <b>{label}</b>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={handleChange}
        value={value}
      >
        {options.map((item, i) => {
          return (
            <FormControlLabel
              key={i}
              value={item.value}
              control={<Radio checked={`${value}` === `${item.value}` ? true : false} />}
              label={item.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

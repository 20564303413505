import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';

export default function RadioInputScale({ valueProp, label, buttonsCount, onChange, id }) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleChange = (event) => {
    let value = parseInt(event.target.value);
    setValue(value);
    onChange(id, value);
  };

  const setContent = () => {
    let content = [];
    for (let i = 1; i <= buttonsCount; i++) {
      content.push(
        <Radio
          id={`${i}`}
          key={i}
          checked={i === value ? true : false}
          onChange={(event) => {
            handleChange(event);
          }}
          value={i}
          inputProps={{ 'aria-label': 'A' }}
          sx={{
            color: '#1A00BD',
            '&.Mui-checked': {
              color: '#1A00BD',
            },
          }}
        />
      );
    }
    return content;
  };

  return (
    <div className="RadioInputContainer" key={id}>
      <p>{label}</p>
      <div>{setContent()}</div>
    </div>
  );
}

/* eslint-disable react/prefer-stateless-function */
import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { inputRef, onChange, id, name, ...other } = props;
  return (
    <NumericFormat
      {...other}
      ref={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.floatValue,
            id: id,
            name: name,
          },
        });
      }}
      thousandSeparator
    />
  );
});

export const NumberInput = ({ id, valueProp, label, onChange, width = '200px', float }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleChange = (event) => {
    let value = event.target.value;
    setValue(value);
  };

  const handleBlur = (event) => {
    let num = float ? parseFloat(event.target.value.replace(/,/g, '')) : parseInt(event.target.value.replace(/,/g, ''));
    onChange(id, num);
  };

  return (
    <div style={{ width: width }} key={id}>
      <TextField
        fullWidth
        id={id}
        label={label}
        value={value}
        onChange={(event) => handleChange(event)}
        onBlur={(event) => handleBlur(event)}
        variant="standard"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />
    </div>
  );
};

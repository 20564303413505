import React, { useState, useCallback, useContext, useEffect } from 'react';
import { TextareaAutosize, Button } from '@mui/material';
import { fetchData } from 'src/fetch';
import { EnvContext } from 'src/context/EnvContext';

function Remarks({ statusID }) {
    const [notes, setNotes] = useState('');
    const [requestData, setRequestData] = useState();
    const [logs, setLogs] = useState([]);
    const [editDateIndex, setEditDateIndex] = useState(null);
    const [editDate, setEditDate] = useState('');

    const envContext = useContext(EnvContext);
    const baseUrl = envContext.baseUrl;

    function formatDate(date = new Date()) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = '' + d.getHours(),
            minutes = '' + d.getMinutes();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        if (hours.length < 2) hours = '0' + hours;
        if (minutes.length < 2) minutes = '0' + minutes;

        return [year, month, day].join('-') + ' ' + [hours, minutes].join(':');
    }

    const getRequestData = useCallback(async () => {
        await fetchData(`${baseUrl}/api/aspreq/${statusID}`, 'GET', localStorage.getItem('JWT'))
            .then(async (response) => {
                let resp = await response.json();
                setRequestData(resp);
            })
            .catch((err) => {
                console.log(err);
                alert(err);
            });
    }, [baseUrl, statusID]);

    const reloadData = async () => {
        await getRequestData();
    };

    const remarkHandler = useCallback(
        (event) => {
            setNotes(event.target.value);
        },
        [setNotes]
    );

    const updateClientRemarksLog = async (reqID, updatedLogs = null) => {
        let requestBody = { updatedLogs };

        await fetchData(`${baseUrl}/api/aspreq/clientremarkslog/${reqID}`, 'PATCH', localStorage.getItem('JWT'), requestBody)
            .then(async (response) => {
                reloadData();
                setNotes('');
            })
            .catch((err) => {
                console.log(err);
                alert(err);
            });
    };

    const handleSaveNote = () => {
        if (notes.trim() !== '') {
            const newLog = {
                date: new Date(),
                note: notes,
            };
            const updatedLogs = [...logs, newLog];
            updateClientRemarksLog(statusID, updatedLogs);
        }
    };

    const handleDateClick = (log, index) => {
        setEditDateIndex(index);
        setEditDate(formatDate(log.date));
    };

    const handleDateChange = (event) => {
        setEditDate(event.target.value);
    };

    const handleSaveDate = (index) => {
        if (editDate.trim() !== '') {
            const updatedLog = logs.map((log, idx) =>
                idx === index ? { ...log, date: new Date(editDate) } : log
            );
            setLogs(updatedLog);
            setEditDateIndex(null);
            updateClientRemarksLog(statusID, updatedLog);
        }
    };

    useEffect(() => {
        if (requestData && requestData.clientremarkslog) {
            const sortedLogs = requestData.clientremarkslog.slice().sort((a, b) => new Date(b.date) - new Date(a.date));
            setLogs(sortedLogs);
        }
    }, [requestData]);

    useEffect(() => {
        getRequestData();
    }, [getRequestData]);

    return (
        <>
            <div className="interactionChild2 marginTop16">
                <TextareaAutosize
                    style={{ width: '100%', padding: '12px', resize: 'none' }}
                    value={notes}
                    onChange={remarkHandler}
                    minRows={5}
                    placeholder='Ide tudsz jegyzetelni...'
                />
                <Button
                    style={{ width: '100%' }}
                    size="large"
                    onClick={handleSaveNote}
                >
                    Jegyzet rögzítése
                </Button>
            </div>
            <div className="interactionChild6 marginTop16">
                <h2>Feljegyzések</h2>
                <div className="marginTop16">
                    {logs.slice().map((e, idx) => (
                        <p key={idx}>
                            <br />
                            {editDateIndex === idx ? (
                                <input
                                    type="datetime-local"
                                    value={editDate}
                                    onChange={handleDateChange}
                                    onBlur={() => handleSaveDate(idx)}
                                />
                            ) : (
                                <span
                                    style={{ color: '#ADADAD', cursor: 'pointer' }}
                                    onClick={() => handleDateClick(e, idx)}
                                >
                                    {formatDate(e.date)}
                                </span>
                            )}
                            {e.sender && <span style={{ fontWeight: '800' }}>{e.sender}</span>}{' '}
                            <span>{e.note}</span>
                            <hr />
                        </p>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Remarks;

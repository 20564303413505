import React, { useState, useCallback, useContext } from 'react';
import Main from './tabs/main';
import Matching from './tabs/matching';
import Validation from './tabs/validation';
import Emails from './tabs/emails';
import Contract from './tabs/contract';
import Documents from './tabs/documents';
import Interaction from './tabs/interaction';
import Remarks from './tabs/remarks';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import { EnvContext } from 'src/context/EnvContext';
import { fetchData } from 'src/fetch';

const StatusView = ({ statusID }) => {
  const [statusData, setStatusData] = useState(null);
  const [tempStatus, setTempStatus] = useState(null);
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;
  const [selectedTab, setSelectedTab] = useState('main');

  const statusTabs = [
    { label: 'Main, log', id: 'main' },
    { label: 'Igény validálás', id: 'validation' },
    { label: 'Match Score', id: 'matching' },
    { label: 'Ügyfél interakció', id: 'interaction' },
    { label: 'E-mails', id: 'emails' },
    { label: 'Szerződés adatok', id: 'contract' },
    { label: 'Dokumentumok', id: 'documents' },
    { label: 'Ügyfél visszajelzések', id: 'remarks' },
  ];

  const getStatusData = useCallback(async () => {
    await fetchData(`${baseUrl}/api/aspreq/getstatus/${statusID}`, 'GET', localStorage.getItem('JWT'))
      .then(async (response) => {
        let resp = await response.json();
        setStatusData(resp);
        setTempStatus(resp.reqstatus);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }, [baseUrl, statusID]);

  const reloadData = async () => {
    await getStatusData();
  };

  // function formatDate(date = new Date()) {
  //   var d = new Date(date),
  //     month = '' + (d.getMonth() + 1),
  //     day = '' + d.getDate(),
  //     year = d.getFullYear();

  //   if (month.length < 2) month = '0' + month;
  //   if (day.length < 2) day = '0' + day;

  //   return [year, month, day].join('-');
  // }

  const tabChangeHandler = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabContent = {
    main: <Main statusID={statusID} />,
    validation: <Validation statusID={statusID} />,
    matching: <Matching statusID={statusID} />,
    interaction: <Interaction statusID={statusID} />,
    emails: <Emails statusID={statusID} />,
    contract: <Contract statusID={statusID} />,
    documents: <Documents statusID={statusID} />,
    remarks: <Remarks statusID={statusID} />,
  };

  return (
    <div className="StatusDisplayContainer">
      <TabContext value={selectedTab}>
        <TabList onChange={tabChangeHandler} aria-label="lab API tabs example">
          {statusTabs.map((item) => {
            return <Tab key={item.id} label={item.label} value={item.id} />;
          })}
        </TabList>
      </TabContext>

      <div>{tabContent[selectedTab]}</div>
    </div>
  );
};

export default StatusView;

import { Button } from '@mui/material';
import React, { useEffect, useContext, useState } from 'react';
import Dropdown from 'src/components/Dropdown';
import { NumberInput } from 'src/components/NumberInput';
import MuiSwitch from 'src/components/Switch';
import TextInput from 'src/components/TextInput';
// material
// components
import { EnvContext } from 'src/context/EnvContext';
import { fetchData } from 'src/fetch';

const couponNumberInputs = [
  { label: 'Kedvezmény EV', id: 'discount' },
  { label: 'Kedvezmény Bt, Kft', id: 'discountLarge' },
];

export const companyTypeList = [
  { label: 'Mind', id: 'any' },
  { label: '"Új" KATA-s (2022 szeptember után is)', id: 'singleprimary' },
  { label: 'Egyéni vállalkozó, átalányadós, mellékállású', id: 'singlesecondary' },
  { label: 'Egyéni vállalkozó, átalányadós, főállású', id: 'kiva' },
  { label: 'Egyéni vállalkozó, VSZJA', id: 'vszja' },
  { label: 'Kft', id: 'kft' },
  { label: 'Kkt', id: 'kkt' },
  { label: 'Bt', id: 'bt' },
  { label: 'Zrt', id: 'zrt' },
  { label: 'Nyrt', id: 'nyrt' },
  { label: 'Társasház', id: 'condo' },
  { label: 'Civil szervezet, egyesület, alapítvány, egyház', id: 'civil' },
  { label: 'Önkormányzat, költségvetési szerv', id: 'citycouncil' },
];

export default function AddCoupon({ handleClose, getCoupons }) {
  const [coupon, setCoupon] = useState({
    couponCode: '',
    discount: '',
    discountLarge: '',
    singleDeduction: true,
    flow: 'searchflow',
    companyType: 'any',
    email: '',
  });
  const envContext = useContext(EnvContext);
  const baseUrl = envContext.baseUrl;

  const addCoupon = async () => {
    let data = { ...coupon, couponCode: coupon.couponCode.toUpperCase() };
    await fetchData(`${baseUrl}/api/coupons/create`, 'POST', localStorage.getItem('JWT'), data)
      .then(async (response) => {
        await getCoupons();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  useEffect(() => {}, []);
  const handleChange = async (id, value) => {
    setCoupon({ ...coupon, [id]: value });
  };
  const handleCheck = async (id, value) => {
    setCoupon({ ...coupon, [id]: value });
  };
  return (
    <div className="AddCouponContainer">
      <div style={{ width: '300px' }}>
        <div style={{ color: 'red', fontSize: '12px', paddingLeft: '5px', marginBottom: '16px' }}>
          <p>Kedvezmény megadása</p>
          <p>
            <b>egyszeri levonás</b> esetén forintban pl: <b>5000</b>
          </p>
          <p>
            <b>folyamatos kedvezmény</b> esetén százalékban, 5%-os kedvezmény esetén: <b>0.95</b>
          </p>
        </div>
        <div style={{ width: '200px', paddingLeft: '5px' }}>
          <TextInput
            label="Ajánlói kód"
            id="couponCode"
            valueProp={coupon.couponCode}
            shrinkLabel
            onChange={handleChange}
          />
        </div>
        <div style={{ width: '200px', paddingLeft: '5px' }}>
          <TextInput label="E-mail" id="email" valueProp={coupon.email} shrinkLabel onChange={handleChange} />
        </div>
        {couponNumberInputs.map((item, i) => {
          return (
            <div key={i} style={{ paddingLeft: '5px' }}>
              <NumberInput
                label={item.label}
                valueProp={coupon[item.id]}
                id={item.id}
                onChange={handleChange}
                index={i}
                float
              />
            </div>
          );
        })}
        <div style={{ width: '200px' }}>
          <Dropdown
            label="Flow"
            list={[
              { label: 'Keresés', id: 'searchflow' },
              { label: 'Regisztráció', id: 'regflow' },
            ]}
            selValue={coupon.flow}
            onChange={(value) => setCoupon({ ...coupon, flow: value })}
          />
        </div>
        <div style={{ width: '200px', paddingLeft: '7px' }}>
          <MuiSwitch
            label="Egyszeri levonás"
            id="singleDeduction"
            value={coupon.singleDeduction}
            onChange={handleCheck}
          />
        </div>
      </div>
      <div style={{ fontSize: '12px', width: '400px' }}>
        <p style={{ color: 'red', marginBottom: '16px' }}>
          Cégtípus megadása: az adott cégtípus azonosítóját kell megadni, ha minden cégtípusra vonatkozik akkor{' '}
          <b>any</b> (alapértelmezett) egyébként a cégtípusok felsorolva szóközzel pl: <b>kiva singlesecondary</b>
        </p>
        {companyTypeList.map((item, i) => {
          return (
            <p>
              {item.label} : <b>{item.id}</b>
            </p>
          );
        })}
        <div style={{ marginTop: '16px' }}>
          <TextInput
            label="Cégtípus"
            id="companyType"
            valueProp={coupon.companyType}
            shrinkLabel
            onChange={handleChange}
          />
        </div>

        <div style={{ width: '100%', padding: '16px 0' }}>
          <Button onClick={addCoupon} style={{ float: 'right' }} variant="contained">
            Hozzáadás
          </Button>
        </div>
      </div>
    </div>
  );
}
